import React from 'react';
import styled from 'styled-components';

const Spinner = (props) => (
  <SpinnerDiv className="spinner-border" role="status" {...props}>
    <span className="sr-only">Loading...</span>
  </SpinnerDiv>
);

const SpinnerDiv = styled.div`
  && {
    width: ${(props) => props.size || '1.5rem'};
    height: ${(props) => props.size || '1.5rem'};
  }
`;

export default Spinner;
