import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../data/Constants';

const MovementsList = ({ movements }) => (
  <table className="table mb-0 table-hover table-borderless">
    <thead>
      <tr>
        <th className="border-0" scope="col">
          Tipo
        </th>
        <th className="border-0" scope="col">
          Fecha de origen
        </th>
        <th className="border-0" scope="col">
          Fecha de presentación
        </th>
        <th className="border-0" scope="col">
          Moneda
        </th>
        <th className="border-0" scope="col">
          Monto
        </th>
        <th className="border-0" scope="col">
          Lote
        </th>
        <th className="border-0" scope="col">
          Cupón
        </th>
        <th className="border-0" scope="col">
          Numero de autorización
        </th>
        <th className="border-0" scope="col">
          Terminal
        </th>
        <th className="border-0" scope="col">
          Tarjeta
        </th>
        <th className="border-0" scope="col">
          Cuota
        </th>
      </tr>
    </thead>
    <tbody>
      {movements.map((movement) => (
        <tr key={movement.id}>
          <td>{Constants.movementTypes[movement.type]}</td>
          <td>{movement.originDate}</td>
          <td>{movement.presentationDate}</td>
          <td>{movement.amountCurrency}</td>
          <td>{movement.amount}</td>
          <td>{movement.batch}</td>
          <td>{movement.couponNumber}</td>
          <td>{movement.authorizationNumber}</td>
          <td>{movement.terminalNumber}</td>
          <td>{`${movement.cardBrandId}: ${movement.cardNumber}`}</td>
          <td>{`${movement.installmentCurrent} / ${movement.installments}`}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

MovementsList.propTypes = {
  movements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      presentationDate: PropTypes.string,
      paymentDate: PropTypes.string,
      originDate: PropTypes.string,
      batch: PropTypes.string,
      couponNumber: PropTypes.string,
      authorizationNumber: PropTypes.string,
      terminalNumber: PropTypes.string,
      cardBrandId: PropTypes.string,
      cardNumber: PropTypes.string,
      amountCurrency: PropTypes.string,
      type: PropTypes.string,
      subtype: PropTypes.string,
      installments: PropTypes.string,
      installmentCurrent: PropTypes.number,
      amount: PropTypes.number,
      discounts: PropTypes.arrayOf(
        PropTypes.shape({
          type1: PropTypes.string,
          type2: PropTypes.string,
          type3: PropTypes.string,
          type4: PropTypes.string,
          description: PropTypes.string,
          region: PropTypes.string,
          currency: PropTypes.string,
          rate: PropTypes.string,
          amount: PropTypes.number
        })
      )
    })
  )
};

export default MovementsList;
