import React from 'react';
import Paginate from '../../../components/Paginate';
import { Link } from 'react-router-dom';
import NoResult from '../../../components/common/NoResult';
import PropTypes from 'prop-types';

const Table = ({ establishments, onPageClick }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="border-0" scope="col">
            Número
          </th>
          <th className="border-0" scope="col">
            Procesadora
          </th>
          <th className="border-0" scope="col">
            Tarjeta
          </th>
          <th className="border-0" scope="col">
            Cuenta
          </th>
          <th className="border-0" scope="col">
            Cantidad de credenciales
          </th>
          <th className="border-0" scope="col">
            Sucursal
          </th>
          <th className="border-0" scope="col" />
          <th className="border-0" scope="col" />
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {establishments.map((model) => {
          return (
            <tr key={model.id}>
              <td>{model.number}</td>
              <td>{model.processor}</td>
              <td>{model.cardBrandId || ''}</td>
              <td>
                <Link to={`/admin/accounts/${model.account.id}`}>{model.account.name}</Link>
              </td>
              <td>{model.establishmentsCredentials.length}</td>
              <td>{model.shop ? model.shop.name : ''}</td>
              <td>
                <Link to={`/admin/establishments/${model.id}`}>Ver</Link>
              </td>
              <td>
                <Link to={`/admin/establishments/${model.id}/edit`}>Editar</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {!establishments.length ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

Table.propTypes = {
  establishments: PropTypes.array,
  onPageClick: PropTypes.func
};

export default Table;
