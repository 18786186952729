import React from 'react';
import PropTypes from 'prop-types';
import GenericInput from '../common/GenericSearchBar/GenericInput';

class ShopNameInput extends React.Component {
  handleChange = (shopName) => {
    this.props.handleInputChange({ shopName: shopName });
  };

  render() {
    return (
      <GenericInput
        handleInputChange={this.handleChange}
        inputName={'shopSearch'}
        inputPlaceholder={'Nombre de shop'}
      />
    );
  }
}

ShopNameInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default ShopNameInput;
