import React, { useState } from 'react';
import { Container, Row, Col, Form, FormInput, Button } from 'shards-react';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import Spinner from '../../components/common/GenericSearchBar/Spinner';
import CredentialService from '../../services/CredentialService';
import Errors from '../../components/Errors';

export default function FetchEstablishments() {
  const [credentialId, setCredentialId] = useState('');
  const [fetching, setFetching] = useState(false);
  const [fetchResult, setFetchResult] = useState({});

  function handleInputChange(e) {
    setCredentialId(e.target.value);
  }

  function showErrors() {
    return fetchResult?.update?.establishments === null && !fetching;
  }

  function showSuccess() {
    return fetchResult?.update?.establishments.length && !fetching;
  }

  async function handleSubmit() {
    setFetching(true);
    const credentialService = await CredentialService();
    const res = await credentialService.fetchEstablishment(credentialId);
    setFetchResult(res);
    setFetching(false);
  }

  return (
    <Container className="main-content-container px-4">
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" subtitle="" title="Fetch Establishments" />
      </Row>
      <Row noGutters>
        <p className="">Ingrese el ID de la credencial.</p>
      </Row>
      <Row noGutters>
        <FetchEstablishmentsForm
          onSubmit={(event) => {
            handleSubmit();
            event.preventDefault();
          }}
        >
          <Row form>
            <FormInput onChange={handleInputChange} />
          </Row>
          <Row className="py-4">
            <ColFlex>
              <SubmitButton disabled={credentialId && !fetching ? false : true}>
                Fetch Establishments
              </SubmitButton>
              {fetching && <Spinner />}
            </ColFlex>
          </Row>
          <Row noGutters>
            {showErrors() && (
              <>
                <ResultMessage>No se crearon los establecimientos para la credencial</ResultMessage>
                <Errors errors={fetchResult.update.errors} />
              </>
            )}
            {showSuccess() && (
              <>
                <ResultMessage>
                  Se crearon establecimientos para la credencial con los siguientes numeros:
                </ResultMessage>
                <List>
                  {fetchResult.update.establishments.map((e) => {
                    return <li key={e.number}>{e.number}</li>;
                  })}
                </List>
              </>
            )}
          </Row>
        </FetchEstablishmentsForm>
      </Row>
    </Container>
  );
}

const List = styled.ul`
  padding: 0 15px;
`;

const FetchEstablishmentsForm = styled(Form)`
  width: 100%;
  max-width: 500px;
`;

const ResultMessage = styled.p`
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin-right: 1rem;
`;

const ColFlex = styled(Col)`
  && {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    align-items: center;
  }
`;
