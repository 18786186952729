import React from 'react';
import PropTypes from 'prop-types';
import { Container, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'shards-react';
import { Link } from 'react-router-dom';
import '../../../assets/styles/icons.css';
import Constants from '../../../data/Constants';
import PageTitle from '../../../components/PageTitle';
import AccountService from '../../../services/AccountService';
import CredentialService from '../../../services/CredentialService';
import ShowEstablishments from './ShowEstablishments';
import styled from 'styled-components';

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = { credential: null };
  }

  componentDidMount() {
    this.findCredential();
  }

  findAccount = async () => {
    const accountService = await AccountService();
    accountService.get(this.state.credential.externalAccountId).then((data) => {
      this.setState({ account: data });
    });
  };

  findCredential = async () => {
    const credentialService = await CredentialService();
    credentialService.get(this.props.credentialId).then((data) => {
      this.setState({ credential: data });
      this.findAccount();
    });
  };

  render() {
    const { credential } = this.state;
    if (credential === null) return null;
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Credencial" />
        </Row>
        <Row>
          <Col md="6">
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Credencial</h6>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    ID
                  </Col>
                  <Col lg="6" sm="6" xs="12">
                    {credential.id}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Nombre de usuario
                  </Col>
                  <Col lg="6" sm="6" xs="12">
                    {credential.username}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Password
                  </Col>
                  <Col lg="6" sm="6" xs="12">
                    {credential.password}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Otro login (opcional)
                  </Col>
                  <Col lg="6" sm="6" xs="12">
                    {credential.otherLogin}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    País
                  </Col>
                  <Col className="Capitalize FlexAlignCenter" lg="6" sm="6" xs="12">
                    {credential.country}
                    <img
                      alt={credential.country}
                      className="MarginLeft10 MaxHeight35"
                      src={Constants.countryIcons[credential.country]}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Proveedor
                  </Col>
                  <Col className="Capitalize FlexAlignCenter" lg="6" sm="6" xs="12">
                    <img
                      alt={credential.infoProvider}
                      className="MarginLeft10 MaxHeight50 MaxWidth100"
                      src={Constants.providerLogos[credential.infoProvider]}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Estado
                  </Col>
                  <ColWithColor
                    className="Capitalize FlexAlignCenter"
                    lg="6"
                    sm="6"
                    status={credential.status}
                    xs="12"
                  >
                    <strong>{credential.status}</strong>
                  </ColWithColor>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Código de error
                  </Col>
                  <Col className="Capitalize FlexAlignCenter" lg="6" sm="6" xs="12">
                    {statusMessage(credential)}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Electronica
                  </Col>
                  <Col className="Capitalize FlexAlignCenter" lg="6" sm="6" xs="12">
                    {credential.electronic ? 'SI' : 'NO'}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="6" sm="6" xs="12">
                    Documentos esperados
                  </Col>
                  <Col className="Capitalize FlexAlignCenter" lg="6" sm="6" xs="12">
                    {credential.expectedDocuments || '-'}
                  </Col>
                </Row>
                {credential.infoProvider === 'first_data_new' ? (
                  <React.Fragment>
                    <hr />
                    <Row>
                      <Col lg="6" sm="6" xs="12">
                        Descarga electrónica? (FirstData nuevo)
                      </Col>
                      <Col className="Capitalize FlexAlignCenter" lg="6" sm="6" xs="12">
                        {credential.electronicCheckbox ? 'SI' : 'NO'}
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : null}
              </CardBody>
              <CardFooter />
            </Card>
          </Col>
          <Col md="6">
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Cuenta</h6>
              </CardHeader>
              <CardBody>
                <Row key="id">
                  <Col lg="6" sm="6" xs="12">
                    ID
                  </Col>
                  <Col lg="6" sm="6" xs="12">
                    <Link to={`/admin/accounts/${this.state.credential.externalAccountId}`}>
                      {this.state.credential.externalAccountId}
                    </Link>
                  </Col>
                </Row>
                <hr />
                {this.state.account ? (
                  <div>
                    <Row key="name">
                      <Col lg="6" sm="6" xs="12">
                        Nombre
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        {this.state.account.name}
                      </Col>
                    </Row>
                    <hr />
                    <Row key="taxId">
                      <Col lg="6" sm="6" xs="12">
                        CUIT
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        {this.state.account.tax_id}
                      </Col>
                    </Row>
                    <hr />
                  </div>
                ) : null}
              </CardBody>
            </Card>
            <Card className="mb-4" small>
              <ShowEstablishments credentialId={this.state.credential.id} />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const ColWithColor = styled(Col)`
  color: ${(props) => {
    switch (props.status) {
      case 'ok':
        return 'green';
      case 'warning':
        return 'orange';
      case 'pending':
        return 'yellow';
      case 'incorrect':
        return 'red';
      case 'blocked':
        return 'red';
      default:
        return 'black';
    }
  }};
`;

const CODE_STATUSES = ['expired', 'blocked'];

const statusMessage = (credential) => {
  if (!credential.statusCode || !CODE_STATUSES.includes(credential.status)) {
    return 'No aplica';
  }
  return credential.statusCode;
};

Show.propTypes = {
  credentialId: PropTypes.string.isRequired
};

export default Show;
