import React from 'react';
import PropTypes from 'prop-types';
import NoResult from '../../../../components/common/NoResult';
import TotalsBox from '../TotalsBox';
import IconBox from '../IconBox';

const Table = ({ monthlyDetails }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th scope="col" />
          <th className="w-10" scope="col">
            Fecha pago
          </th>
          <th className="w-30" scope="col">
            Total presentado
          </th>
          <th className="w-30" scope="col">
            Total deducción
          </th>
          <th className="w-30" scope="col">
            Total acreditado
          </th>
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {(monthlyDetails.details || []).map((detail, i) => {
          return (
            <tr className="NoPadding VerticalMiddle" key={i} style={{ border: '1px solid black' }}>
              <td>
                <IconBox match={detail.allMatch} />
              </td>
              <td style={{ fontSize: '15px', fontWeight: 200 }}>{detail.date}</td>
              <td>
                <TotalsBox totals={detail.totalAmount} />
              </td>
              <td>
                <TotalsBox totals={detail.totalDeduction} />
              </td>
              <td>
                <TotalsBox totals={detail.totalEarn} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {monthlyDetails.details === undefined ? <NoResult /> : null}
  </React.Fragment>
);

Table.propTypes = {
  monthlyDetails: PropTypes.object
};

export default Table;
