import React, { useMemo } from 'react';

import Select from '../common/Select';
import { ScraperService } from '../../../../services/ScraperService';

const InfoProviderSelect = ({ account, onSelect }) => {
  const providers = useMemo(() => {
    return ScraperService().getInfoProviders(account.infoProviders);
  }, [account]);

  return (
    <Select
      defaultOptions
      id="infoProviderFilter"
      isClearable={true}
      onChange={(options) => onSelect(options && options.value)}
      options={providers}
      placeholder="Ver todas"
    />
  );
};

export default InfoProviderSelect;
