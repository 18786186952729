import React from 'react';
import PropTypes from 'prop-types';
import Paginate from '../../../components/Paginate';
import NoResult from '../../../components/common/NoResult';
import Translator from '../../../services/Translator';
import { Link } from 'react-router-dom';

const Table = ({ comparisons, onPageClick }) => {
  return (
    <React.Fragment>
      <table className="table table-hover text-center table-fixed MarginTop20">
        <thead className="thead-dark">
          <tr>
            <th className="w-10" scope="col">
              Procesadora
            </th>
            <th className="w-10" scope="col">
              Tarjeta
            </th>
            <th className="w-10" scope="col">
              Establecimiento
            </th>
            <th className="w-10" scope="col">
              Mes
            </th>
            <th className="w-10" scope="col">
              Año
            </th>
            <th className="w-10" scope="col">
              Resultado
            </th>
            <th className="w-10" scope="col">
              Diarias
            </th>
            <th className="w-10" scope="col">
              Etapa
            </th>
            <th className="w-5" scope="col" />
          </tr>
        </thead>
        <tbody className="BackgroundWhite">
          {comparisons.map((model, i) => {
            return (
              <tr key={i}>
                <td>{Translator.infoProvider(model.infoProvider)}</td>
                <td>{Translator.cardBrand(model.cardBrandId)}</td>
                <td>{model.establishmentNumber}</td>
                <td>{model.month}</td>
                <td>{model.year}</td>
                <td>
                  {model.initialComparison.comparisonResult} |{' '}
                  {model.lastComparison.comparisonResult}
                  {} %
                </td>
                <td>
                  {model.initialComparison.dailyPaymentsSummary} |{}
                  {} {model.lastComparison.dailyPaymentsSummary}
                </td>
                <td>
                  {model.initialComparison.stage} | {model.lastComparison.stage}
                </td>
                <td>
                  <Link to={`/support/monthly_detailed/${model.monthlyPaymentId}`}>Ver</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {comparisons.length < 1 ? <NoResult /> : null}
      <Paginate disabled={comparisons.length === 0} onPageChange={onPageClick} />
    </React.Fragment>
  );
};

Table.propTypes = {
  comparisons: PropTypes.array.isRequired,
  onPageClick: PropTypes.func.isRequired
};

export default Table;
