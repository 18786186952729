import Constants from '../data/Constants';

class Translator {
  static infoProvider(infoProvider) {
    const candidate = Constants.infoProvidersSimple.find(({ value }) => value === infoProvider);
    return (candidate || {}).label || infoProvider;
  }

  static scraperInfoProvider(infoProvider) {
    const candidate = Constants.infoProvidersScraper.find(({ value }) => value === infoProvider);
    return (candidate || {}).label || infoProvider;
  }

  static cardBrand(cardBrand) {
    const candidate = Constants.cardBrands.find(({ value }) => value === cardBrand);
    return (candidate || {}).label || cardBrand;
  }

  static credentialStatus(status) {
    const candidate = Constants.credentialStatuses.find(({ value }) => value === status);
    return (candidate || {}).label || status;
  }

  static documentType(documentType) {
    const candidate = Constants.documentTypes.find(({ value }) => value === documentType);
    return (candidate || {}).label || documentType;
  }

  static userRole(role) {
    const candidate = Constants.userRoles.find(({ value }) => value === role);
    return (candidate || {}).label || role;
  }

  static productName(product) {
    const candidate = Constants.productNames.find(({ value }) => value === product);
    return (candidate || {}).label || product;
  }

  static jobStatus(jobStatus) {
    const candidate = Constants.jobStatuses.find(({ value }) => value === jobStatus);
    return (candidate || {}).label || jobStatus;
  }

  static reparseStatus(status) {
    const candidate = Constants.reparseStatuses.find(({ value }) => value === status);
    return (candidate || {}).label || status;
  }

  static refocusStatus(status) {
    const candidate = Constants.refocusStatuses.find(({ value }) => value === status);
    return (candidate || {}).label || status;
  }
}

export default Translator;
