import React from 'react';
import styled from 'styled-components';

import Spinner from '../../../components/common/GenericSearchBar/Spinner';

const LoadingContainer = styled.div.attrs({
  className: 'loading-indicator'
})`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  ${({ backdrop }) =>
    backdrop &&
    `
    background: rgba(255, 255, 255, 0.8);
  `}
`;

const Loading = ({ backdrop }) => {
  return (
    <LoadingContainer backdrop={backdrop}>
      <Spinner />
    </LoadingContainer>
  );
};

export default Loading;
