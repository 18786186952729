import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';
import axios from 'axios';

const BatchEstablishmentService = async () => {
  const gqlClient = GraphqlClient();
  const batchCreateMutation = gql`
    mutation BatchCreate($fileName: String!, $accountId: String!) {
      api_batchEstablishmentsCreator(fileName: $fileName, accountId: $accountId) {
        message
        errors
      }
    }
  `;

  const batchNotificationMutation = gql`
    mutation BatchNotify($fileName: String!, $accountId: String!) {
      api_batchEstablishmentsNotifier(fileName: $fileName, accountId: $accountId) {
        message
        errors
      }
    }
  `;

  const uploadFile = (rawParams, file) => {
    const params = JSON.parse(rawParams);
    const formData = new FormData();
    formData.append('Content-Type', params['Content-Type'] || 'multipart/form-data');
    formData.append('key', params.key);
    formData.append('acl', params.acl);
    formData.append('x-amz-credential', params['x-amz-credential']);
    formData.append('x-amz-date', params['x-amz-date']);
    formData.append('x-amz-signature', params['x-amz-signature']);
    formData.append('x-amz-algorithm', params['x-amz-algorithm']);
    if (params['x-amz-security-token']) {
      formData.append('x-amz-security-token', params['x-amz-security-token']);
    }
    formData.append('policy', params.policy);
    formData.append('Expires', params['Expires']);
    formData.append('file', file);

    return axios.post(params['url'], formData);
  };

  const getUploadLink = (fileName, accountId) => {
    return gqlClient
      .mutate({
        mutation: batchCreateMutation,
        variables: {
          fileName: fileName,
          accountId: accountId
        }
      })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  };

  const notifyUploadedFile = (fileName, accountId) => {
    return gqlClient
      .mutate({
        mutation: batchNotificationMutation,
        variables: {
          fileName: fileName,
          accountId: accountId
        }
      })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  };

  return {
    batchCreate: (fileName, accountId, file) => {
      return getUploadLink(fileName, accountId)
        .then((data) => {
          return uploadFile(data.data.api_batchEstablishmentsCreator.message, file);
        })
        .then(() => {
          return notifyUploadedFile(fileName, accountId);
        })
        .then((data) => {
          return data.data.api_batchEstablishmentsNotifier;
        })
        .catch((error) => {
          throw error;
        });
    }
  };
};

export default BatchEstablishmentService;
