import Debounce from 'es6-promise-debounce';

import CredentialService from '../services/CredentialService';

export const debouncedFetchCredentials = Debounce(async (credentialUsername) => {
  const credentialService = await CredentialService();

  return credentialService.list({ credentialUsername }).then((data) => {
    const transformedData = (data || []).map((credential) => {
      return { value: credential.id, label: credential.username };
    });
    return transformedData;
  });
}, 500);
