import constants from '../data/Constants';
import { getCurrentCountry } from './admin';

const dayOfWeek = (date) => {
  const day = Intl.DateTimeFormat('es', { weekday: 'long' }).format(date);

  return day[0].toUpperCase() + day.substr(1);
};

const monthOfYear = (date) => {
  const month = Intl.DateTimeFormat('es', { month: 'long' }).format(date);

  return month[0].toUpperCase() + month.substr(1);
};

const coerceToDate = (value) => {
  if (typeof value === 'string') {
    return new Date(value);
  }

  return value;
};

/**
 *
 * @param {*} value datetime encoded as ISO 8601 string or Date object
 * @param {*} style format style. 'short': dd/mm/yyyy; 'medium': Lunes 5 de Julio; 'iso': YYYY-MM-DD, 'monthOfYear': Julio 2019
 */
export const formatDateTime = (value, style = 'short') => {
  if (!value) {
    return '-';
  }

  const date = coerceToDate(value);

  if (style === 'short') {
    return date.toLocaleString('es', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  if (style === 'iso') {
    return date.toISOString().substr(0, 10);
  }

  if (style === 'monthOfYear') {
    return `${monthOfYear(date)} ${date.getFullYear()}`;
  }

  return `${dayOfWeek(date)} ${date.getDate()} de ${monthOfYear(date)}`;
};

export const isToday = (value) => {
  const now = new Date();

  const date = coerceToDate(value);

  return (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() === date.getDate()
  );
};

export const isSameDate = (v1, v2) => {
  if (!v1 && !v2) {
    return true;
  }

  return v1.getTime() === v2.getTime();
};

/**
 * Extract filename from URL
 *
 * @param {*} url
 */
export const urlFileName = (url) => {
  if (!url) {
    return '';
  }
  const re = /.com\/(.+)\/(.+)(\?X-Amz-Algorithm)/g;
  const fileName = re.exec(url);
  return fileName[2];
};

/**
 * Convert string representing a date (format YYYY-MM-DD) into a Date object
 *
 * @param {*} dateStr
 */
export const dateToDateTime = (dateStr) => {
  const parts = dateStr.split('-').map((s) => parseInt(s, 10));

  if (parts.length < 3) {
    return null;
  }

  return new Date(parts[0], parts[1] - 1, parts[2]);
};

export const beginningOfDay = (date = new Date()) => {
  const newDate = new Date(date);

  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const getDocumentTypesByCountry = () => {
  return (
    constants.scraperDocumentType[getCurrentCountry()] || constants.scraperDocumentType.default
  );
};

export const getDocumentTypesByInfoProviders = (infoProviders) => {
  const docTypes = infoProviders.reduce((acc, infoProvider) => {
    const docType = constants.scraperDocumentTypeByInfoProvider[infoProvider];
    if (Array.isArray(docType)) {
      docType.forEach((type) => {
        acc.add(type);
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Can´t find document type for infoProvider: ', infoProvider);
    }
    return acc;
  }, new Set());
  if (docTypes.size === 0) {
    // if no document types found by infoProvider, return default doc types by country
    return getDocumentTypesByCountry();
  }
  return [...docTypes];
};
