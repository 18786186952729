import React from 'react';
import { Row, Col } from 'shards-react';

const Header = ({ monthlyDetails }) => {
  return (
    <React.Fragment>
      <Row className="MarginTop20 TextAlignCenter">
        <Col className="NoPadding" md="3">
          <ul className="list-group">
            <li className="list-group-item">
              <a href={monthlyDetails.downloadLink}>
                <strong>Descargar</strong>
              </a>
            </li>
          </ul>
        </Col>
        <Col className="NoPadding" md="3">
          <ul className="list-group">
            <li className="list-group-item">
              Establecimiento - <strong>{monthlyDetails.establishmentNumber}</strong>
            </li>
          </ul>
        </Col>
        <Col className="NoPadding" md="3">
          <ul className="list-group">
            <li className="list-group-item">
              Procesadora - <strong>{monthlyDetails.infoProvider}</strong>
            </li>
          </ul>
        </Col>
        <Col className="NoPadding" md="3">
          <ul className="list-group">
            <li className="list-group-item">
              Tarjeta - <strong>{monthlyDetails.cardBrand}</strong>
            </li>
          </ul>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Header;
