import React from 'react';
import { Col } from 'shards-react';
import { H1 } from '@increase/typed-components';

const PageTitle = ({ title, className }) => {
  return (
    <Col className={className} xs="12">
      <H1>{title}</H1>
    </Col>
  );
};

export default PageTitle;
