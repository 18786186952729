import React, { Fragment, useState } from 'react';
import { Row, Col } from 'shards-react';
import Select from 'react-select';
import { Table, THead, Th, TableRow, Label } from '@increase/typed-components';
import { LoadingTableRow } from '../LoadingTableRow';
import { EmptyTableMessage } from '../EmptyTableMessage';
import TableCell from '../common/TableCell';
import Paginate from '../common/Paginate';
import InputFile from '../common/InputFile';
import DownloadIcon from '../DownloadIcon';
import UploadingFile from './UploadingFile';
import {
  formatDateTime,
  getDocumentTypesByCountry,
  urlFileName
} from '../../../../utils/manualScraper';
import { useCredentialDocuments } from '../../../../hooks/manualScraper';
import { ScraperService } from '../../../../services/ScraperService';
import constants from '../../../../data/Constants';
import DatePicker from '../common/DatePicker';
import ParsedStatusIcon from '../../../../components/ParsedStatusIcon';
import { CredentialDocumentsStatus } from '../../../../components/CredentialDocumentsStatus';
import Errors from '../../../../components/Errors';
import Messages from '../../../../components/Messages';

const filesPerPage = constants.scraperDocumentsPerPage;

const DocumentList = ({
  credentialId,
  canEdit,
  date,
  infoProvider,
  country,
  managementId,
  onFileUploaded
}) => {
  const [uploading, setUploading] = useState(false);
  const [docTypeSelected, setDocTypeSelected] = useState(null);
  const [filesUploadProgress, setFilesUploadProgress] = useState({});
  const [filesFilter, setFilesFilter] = useState({ date });
  const [filesOffset, setFilesOffset] = useState(0);
  const [uploadsSuccessedMessages, setUploadsSuccessedMessages] = useState([]);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);

  // Load documents
  const [files, managements, { loading: loadingFiles, setFiles }] = useCredentialDocuments(
    credentialId,
    filesFilter,
    filesOffset,
    filesPerPage
  );

  const docTypeOptions = getDocumentTypesByCountry().map((t) => {
    return {
      value: t,
      label: constants.scraperDocumentTypeLabels[t]
    };
  });

  const onFileAdded = (event) => {
    setUploading(true);
    setUploadsSuccessedMessages([]);
    setUploadErrorMessages([]);
    const filesArray = Object.values(event.target.files);

    const uploadingPromises = filesArray.map((file) => {
      return ScraperService().uploadDocument(
        {
          country: country || 'argentina',
          documentType: docTypeSelected,
          infoProvider,
          credentialId,
          date
        },
        file,
        managementId,
        (progress) => {
          return setFilesUploadProgress((prevState) => ({ ...prevState, [file.name]: progress }));
        }
      );
    });

    Promise.all(uploadingPromises)
      .then((values) => {
        let errorMessages = [];
        let successedMessages = [];

        values.forEach((result) => {
          if (result.message && result.message === 'Document does not enqueued') {
            const newMessage = `El archivo ${result.file.name} ya existe`;
            errorMessages = [...errorMessages, newMessage];
          }

          if (result.message && result.message === 'Document does not exist in S3') {
            const newMessage = `El archivo ${result.file.name} no se encontro en S3`;
            errorMessages = [...errorMessages, newMessage];
          }

          if (result.message && result.message === 'Document enqueued') {
            const newMessage = `El archivo ${result.file.name} fue subido correctamente`;
            successedMessages = [...successedMessages, newMessage];
            // Are we on the first page?
            if (filesOffset === 0) {
              // Add the file to the top
              setFiles([
                {
                  link: result.url,
                  date,
                  createdAt: new Date(),
                  id: result.url
                },
                ...files.slice(0, filesPerPage - 1)
              ]);
            } else {
              // Not in the first page? Reset pagination
              setFilesOffset(0);
            }
            onFileUploaded(result.url);
          }
        });

        setUploadsSuccessedMessages(successedMessages);
        setUploadErrorMessages(errorMessages);
      })
      .catch(() => {
        setUploadErrorMessages(['Algunos archivos fallaron durante la subida']);
      })
      .finally(() => {
        setFilesUploadProgress({});
        setUploading(false);
      });
  };

  const handlePageClick = (direction) => {
    const newOffset = filesOffset + direction * filesPerPage;

    if (newOffset >= 0) {
      setFilesOffset(newOffset);
    }
  };

  const handleFilesFilterChange = (filter) => {
    setFilesFilter(filter);
    setFilesOffset(0);
  };

  return (
    <Fragment>
      <Row className="mt-3">
        <Col md="6">
          <div className="d-inline-block">
            <Label>Fecha de Creación</Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              disabled={uploading}
              id="documentCreatedAt"
              isClearable
              onChange={(date) => handleFilesFilterChange({ createdAt: date })}
              placeholderText="dd/mm/yyyy"
              selected={filesFilter.createdAt}
            />
          </div>
        </Col>
        <Col className="d-flex align-items-end justify-content-end" md="6">
          {canEdit && (
            <>
              <Select
                className="basic-multi-select mr-4 w-100"
                classNamePrefix="select"
                name="docTypeInput"
                onChange={(e) => {
                  setDocTypeSelected(e.value);
                }}
                options={docTypeOptions}
                placeholder="Seleccionar tipo de documento"
              />
              <InputFile
                disabled={docTypeSelected === null || uploading}
                label="Agregar Documento"
                multiple={true}
                onChange={onFileAdded}
              />
            </>
          )}
        </Col>
      </Row>
      <CredentialDocumentsStatus managements={managements} />
      <Row>
        <Col>
          <Messages messages={uploadsSuccessedMessages} />
          <Errors errors={uploadErrorMessages} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <UploadingFile filesProgress={filesUploadProgress} uploading={uploading} />
          <Table>
            <THead>
              <tr>
                <Th width="5%">Parseado</Th>
                <Th>Nombre</Th>
                <Th width="15%">Fecha de Creación</Th>
                <Th className="text-center" width="3%">
                  Descargar
                </Th>
              </tr>
            </THead>
            <tbody>
              <LoadingTableRow loading={loadingFiles} />
              {files &&
                files.map((f, index) => {
                  const fileName = urlFileName(f.link);
                  return (
                    <TableRow key={`${f.id}-${index}`}>
                      <TableCell className="text-center">
                        <ParsedStatusIcon isParsed={f.parsed} isProcessInApi={f.processInApi} />
                      </TableCell>
                      <TableCell title={fileName}>{fileName}</TableCell>
                      <TableCell>{formatDateTime(f.createdAt)}</TableCell>
                      <TableCell className="text-center">
                        <DownloadIcon href={f.link} />
                      </TableCell>
                    </TableRow>
                  );
                })}

              <EmptyTableMessage
                collection={files}
                message="No hay documentos."
                ready={!loadingFiles}
              />
            </tbody>
          </Table>
          <Paginate className="mt-3" disabled={uploading} onPageChange={handlePageClick} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default DocumentList;
