import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Translator from '../../../services/Translator';

const Credentials = ({ credentials }) => (
  <table className="table mb-0 table-hover table-borderless">
    <thead>
      <tr>
        <th className="border-0" scope="col">
          ID
        </th>
        <th className="border-0" scope="col">
          Nombre de usuario
        </th>
        <th className="border-0" scope="col">
          Proveedor
        </th>
        <th className="border-0" scope="col">
          Editar
        </th>
      </tr>
    </thead>
    <tbody>
      {credentials.map((credential) => (
        <tr key={credential.id}>
          <td>
            <Link to={`/admin/credentials/${credential.id}`}>{credential.id}</Link>
          </td>
          <td>{credential.username}</td>
          <td>{Translator.infoProvider(credential.infoProvider)}</td>
          <td>
            <Link to={`/admin/credentials/${credential.id}/edit`}>Editar</Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

Credentials.propTypes = {
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
      provider: PropTypes.string
    })
  )
};

export default Credentials;
