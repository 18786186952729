import React from 'react';
import PropTypes from 'prop-types';
import GenericInput from '../common/GenericSearchBar/GenericInput';

class CredentialUsernameInput extends React.Component {
  handleChange = (credentialUsername) => {
    this.props.handleInputChange({ credentialUsername: credentialUsername });
  };

  render() {
    return (
      <GenericInput
        handleInputChange={this.handleChange}
        inputName={'credentialSearch'}
        inputPlaceholder={'Nombre de usuario de la credencial'}
      />
    );
  }
}

CredentialUsernameInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default CredentialUsernameInput;
