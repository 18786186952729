import React from 'react';
import { Nav } from 'shards-react';

import SidebarNavItem from './SidebarNavItem';
import SidebarOptions from './SidebarOptions';
import SidebarLogOut from './SidebarLogOut';
import SidebarUserInfo from './SidebarUserInfo';
import items from '../../../data/sidebar-nav-items';

const SidebarNavItems = () => {
  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {items.map((item, idx) => (
          <SidebarNavItem item={item} key={idx} />
        ))}
      </Nav>
      <SidebarOptions />
      <div className="MarginTop20 FlexAlignCenter">
        <SidebarLogOut />
      </div>
      <div className="MarginTop20 FlexAlignCenter">
        <SidebarUserInfo />
      </div>
    </div>
  );
};

export default SidebarNavItems;
