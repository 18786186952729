import React from 'react';
import { Container, Row } from 'shards-react';

import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';

import AccountNameOrIdInput from '../../../components/inputs/AccountNameOrIdInput';
import UserEmailInput from '../../../components/inputs/UserEmailInput';
import UserNamesInput from '../../../components/inputs/UserNamesInput';

import PageTitle from '../../../components/PageTitle';
import UserService from '../../../services/UserService';
import Constants from '../../../data/Constants';
import Table from './Table';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      users: [],
      offset: 0,
      loading: true
    };

    this.inputs = [UserEmailInput, UserNamesInput, AccountNameOrIdInput];
  }

  componentDidMount = () => {
    this.queryUsers();
  };

  queryUsers = async () => {
    this.setState({ loading: true });
    window.scrollTo(0, 0);

    const userService = await UserService();
    userService
      .listUsers(this.state.filter, Constants.elementsPerPage, this.state.offset)
      .then((data) =>
        this.setState({
          users: data,
          offset: !data.length ? 0 : this.state.offset
        })
      )
      .finally(() => this.setState({ loading: false }));
  };

  onSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.queryUsers());
  };

  handlePageClick = async (direction) => {
    const newOffset = this.state.offset + direction * Constants.elementsPerPage;

    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.queryUsers());
    }
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Usuarios" />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} inputWidth="33" />
          <Table onPageClick={this.handlePageClick} users={this.state.users} />
        </LoadingProvider>
      </Container>
    );
  }
}

export default List;
