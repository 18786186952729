import React from 'react';
import { Container, Row } from 'shards-react';

import PageTitle from '../../../components/PageTitle';
import PaymentService from '../../../services/PaymentService';
import Constants from '../../../data/Constants';
import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';
import Table from './Table';
import EstablishmentNumberInput from '../../../components/inputs/EstablishmentNumberInput';
import DateInput from '../../../components/inputs/DateInput';
import CardBrandInput from '../../../components/inputs/CardBrandInput';
import DocumentTypeInput from '../../../components/inputs/DocumentTypeInput';
import PaymentOrderInput from '../../../components/inputs/PaymentOrderInput';
import WithPlaceHolderAndKey from '../../../components/inputs/WithPlaceHolderAndKey';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      offset: 0,
      filter: {},
      loading: true
    };

    this.inputs = [
      EstablishmentNumberInput,
      WithPlaceHolderAndKey(DateInput, 'Fecha desde', 'dateFrom'),
      WithPlaceHolderAndKey(DateInput, 'Fecha hasta', 'dateTo'),
      CardBrandInput,
      DocumentTypeInput,
      PaymentOrderInput
    ];
  }

  componentDidMount() {
    this.queryPayments();
  }

  queryPayments = async () => {
    this.setState({ loading: true });

    const filter = {
      paymentDateFrom: this.state.filter.dateFrom,
      paymentDateTo: this.state.filter.dateTo,
      establishmentNumber: this.state.filter.establishmentNumberOrId,
      cardBrand: this.state.filter.cardBrand,
      documentType: this.state.filter.documentType,
      orderBy: this.state.filter.orderBy
    };

    const paymentService = await PaymentService();
    paymentService
      .list(filter, Constants.elementsPerPage, this.state.offset)
      .then((data) => {
        this.setState({
          payments: data,
          offset: !(data || []).length ? 0 : this.state.offset
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  onSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.queryPayments());
  };

  handlePageClick = async (direction) => {
    const newOffset = this.state.offset + direction * Constants.elementsPerPage;

    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.queryPayments());
    }
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Liquidaciones" />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} inputWidth="25" />
          <Table onPageClick={this.handlePageClick} payments={this.state.payments} />
        </LoadingProvider>
      </Container>
    );
  }
}

export default List;
