import React from 'react';

import StatusBg from '../StatusBg';

const AccountStatus = ({ account }) => {
  const { lockedById, status } = account;

  const translation = {
    awaiting_start: 'Gestión sin iniciar',
    pending: 'Gestión en proceso',
    in_progress: 'Gestión en proceso',
    done: 'Gestión terminada'
  };

  const statusText = translation[status] || status;
  const lockedByText = ({ lockedBy, lockedById }) => {
    return lockedBy ? `${lockedBy.firstName} ${lockedBy.lastName}` : lockedById;
  };

  return (
    <StatusBg status={status}>
      {lockedById ? `Siendo editado por ${lockedByText(account)}` : statusText}
    </StatusBg>
  );
};

export default AccountStatus;
