import React from 'react';
import { Container, Row } from 'shards-react';
import PageTitle from '../../../components/PageTitle';
import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';

import UserEmailInput from '../../../components/inputs/UserEmailInput';
import AccountNameOrIdInput from '../../../components/inputs/AccountNameOrIdInput';
import ShopNameInput from '../../../components/inputs/ShopNameInput';
import EstablishmentNumberInput from '../../../components/inputs/EstablishmentNumberInput';
import CredentialUsernameInput from '../../../components/inputs/CredentialUsernameInput';
import InfoProviderInput from '../../../components/inputs/InfoProviderInput';
import ScrapeTable from './ScrapeTable';

import ScraperComponent from './ScraperComponent';
import EstablishmentService from '../../../services/EstablishmentService';
import SchedulerService from '../../../services/SchedulerService';
import AuthorizedDocumentService from '../../../services/AuthorizedDocumentService';
import Constants from '../../../data/Constants';
import DismissableMessagesList from '../../../components/DismissableMessagesList';
import Errors from '../../../components/Errors';
import { AuthContext } from '../../../contexts/AuthContext';

class SupportDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEstablishments: [],
      establishments: [],
      offset: 0,
      filter: {},
      scrapingJobState: [],
      loading: true
    };
    this.inputs = [
      UserEmailInput,
      EstablishmentNumberInput,
      ShopNameInput,
      CredentialUsernameInput,
      AccountNameOrIdInput,
      InfoProviderInput
    ];
  }

  componentDidMount = () => {
    this.getEstablishments();
  };

  getEstablishments = async () => {
    this.setState({ loading: true, selectedEstablishments: [], establishments: [] });
    const filter = {
      userEmail: this.state.filter.userEmail,
      shopName: this.state.filter.shopName,
      establishmentNumberOrId: this.state.filter.establishmentNumberOrId,
      credentialInfoProvider: this.state.filter.infoProvider,
      credentialUsername: this.state.filter.credentialUsername,
      accountNameOrId: this.state.filter.accountNameOrId
    };

    const establishmentService = await EstablishmentService();
    establishmentService
      .listWithShopsAndCredentials(filter, Constants.elementsPerPage, this.state.offset)
      .then((data) => {
        this.setState({ establishments: data });
      })
      .finally(() => this.setState({ loading: false }));
  };

  onFilterSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.getEstablishments());
  };

  handlePageClick = (direction) => {
    const newOffset = this.state.offset + direction * Constants.elementsPerPage;

    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.getEstablishments());
    }
  };

  handleSelectEstablishment = (establishment, selected) => {
    if (selected) {
      this.setState((prevState) => {
        return { selectedEstablishments: [...prevState.selectedEstablishments, establishment] };
      });
    } else {
      this.setState((prevState) => {
        return {
          selectedEstablishments: prevState.selectedEstablishments.filter(
            (estab) => estab.id !== establishment.id
          )
        };
      });
    }
  };

  onSelectAll = () => {
    this.setState((prevState) => {
      return {
        selectedEstablishments: prevState.establishments
      };
    });
  };

  onUnselectAll = () => {
    this.setState({
      selectedEstablishments: []
    });
  };

  groupEstablishmentsPayload = (query) => {
    const { selectedEstablishments } = this.state;
    const credentialIds = new Set(
      selectedEstablishments.flatMap((est) => est.credentials.map((credential) => credential.id))
    );
    const { realUser, userEmail } = this.context;
    const payloads = [...credentialIds].map((credentialId) => ({
      establishmentNumbers: [],
      credentialId: credentialId,
      documentTypes: [query.documentType],
      startDate: query.dateFrom,
      endDate: query.dateTo,
      originDetail: realUser ? realUser.email : userEmail
    }));

    selectedEstablishments.forEach((establishment) => {
      establishment.credentials.forEach((credential) => {
        const targetPayload = payloads.find((payload) => payload.credentialId === credential.id);
        targetPayload.establishmentNumbers.push(establishment.number);
      });
    });

    return payloads;
  };

  dismissMessage = (msg) => {
    this.setState((prevState) => {
      return { scrapingJobState: prevState.scrapingJobState.filter((item) => item !== msg) };
    });
  };

  onSendToScrap = (query) => {
    this.setState({ loading: true, scrapingJobState: [] }, () => this.handleScrap(query));
  };

  sendToScheduler = async (docTypes, params) => {
    const schedulerService = await new SchedulerService();

    if (docTypes.map((docType) => docType.documentType).includes(params.documentTypes[0])) {
      schedulerService.scheduleScrapping(params).then((data) => {
        if (data.proposeScrappingJob) {
          const msg = `UUID: ${data.proposeScrappingJob.requestUuid} - CredentialID: ${
            data.proposeScrappingJob.credentialId
          }`;
          this.setState((prevState) => {
            if (!prevState.scrapingJobState.includes(msg)) {
              return prevState.scrapingJobState.push(msg);
            }
            return prevState;
          });
        } else {
          this.setState({ errors: [data.message] });
        }
      });
    }
  };

  handleScrap = async (query) => {
    if (
      query.dateFrom &&
      query.dateTo &&
      query.documentType &&
      this.state.selectedEstablishments.length > 0
    ) {
      const finalParams = this.groupEstablishmentsPayload(query);
      const authorizedDocumentService = await new AuthorizedDocumentService();
      finalParams.forEach((params) => {
        authorizedDocumentService
          .getAuthDocs(params.credentialId)
          .then((docTypes) => {
            this.sendToScheduler(docTypes, params);
          })
          .finally(() => this.setState({ loading: false }));
      });
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Soporte" />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar
            handleSubmit={this.onFilterSubmit}
            inputWidth="33"
            inputs={this.inputs}
          />
          <ScraperComponent
            onSelectAll={this.onSelectAll}
            onSendToScrap={this.onSendToScrap}
            onUnselectAll={this.onUnselectAll}
          />
          <DismissableMessagesList
            dismissMessage={this.dismissMessage}
            messages={this.state.scrapingJobState}
          />
          <Errors errors={this.state.errors} />
          <ScrapeTable
            establishments={this.state.establishments}
            onPageClick={this.handlePageClick}
            onSelect={this.handleSelectEstablishment}
            selectedEstablishments={this.state.selectedEstablishments}
          />
        </LoadingProvider>
      </Container>
    );
  }
}

SupportDashboard.contextType = AuthContext;

export default SupportDashboard;
