import React from 'react';
import styled from 'styled-components';

import { color } from '../../../utils/theme';

const Link = styled.a`
  color: ${color('brandBlack', 'light')};
  font-size: 1.25rem;
`;

const DownloadIcon = ({ href }) => {
  return (
    <Link href={href} rel="noopener noreferrer" target="_blank" title="Descargar">
      <i className="material-icons">cloud_download</i>
    </Link>
  );
};

export default DownloadIcon;
