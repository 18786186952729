import React from 'react';

const tickOrCross = (bool) => {
  return JSON.parse(bool) ? (
    <React.Fragment>
      <i
        className="material-icons"
        style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}
      >
        done
      </i>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <i className="material-icons" style={{ fontSize: '20px', fontWeight: 'bold', color: 'red' }}>
        clear
      </i>
    </React.Fragment>
  );
};

export default tickOrCross;
