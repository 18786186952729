import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ShopsTable = ({ shops, accountId }) => (
  <table className="table mb-0 table-hover table-borderless">
    <thead>
      <tr>
        <th className="border-0" scope="col">
          ID
        </th>
        <th className="border-0" scope="col">
          Nombre
        </th>
        <th className="border-0" scope="col">
          Ver
        </th>
        <th className="border-0" scope="col">
          Editar
        </th>
      </tr>
    </thead>
    <tbody>
      {shops.map((shop) => (
        <tr key={shop.id}>
          <td>{shop.id}</td>
          <td>{shop.name}</td>
          <td>
            <Link to={`${accountId}/shops/${shop.id}`}>Ver</Link>
          </td>
          <td>
            <Link to={`${accountId}/shops/${shop.id}/edit`}>Editar</Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

ShopsTable.propTypes = {
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  )
};

export default ShopsTable;
