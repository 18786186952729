import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const PaymentService = async () => {
  const gqlClient = GraphqlClient();

  const listQuery = gql`
    query Payments($filter: api_PaymentsFilter, $limit: Int, $offset: Int) {
      payments: api_payments(filter: $filter, limit: $limit, offset: $offset) {
        id
        paymentDate
        presentationDate
        infoProvider
        documentType
        cardBrandId
        clearingNumber
        establishment {
          id
          number
        }
        scrapedDocumentExtId
        parsedDocumentExtId
        totalAmount
        totalDeduction
        totalEarn
        openingBalance
        closingBalance
        currency
        isBalanced
        focusedForPayments
        focusedForTransactions
        focusedForChargebacks
        focusedForApi
        createdAt
      }
    }
  `;

  const getSiblingPayments = gql`
    query SiblingPayments($id: String!) {
      payments: api_siblingPayments(id: $id) {
        id
      }
    }
  `;

  const getQuery = gql`
    query Payment($id: String!) {
      payment: api_payment(id: $id) {
        id
        paymentDate
        presentationDate
        infoProvider
        documentType
        cardBrandId
        clearingNumber
        establishment {
          id
          number
        }
        scrapedDocumentExtId
        parsedDocumentExtId
        totalAmount
        totalDeduction
        totalEarn
        openingBalance
        closingBalance
        currency
        isBalanced
        focusedForPayments
        focusedForTransactions
        focusedForChargebacks
        focusedForApi
        discounts {
          type1
          type2
          type3
          type4
          description
          region
          currency
          rate
          amount
        }
        movements {
          id
          presentationDate
          paymentDate
          originDate
          batch
          couponNumber
          authorizationNumber
          terminalNumber
          cardBrandId
          cardNumber
          amountCurrency
          type
          creditType
          discounts {
            type1
            type2
            type3
            type4
            description
            region
            currency
            rate
            amount
          }
          installments
          installmentCurrent
          amount
        }
        createdAt
      }
    }
  `;

  const refocusMutation = gql`
    mutation Refocus($id: String!) {
      data: api_refocusPayment(id: $id) {
        result
      }
    }
  `;

  return {
    list: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: listQuery,
          variables: {
            filter: filter,
            limit: limit,
            offset: offset
          }
        })
        .then((data) => data.data.payments)
        .catch(() => null); // TODO: do something
    },
    get: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getQuery,
          variables: { id: id }
        })
        .then((data) => data.data.payment)
        .catch(() => null); // TODO: do something
    },
    getSiblings: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getSiblingPayments,
          variables: { id: id }
        })
        .then((data) => data.data.payments)
        .catch(() => null); // TODO: do something
    },
    refocus: (id) => {
      return gqlClient
        .mutate({
          mutation: refocusMutation,
          variables: { id: id }
        })
        .then((data) => data.data)
        .catch(() => null);
    }
  };
};

export default PaymentService;
