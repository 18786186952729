import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  justify-items: ${({ justifyItems = 'flex-start' }) => justifyItems};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'flex-start' }) => alignItems};
  align-content: ${({ alignContent = 'flex-start' }) => alignContent};
  ${({ gap }) => gap && `gap: ${gap}`};
  ${({ childrenFlex }) =>
    childrenFlex &&
    `& > * {
      flex: ${childrenFlex};
    };`}
`;
