import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './accounts/List';
import Show from './accounts/Show';
import Editor from './accounts/Editor';
import ShopEditor from './shops/Editor';
import ShopShow from './shops/Show';

const AdminAccounts = () => (
  <Switch>
    <Route component={List} exact path="/admin/accounts" />
    <Route exact path="/admin/accounts/new" render={() => <Editor isNew={true} />} />
    <Route
      exact
      path="/admin/accounts/:id/shops/new"
      render={(props) => <ShopEditor accountId={props.match.params.id} isNew={true} />}
    />
    <Route
      exact
      path="/admin/accounts/:id/shops/:shopId"
      render={(props) => (
        <ShopShow accountId={props.match.params.id} shopId={props.match.params.shopId} />
      )}
    />
    <Route
      exact
      path="/admin/accounts/:id/shops/:shopId/edit"
      render={(props) => (
        <ShopEditor
          accountId={props.match.params.id}
          isNew={false}
          shopId={props.match.params.shopId}
        />
      )}
    />
    <Route
      exact
      path="/admin/accounts/:id/edit"
      render={(props) => <Editor accountId={props.match.params.id} isNew={false} />}
    />
    <Route
      exact
      path="/admin/accounts/:id"
      render={(props) => <Show accountId={props.match.params.id} />}
    />
  </Switch>
);

export default AdminAccounts;
