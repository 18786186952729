import React from 'react';
import Paginate from '../../../components/Paginate';
import { Link } from 'react-router-dom';
import NoResult from '../../../components/common/NoResult';
import PropTypes from 'prop-types';
import Translator from '../../../services/Translator';
import styled from 'styled-components';

const Table = ({ credentials, onPageClick }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="w-20" scope="col">
            Usuario
          </th>
          <th className="w-10" scope="col">
            Estado
          </th>
          <th className="w-20" scope="col">
            Proveedor
          </th>
          <th className="w-10" scope="col">
            Cuenta
          </th>
          <th className="w-10" scope="col">
            Ver
          </th>
          <th className="w-10" scope="col">
            Editar
          </th>
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {credentials.map((model) => {
          return (
            <tr key={model.id}>
              <td>
                <strong>{model.username}</strong>
              </td>
              <TdStatus status={model.status}>{Translator.credentialStatus(model.status)}</TdStatus>
              <td>{Translator.infoProvider(model.infoProvider)}</td>
              <td>
                {model.account !== null ? (
                  <Link to={`/admin/accounts/${model.account.id}`}>{model.account.name}</Link>
                ) : (
                  ''
                )}
              </td>
              <td>
                <Link to={`/admin/credentials/${model.id}`}>Ver</Link>
              </td>
              <td>
                <Link to={`/admin/credentials/${model.id}/edit`}>Editar</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {!credentials.length ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

const TdStatus = styled.td`
  color: ${(props) => {
    switch (props.status) {
      case 'ok':
        return 'green';
      case 'warning':
        return 'orange';
      case 'pending':
        return 'yellow';
      case 'incorrect':
        return 'red';
      case 'blocked':
        return 'red';
      default:
        return 'black';
    }
  }};
`;

Table.propTypes = {
  credentials: PropTypes.array,
  onPageClick: PropTypes.func
};

export default Table;
