import React from 'react';
import PropTypes from 'prop-types';
import GenericInput from '../common/GenericSearchBar/GenericInput';

class EstablishmentNumberInput extends React.Component {
  handleChange = (establishmentNumberOrId) => {
    this.props.handleInputChange({ establishmentNumberOrId: establishmentNumberOrId });
  };

  render() {
    return (
      <GenericInput
        handleInputChange={this.handleChange}
        inputName={'establishmentSearch'}
        inputPlaceholder={'Número de Establecimiento'}
      />
    );
  }
}

EstablishmentNumberInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default EstablishmentNumberInput;
