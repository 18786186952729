import React, { useState } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from '../common/Dropdown';

const SaveDropdown = ({ onFinish, onUnlock, onFinishWithException }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((v) => !v);

  return (
    <Dropdown open={open} toggle={toggle}>
      <DropdownToggle>Guardar</DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={onUnlock}>Guardar progreso</DropdownItem>
        <DropdownItem onClick={onFinish}>Guardar y marcar como Terminada</DropdownItem>
        {onFinishWithException && (
          <DropdownItem onClick={onFinishWithException}>
            Validar documentos y marcar como Terminada
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SaveDropdown;
