import React, { useMemo } from 'react';

import { ScraperService } from '../../../../services/ScraperService';
import ProviderBox from '../ProviderBox';

const ProviderDayRow = ({
  selected,
  disabled,
  infoProvider,
  managements = [],
  onClick,
  className
}) => {
  const info = useMemo(() => {
    return ScraperService().providerInfo(managements);
  }, [managements]);

  const color = useMemo(() => {
    return ScraperService().dayFinishedColor(managements, infoProvider);
  }, [managements, infoProvider]);

  return (
    <ProviderBox
      className={className}
      color={color}
      disabled={disabled}
      infoProvider={infoProvider}
      onClick={onClick}
      selected={selected}
    >
      {info.ok}/{info.total}
    </ProviderBox>
  );
};

export default ProviderDayRow;
