import React from 'react';
import { CloudDone, CloudOff } from '@styled-icons/material';
import { Label } from '@increase/typed-components';
import styled from 'styled-components';

const StyledLabel = styled(Label)`
  align-items: center;
`;

export const CredentialDocumentsStatus = ({ managements }) => {
  if (!managements) {
    return null;
  }
  const currentDocuments = managements.files && managements.files.length;
  const expectedDocuments = managements.expectedDocuments;
  const gotAllDocuments = expectedDocuments > 0 && currentDocuments === expectedDocuments;
  const StatusIcon = gotAllDocuments ? CloudDone : CloudOff;

  return (
    <div className="mt-3 mb-3">
      <StyledLabel className="card-row">
        <StatusIcon className="mr-2" size={24} />
        {currentDocuments}/{expectedDocuments} (Documentos obtenidos / esperados)
      </StyledLabel>
    </div>
  );
};
