import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const AccountService = async () => {
  const gqlClient = GraphqlClient();

  const listQuery = gql`
    query Accounts($filter: api_AccountsFilter, $limit: Int, $offset: Int) {
      accounts: api_accounts(filter: $filter, limit: $limit, offset: $offset) {
        id
        name
        taxId
        autogenerated
        active
      }
    }
  `;

  const groupedListQuery = gql`
    query AccountsGroupedBy($filter: api_AccountsFilter, $limit: Int, $offset: Int) {
      accounts: api_accounts(filter: $filter, limit: $limit, offset: $offset) {
        id
        name
        supportTag
        infoProviders
      }
    }
  `;

  const getQuery = gql`
    query Account($id: String!) {
      account: api_account(id: $id) {
        id
        name
        taxId
        activated
        validated
        autogenerated
        onboardingFinished
        authorizationLetterLink
        shops {
          id
          name
        }
        establishments {
          id
          number
        }
        platformInfo {
          id
          products {
            id
            name
          }
        }
      }
    }
  `;

  const getForScrapQuery = gql`
    query Account($id: String!) {
      account: api_account(id: $id) {
        id
        name
        infoProviders
      }
    }
  `;

  const updateMutation = gql`
    mutation Update($id: String!, $params: plt_AccountAttributes!) {
      operation: plt_accountUpdate(id: $id, params: $params) {
        account {
          id
        }
        errors
      }
    }
  `;

  const createMutation = gql`
    mutation Create($params: plt_AccountAttributes!) {
      operation: plt_accountCreate(params: $params) {
        account {
          id
        }
        errors
      }
    }
  `;

  const validateMutation = gql`
    mutation Validate($id: String!) {
      operation: api_validateAccount(id: $id) {
        id
      }
    }
  `;

  return {
    list: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: listQuery,
          variables: { filter, limit, offset }
        })
        .then((data) => data.data.accounts)
        .catch(() => []); // TODO: do something
    },
    groupedList: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: groupedListQuery,
          variables: { filter, limit, offset }
        })
        .then((data) => data.data.accounts)
        .catch(() => []);
    },
    get: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getQuery,
          variables: { id }
        })
        .then((data) => data.data.account)
        .catch(() => null); //TODO: do something
    },
    getForScrap: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getForScrapQuery,
          variables: { id }
        })
        .then((data) => data.data.account)
        .catch(() => null); //TODO: do something
    },
    update: (id, params) => {
      return gqlClient
        .mutate({
          mutation: updateMutation,
          variables: { id, params }
        })
        .then((data) => data.data.operation)
        .catch(() => null);
    },
    create: (params) => {
      return gqlClient
        .mutate({
          mutation: createMutation,
          variables: { params }
        })
        .then((data) => data.data.operation)
        .catch(() => null);
    },
    validateAccount: (id) => {
      return gqlClient
        .mutate({
          mutation: validateMutation,
          variables: { id: id }
        })
        .then((data) => data.data.operation)
        .catch(() => null);
    }
  };
};

export default AccountService;
