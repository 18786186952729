import React from 'react';
import { Container, Row } from 'shards-react';
import PageTitle from '../../../components/PageTitle';
import SchedulerService from '../../../services/SchedulerService';

import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';
import RequestIdInput from '../../../components/inputs/RequestIdInput';

import Table from './Table';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      jobs: [],
      loading: false
    };

    this.inputs = [RequestIdInput];
  }

  onSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.queryJobs());
  };

  setLoading = (boolean) => {
    this.setState({ loading: boolean });
  };

  queryJobs = async () => {
    this.setState({ loading: true });

    window.scrollTo(0, 0);

    const schedulerService = await SchedulerService();
    schedulerService
      .getJobs(this.state.filter)
      .then((data) => {
        this.setState({ jobs: data.jobs });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Job Tracker" />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} inputWidth="100" />
          <Table jobs={this.state.jobs || []} setLoading={this.setLoading} />
        </LoadingProvider>
      </Container>
    );
  }
}

export default List;
