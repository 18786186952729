import React from 'react';
import { Container, Row } from 'shards-react';
import styled from 'styled-components';

const TotalsBox = ({ totals: { monthlyDailies, payments, match } }) => (
  <ContainerTotals className="text-center FillParent" color={match ? 'green' : 'red'}>
    <Row>
      <LessMarginP bottom={'-5px'} top={'none'}>
        {monthlyDailies.join(', ') || '-'}
      </LessMarginP>
    </Row>
    <CustomHrContainer>
      <p>M</p>
      <CustomHr />
      <p>D</p>
    </CustomHrContainer>
    <Row>
      <LessMarginP bottom={'none'} top={'-5px'}>
        {payments.join(', ') || '-'}
      </LessMarginP>
    </Row>
  </ContainerTotals>
);

const ContainerTotals = styled(Container)`
  && {
    color: ${(props) => props.color};
    padding: 10px 0px 10px 0px;
    position: relative;
  }
`;

const CustomHr = styled.hr`
  border-top: 1px solid
  margin-bottom: 0.25em
  margin-top: 0.25em
  margin-left: 20px;
  margin-right: 20px;
`;

const CustomHrContainer = styled.div`
  font-size: 8px;
  text-align: left;
  p {
    padding-left: 20px;
  }
`;

const LessMarginP = styled.p`
  && {
    margin-top: ${(props) => props.top};
    margin-bottom ${(props) => props.bottom};
  }
`;
export default TotalsBox;
