import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from 'shards-react';
import Select from 'react-select';

import PageTitle from '../../../components/PageTitle';
import ShopService from '../../../services/ShopService';
import AccountService from '../../../services/AccountService';
import Errors from '../../../components/Errors';

class ShopEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shop: {
        id: props.shopId,
        name: '',
        account: {
          id: props.accountId,
          establishments: []
        },
        establishments: []
      },
      redirect: null
    };
  }

  componentDidMount() {
    if (this.props.isNew) {
      this.findAccount();
    } else {
      this.findShop();
    }
  }

  findAccount = async () => {
    const accountService = await AccountService();
    accountService.get(this.props.accountId).then((data) => {
      this.setState({ shop: { account: data } });
    });
  };

  findShop = async () => {
    const shopService = await ShopService();
    shopService.get(this.props.shopId).then((data) => {
      data['establishmentIds'] = data.establishments.map((establishment) =>
        this.establishmentToOption(establishment)
      );
      this.setState({ shop: data });
    });
  };

  createShop = async (params) => {
    const shopService = await ShopService();
    return shopService.create(params);
  };

  updateShop = async (id, params) => {
    const shopService = await ShopService();
    return shopService.update(id, params);
  };

  establishmentToOption = (establishment) => {
    return {
      value: establishment.id,
      label: establishment.number
    };
  };

  handleSubmit = (shop) => {
    const action = this.props.isNew ? this.createShop : this.updateShop;

    const establishmentIds =
      shop.establishmentIds !== undefined
        ? shop.establishmentIds.map((establishmentOption) => establishmentOption.value)
        : '';

    const params = {
      name: shop.name,
      establishmentIds: establishmentIds
    };

    if (this.props.isNew) {
      params.accountId = shop.account.id;
    }

    action(params, this.state.shop.id).then((data) => {
      if ((data.errors || []).length > 0) {
        this.setState({ errors: data.errors });
      } else {
        this.setState({ redirect: data.shop.account.id });
      }
    });
  };

  render() {
    const cardTitle = this.props.isNew ? 'Crear' : 'Editar';
    const buttonText = this.props.isNew ? 'Crear shop' : 'Editar shop';

    return this.state.redirect === null ? (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Shops" />
        </Row>
        <Row>
          <Col>
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">{cardTitle}</h6>
              </CardHeader>
              <CardBody>
                <Errors errors={this.state.errors} />
                <Formik enableReinitialize initialValues={this.state.shop}>
                  {(props) => {
                    const { values, isSubmitting, handleChange, handleBlur, setFieldValue } = props;

                    return (
                      <Form>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="name">Nombre</label>
                            <FormInput
                              id="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Nombre"
                              type="text"
                              value={values.name}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="accountId">Cuenta</label>
                            <FormInput
                              disabled={true}
                              id="accountId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={this.state.shop.account.name}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="establishmentIds">Establecimientos</label>
                            <Select
                              id="establishmentIds"
                              isMulti={true}
                              onBlur={handleBlur}
                              onChange={(option) => {
                                const establishments = !this.props.isNew
                                  ? this.state.shop.establishments.map((e) => e.id)
                                  : [];
                                const selected = option.map((op) => op.value);
                                if (!establishments.every((elem) => selected.indexOf(elem) > -1)) {
                                  return;
                                }
                                setFieldValue('establishmentIds', option);
                              }}
                              options={this.state.shop.account.establishments.map((establishment) =>
                                this.establishmentToOption(establishment)
                              )}
                              placeholder="Establecimientos"
                              value={values.establishmentIds}
                            />
                          </Col>
                        </Row>
                        <Button
                          className="btn-primary"
                          disabled={isSubmitting}
                          onClick={() => this.handleSubmit(values)}
                        >
                          {buttonText}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    ) : (
      <Redirect to={`/admin/accounts/${this.state.redirect}`} />
    );
  }
}

ShopEditor.defaultProps = {
  isNew: true
};

ShopEditor.propTypes = {
  accountId: PropTypes.string,
  isNew: PropTypes.bool
};

export default ShopEditor;
