import React, { useState, useEffect } from 'react';
import { Collapse } from 'shards-react';
import styled from 'styled-components';
import { Label } from '@increase/typed-components';

import CredentialDocuments from './CredentialDocuments';
import { ScraperService } from '../../../../services/ScraperService';
import StatusBadge from './StatusBadge';
import CredentialStatus from '../CredentialStatus';
import CredentialDetail from '../CredentialDetail';
import { color } from '../../../../utils/theme';

const CredentialContainer = styled.div`
  border: 0px solid ${color('brandWhite', 'medium')};
  border-top-width: 1px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  &:last-child {
    border-bottom-width: 1px;
  }

  .header {
    display: flex;
    align-items: center;
    cursor: pointer;

    .toggle {
      text-align: right;
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    label {
      cursor: pointer;
    }
  }
`;

const ToggleButton = styled.i.attrs({
  className: 'material-icons'
})`
  && {
    cursor: pointer;
    font-size: 1rem;
    color: ${color('brandBlack', 'regular')};
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const CredentialLabel = styled(Label)`
  color: ${color('brandBlack', 'light')};
  margin-bottom: 0;
`;

const CredentialUsername = styled(Label)`
  color: ${color('brandWhite', 'bold')};
  text-transfrom: none;
  margin-bottom: 0;
`;

const CredentialData = styled(CredentialDetail)`
  flex-direction: column;
  background: ${color('brandWhite', 'light')};
  padding-left: 3.5rem !important;
`;

const CredentialInfo = ({ info, onClick, active }) => {
  const { credential, date } = info;
  // Only render documents the first time the credential is active.
  // Avoid unnecesary queries
  const [renderDocuments, setRenderDocuments] = useState(false);

  const color = ScraperService().credentialFinishedColor(info);

  useEffect(() => {
    if (active) {
      setRenderDocuments(true);
    }
  }, [active]);

  return (
    <CredentialContainer>
      <div className="header card-row" onClick={() => onClick(info)}>
        <div className="d-inline-flex align-items-center">
          <StatusBadge color={color} size="20px" />
          <CredentialLabel className="mx-3">Credencial</CredentialLabel>
          <CredentialUsername>{credential.username}</CredentialUsername>
        </div>
        <div className="d-inline-block toggle">
          <CredentialStatus credential={info} />
          <ToggleButton title={active ? 'Click para cerrar' : 'Click para abrir'}>
            {active ? 'expand_less' : 'expand_more'}
          </ToggleButton>
        </div>
      </div>
      <Collapse open={active}>
        <CredentialData
          className="my-3 py-3 card-row"
          loginPage={credential.loginPage}
          otherLogin={credential.otherLogin}
          password={credential.password}
          status={credential.status}
        />
        {renderDocuments && (
          <CredentialDocuments className="card-row" credential={credential} date={date} />
        )}
      </Collapse>
    </CredentialContainer>
  );
};

export default CredentialInfo;
