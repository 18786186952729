import React from 'react';
import { FormInput } from 'shards-react';
import PropTypes from 'prop-types';

class GenericInput extends React.Component {
  handleChange(input) {
    this.props.handleInputChange(input);
  }

  render() {
    const { inputName, inputPlaceholder } = this.props;
    return (
      <FormInput
        id={`${inputName}Input`}
        name={inputName}
        onChange={(e) => {
          this.handleChange(e.target.value);
        }}
        placeholder={inputPlaceholder}
      />
    );
  }
}

GenericInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired
};

export default GenericInput;
