import React from 'react';
import { Alert } from 'shards-react';

const Errors = ({ errors }) => {
  if (errors !== undefined && errors !== null) {
    return errors.map((error, index) => {
      return (
        <Alert key={index} theme="danger">
          {error}
        </Alert>
      );
    });
  }
  return '';
};

export default Errors;
