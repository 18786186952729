import React, { useState } from 'react';
import styled from 'styled-components';
import { InputStyledBase } from './InputStyledBase';
import { MdiArrowUpIcon } from '@increasecard/icons';
import { InputBaseProps, InputBase } from './InputBase';
import { CellText } from '../typography/typography';

export interface InputFileProps extends Omit<InputBaseProps, 'onChange' | 'value'> {
  noFilesText?: string;
  innerLabel?: string;
  onChange: (file: File | null) => void;
}

const InputControlWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const InputSubstitute = styled(InputStyledBase)<Pick<typeof InputStyledBase.arguments, 'disabled'>>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  input:disabled + & {
    opacity: 0.16;
  }

  input:not(:disabled):hover + & {
    border: 1px solid ${({ theme }) => theme.colorsNew.gray.black};
  }
`;

const ValueContainer = styled.div`
  padding: 8px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InnerLabel = styled(CellText)`
  font-weight: bold;
  min-width: max-content;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${({ theme }) => theme.colorsNew.primaryAction.regular};
  color: ${({ theme }) => theme.colorsNew.gray.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;

  /* Some CSS magic for being over the border */
  height: calc(100% + 2px);
  margin: -1px;

  input:hover + * & {
    box-shadow: 1px 2px 3px rgba(15, 107, 255, 0.27), 1px 2px 2px rgba(15, 107, 255, 0.36);
  }
`;

export const InputFile = ({
  innerLabel,
  onChange,
  noFilesText = 'Ningún archivo seleccionado',
  ...rest
}: InputFileProps) => {
  const [file, setFile] = useState<File | null>(null);
  const inputValue = file !== null ? file.name : noFilesText;
  const handleFileSelected = (f: File | null) => {
    setFile(f);
    onChange(f);
  };
  return (
    <InputBase {...rest}>
      {(inputProps: typeof InputStyledBase.arguments) => (
        <>
          <InputControlWrapper>
            <Input
              {...inputProps}
              onChange={(e) => handleFileSelected(e.target.files && e.target.files[0])}
              type="file"
            />
            <InputSubstitute
              as="label"
              disabled={inputProps.disabled}
              htmlFor={inputProps.id}
              size="normal"
            >
              <ValueContainer>{inputValue}</ValueContainer>
              <InnerLabel>{innerLabel || <MdiArrowUpIcon height="24" width="24" />}</InnerLabel>
            </InputSubstitute>
          </InputControlWrapper>
        </>
      )}
    </InputBase>
  );
};
