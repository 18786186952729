import React from 'react';
import Paginate from '../../../components/Paginate';
import { Link } from 'react-router-dom';
import NoResult from '../../../components/common/NoResult';
import PropTypes from 'prop-types';
import Translator from '../../../services/Translator';
import TickOrCross from '../../../utils/TickOrCross';

const Table = ({ payments, onPageClick }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="border-0" scope="col">
            Fecha de Pago
          </th>
          <th className="border-0" scope="col">
            Número de Liquidación
          </th>
          <th className="border-0" scope="col">
            Establecimiento
          </th>
          <th className="border-0" scope="col">
            Proveedor
          </th>
          <th className="border-0" scope="col">
            Tarjeta
          </th>
          <th className="border-0" scope="col">
            Moneda
          </th>
          <th className="border-0" scope="col">
            Presentado
          </th>
          <th className="border-0" scope="col">
            Descontado
          </th>
          <th className="border-0" scope="col">
            Acreditado
          </th>
          <th className="border-0" scope="col">
            Balanceada
          </th>
          <th className="border-0" scope="col" />
        </tr>
      </thead>
      <tbody>
        {payments.map((payment) => {
          const green =
            payment.focusedForApi ||
            payment.focusedForChargebacks ||
            payment.focusedForTransactions ||
            payment.focusedForPayments;
          return (
            <tr className={`table-${green ? 'success' : 'secondary'}`} key={`tr-${payment.id}`}>
              <td>{payment.paymentDate || ''}</td>
              <td>{payment.clearingNumber || ''}</td>
              <td>{payment.establishment ? payment.establishment.number : ''}</td>
              <td>{Translator.infoProvider(payment.infoProvider)}</td>
              <td>{Translator.cardBrand(payment.cardBrandId)}</td>
              <td>{payment.currency || ''}</td>
              <td>{payment.totalAmount || ''}</td>
              <td>{payment.totalDeduction || ''}</td>
              <td>{payment.totalEarn || ''}</td>
              <td>{TickOrCross(payment.isBalanced) || ''}</td>
              <td>
                <Link to={`/admin/payments/${payment.id}`}>Ver</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {!payments.length ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

Table.propTypes = {
  onPageClick: PropTypes.func,
  payments: PropTypes.array
};

export default Table;
