import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const EstablishmentService = () => {
  const gqlClient = GraphqlClient();

  const listQuery = gql`
    query Establishments($filter: api_EstablishmentsFilter, $limit: Int, $offset: Int) {
      establishments: api_establishments(filter: $filter, limit: $limit, offset: $offset) {
        id
        number
        processor
        cardBrandId
        establishmentsCredentials {
          id
          credentialId
        }
        account {
          id
          name
        }
        shop {
          id
          name
        }
      }
    }
  `;

  const listFullEstablishments = gql`
    query listFullEstablishments($filter: api_EstablishmentsFilter, $limit: Int, $offset: Int) {
      establishments: api_establishments(filter: $filter, limit: $limit, offset: $offset) {
        id
        number
        processor
        cardBrandId
        shop {
          id
          name
        }
        establishmentsCredentials {
          id
          credentialId
        }
        lastPayment {
          id
          paymentDate
        }
        credentials {
          id
          username
          otherLogin
          country
          infoProvider
          status
          blockedAt
          deletedAt
          status
          electronic
        }
      }
    }
  `;

  const getQuery = gql`
    query Establishment($id: String!) {
      establishment: api_establishment(id: $id) {
        id
        number
        processor
        cardBrandId
        establishmentsCredentials {
          id
          credentialId
          existsInScheduler
        }
        account {
          id
          name
        }
        shop {
          id
          name
          account {
            id
            name
          }
        }
        credentials {
          id
          username
          infoProvider
          status
        }
      }
    }
  `;

  const updateMutation = gql`
    mutation Update($id: String!, $params: api_EstablishmentAttributes!) {
      update: api_establishmentUpdate(id: $id, params: $params) {
        establishment {
          id
          number
        }
        errors
      }
    }
  `;

  const createMutation = gql`
    mutation Create($params: api_EstablishmentAttributes!) {
      mutation: api_establishmentCreate(params: $params) {
        establishment {
          id
          number
        }
        errors
      }
    }
  `;

  return {
    list: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: listQuery,
          variables: { filter: filter, limit, offset }
        })
        .then((data) => {
          return data.data.establishments;
        })
        .catch((error) => {
          throw error;
        });
    },
    get: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getQuery,
          variables: { id: id }
        })
        .then((data) => data.data.establishment)
        .catch((error) => {
          throw error;
        });
    },
    update: (number, credentialIds, accountId, shopId, id, cardBrandId) => {
      return gqlClient
        .mutate({
          mutation: updateMutation,
          variables: {
            id: id,
            params: {
              number: number,
              credentialIds: credentialIds,
              accountId: accountId,
              shopId: shopId,
              cardBrandId: cardBrandId
            }
          }
        })
        .then((data) => data.data.update)
        .catch((error) => {
          throw error;
        });
    },
    create: (number, credentialIds, accountId, shopId, id, cardBrandId) => {
      return gqlClient
        .mutate({
          mutation: createMutation,
          variables: {
            params: {
              id: id,
              number: number,
              credentialIds: credentialIds,
              accountId: accountId,
              shopId: shopId,
              cardBrandId: cardBrandId
            }
          }
        })
        .then((data) => data.data.mutation)
        .catch((error) => {
          throw error;
        });
    },
    listWithShopsAndCredentials: (filter, limit, offset) => {
      if (filter === undefined) {
        filter = {};
      }
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: listFullEstablishments,
          variables: {
            filter,
            limit,
            offset
          }
        })
        .then((data) => data.data.establishments)
        .catch((error) => {
          throw error;
        });
    }
  };
};

export default EstablishmentService;
