import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './users/List';
import Show from './users/Show';
import Editor from './users/Editor';

const AdminUser = () => (
  <Switch>
    <Route component={List} exact path="/admin/users" />
    <Route exact path="/admin/users/new" render={() => <Editor isNew={true} />} />
    <Route component={Show} exact path="/admin/users/:id" />
    <Route component={Editor} exact path="/admin/users/:id/edit" />
  </Switch>
);

export default AdminUser;
