import React from 'react';
import { Alert } from 'shards-react';

class MessageDismissable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: true };
  }

  componentDidUpdate = () => {
    /* eslint-disable react/no-direct-mutation-state */
    this.state.visible = true;
    /* eslint-enable react/no-direct-mutation-state */
  };

  dismiss = () => {
    this.props.dismissMessage(this.props.msg);
    this.setState({ visible: false });
  };

  render() {
    return (
      <Alert
        dismissible={this.dismiss}
        open={this.state.visible}
        theme={this.props.theme || 'primary'}
      >
        {this.props.msg}
      </Alert>
    );
  }
}

export default MessageDismissable;
