import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'shards-react';
import PropTypes from 'prop-types';

const UserShops = ({ user }) => (
  <React.Fragment>
    <Card className="mb-12" small>
      <CardHeader className="border-bottom">
        <Row>
          <Col lg="12" sm="12" xs="12">
            <h6 className="m-0">Sucursales</h6>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <table className="table mb-0 table-hover table-borderless">
          <thead>
            <tr>
              <th className="border-0" scope="col">
                Nombre
              </th>
              <th className="border-0" scope="col">
                Ver
              </th>
            </tr>
          </thead>
          <tbody>
            {user.shops !== undefined
              ? user.shops.map((shop) => {
                  return (
                    <tr key={shop.id}>
                      <td>
                        <Link to={`/admin/accounts/${user.account.id}/shops/${shop.id}`}>
                          {shop.name}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/admin/accounts/${user.account.id}/shops/${shop.id}`}>Ver</Link>
                      </td>
                    </tr>
                  );
                })
              : ''}
          </tbody>
        </table>
      </CardBody>
      <CardFooter />
    </Card>
  </React.Fragment>
);

UserShops.propTypes = {
  user: PropTypes.object
};

export default UserShops;
