import React from 'react';
import PropTypes from 'prop-types';
import NoResult from '../../../components/common/NoResult';
import Translator from '../../../services/Translator';

const Table = ({ metrics }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="w-10" scope="col">
            Procesadora
          </th>
          <th className="w-10" scope="col">
            Tarjeta
          </th>
          <th className="w-10" scope="col">
            Presentado %
          </th>
          <th className="w-10" scope="col">
            Descontado %
          </th>
          <th className="w-10" scope="col">
            Acreditado %
          </th>
          <th className="w-10" scope="col">
            Cantidad
          </th>
          <th className="w-10" scope="col">
            Actualizado
          </th>
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {metrics.map((model, i) => {
          return (
            <tr key={i}>
              <td>{Translator.infoProvider(model.infoProvider)}</td>
              <td>{Translator.cardBrand(model.cardBrandId)}</td>
              <td>{model.totalAmountHitRatio * 100.0}%</td>
              <td>{model.totalDeductionHitRatio * 100.0}%</td>
              <td>{model.totalEarnHitRatio * 100.0}%</td>
              <td>{model.records}</td>
              <td>{model.updatedAt}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {metrics.length < 1 ? <NoResult /> : null}
  </React.Fragment>
);

Table.propTypes = {
  metrics: PropTypes.array.isRequired
};

export default Table;
