import React from 'react';
import {
  DropdownToggle as ShardToggle,
  DropdownMenu as ShardMenu,
  DropdownItem as ShardItem
} from 'shards-react';
import { Button, CellText } from '@increase/typed-components';
import styled from 'styled-components';

import { color } from '../../../../utils/theme';

const ToggleContainer = styled.div`
  display: inline-block;
  button {
    padding-right: 0;
  }
`;

const Caret = styled.i.attrs({
  className: 'material-icons',
  children: 'arrow_drop_down'
})`
  &&& {
    line-height: inherit;
    font-weight: bold;
    color: inherit;

    padding: 0 0.625rem;
    border-left: 1px solid rgba(24, 32, 38, 0.16);
    margin-left: 1.5rem;
    margin-right: 0;
  }
`;

const Toggle = ({ children, className, ...rest }) => {
  return (
    <ToggleContainer className={className}>
      <Button {...rest} styles={{ paddingRight: 0 }}>
        {children}
        <Caret />
      </Button>
    </ToggleContainer>
  );
};

export { Dropdown } from 'shards-react';

export const DropdownToggle = ({ children }) => {
  return <ShardToggle tag={Toggle}>{children}</ShardToggle>;
};

export const DropdownMenu = styled(ShardMenu)`
  && {
    padding: 0;
    background: ${color('nightSky', 'medium')};

    border: 1px solid ${color('nightSky', 'bold')};
    box-sizing: border-box;

    box-shadow: 0px 3px 9px rgba(24, 32, 38, 0.18);
    border-radius: 4px;

    .dropdown-item {
      &:hover,
      &:focus {
        background: ${color('nightSky', 'bold')};
      }

      span {
        color: ${color('brandWhite', 'regular')};
      }
    }
  }
`;

export const DropdownItem = ({ children, ...rest }) => {
  return (
    <ShardItem {...rest}>
      <CellText>{children}</CellText>
    </ShardItem>
  );
};
