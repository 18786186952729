import React from 'react';
import { DatePicker as ReactDatePicker } from 'shards-react';
import styled from 'styled-components';

import { color } from '../../../../utils/theme';

const DatePickerContainer = styled.div`
  input {
    background: ${color('brandWhite', 'regular')};
    border: 1px solid ${color('brandWhite', 'medium')};
    border-radius: 4px;
    color: ${color('brandBlack', 'regular')};
    font-size: 13px;
    line-height: 14px;
    outline: none;
    padding: 12px;
    padding-left: ${(props) => (props.type === 'search' ? '40px' : null)};
    width: 100%;
    &:focus {
      border: 1px solid ${color('brandBlack', 'regular')};
    }
    &::placeholder {
      color: ${color('brandWhite', 'bold')};
    }
    padding-left: 48px;
  }

  .react-datepicker__input-container:before {
    position: absolute;
    top: 25%;
    left: 10px;
    display: inline-block;

    content: 'insert_invitation';
    font-size: 13px;
    font-family: 'Material Icons';
    font-size: 20px;
    line-height: 1;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    border-right: 1px solid ${color('brandWhite', 'medium')};
    padding-right: 8px;
  }

  .react-datepicker__close-icon {
    top: 30%;
  }
`;

const DatePicker = ({ className, ...rest }) => {
  return (
    <DatePickerContainer className={className}>
      <ReactDatePicker {...rest} />
    </DatePickerContainer>
  );
};

export default DatePicker;
