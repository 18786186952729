import React from 'react';
import { Container, Row } from 'shards-react';
import PageTitle from '../../../components/PageTitle';
import MonthlyMetricsService from '../../../services/MonthlyMetricsService';

import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';

import InfoProviderSimpleInput from '../../../components/inputs/InfoProviderSimpleInput';
import MonthAndYearInput from '../../../components/inputs/MonthAndYearInput';
import Table from './Table';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      metrics: [],
      loading: true
    };

    this.inputs = [MonthAndYearInput, InfoProviderSimpleInput];
  }

  componentDidMount() {
    const currentDate = new Date();

    this.setState(
      {
        filter: {
          month: currentDate.getMonth(),
          year: currentDate.getFullYear()
        }
      },
      () => this.getMonthlyMetrics()
    );
  }

  onSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.getMonthlyMetrics());
  };

  getMonthlyMetrics = async () => {
    this.setState({ loading: true });

    window.scrollTo(0, 0);

    const monthlyMetricsService = await MonthlyMetricsService();
    monthlyMetricsService
      .getMetrics(this.state.filter)
      .then((data) => {
        this.setState({
          metrics: data || []
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle
            className="text-sm-left"
            sm="4"
            subtitle=""
            title="Comparador mensual - Métricas"
          />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} inputWidth="50" />
          <Table metrics={this.state.metrics} />
        </LoadingProvider>
      </Container>
    );
  }
}

export default List;
