import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class GenericInputSelect extends React.Component {
  handleChange(selectedItem) {
    this.props.handleInputChange(selectedItem);
  }

  render() {
    const { selectName, selectPlaceholder, selectOptions } = this.props;
    return (
      <Select
        className="basic-multi-select"
        classNamePrefix="select"
        name={selectName}
        onChange={(e) => {
          this.handleChange(e.value);
        }}
        options={selectOptions}
        placeholder={selectPlaceholder}
      />
    );
  }
}

GenericInputSelect.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  selectName: PropTypes.string.isRequired,
  selectOptions: PropTypes.array.isRequired,
  selectPlaceholder: PropTypes.string.isRequired
};

export default GenericInputSelect;
