import { useState, useEffect } from 'react';
import AccountService from '../services/AccountService';
import { ScraperService } from '../services/ScraperService';
import { getDocumentTypesByCountry } from '../utils/manualScraper';

export const useAccountForScrap = (accountId) => {
  const [account, setAccount] = useState();

  useEffect(() => {
    async function getAccount(id) {
      if (id) {
        const accountService = await AccountService();
        const account = await accountService.getForScrap(accountId);

        setAccount(account);
      }
    }

    getAccount(accountId);
  }, [accountId]);

  return account;
};

export const useCredentialDocuments = (credentialId, filter, offset, limit) => {
  // NOTE: we can not ensure that filter object is always the same instance. Destruct it to use as "effect" dependency
  const { date, createdAt, unlimited } = filter;
  const [files, setFiles] = useState([]);
  const [managements, setManagements] = useState({});
  const [loading, setLoading] = useState(true);
  const documentTypes = getDocumentTypesByCountry();

  useEffect(() => {
    async function getFiles() {
      const managements = await ScraperService().getFiles(
        credentialId,
        {
          date,
          createdAt,
          unlimited,
          documentType: documentTypes
        },
        limit,
        offset
      );
      if (managements) {
        setManagements(managements);
        setFiles(managements.files);
        setLoading(false);
      }
    }
    setFiles([]);
    if (credentialId) {
      setLoading(true);
      getFiles();
    }
  }, [credentialId, date, createdAt, unlimited, limit, offset, documentTypes]);

  return [files, managements, { loading, setFiles }];
};

export const useCredentialHasDocuments = (credentialId, filter) => {
  const [credentialHasDocuments, setCredentialHasDocuments] = useState();
  const [files, { loading }] = useCredentialDocuments(credentialId, filter, 0, 1);

  useEffect(() => {
    // Prevent cancelations until we are sure it is allowed
    setCredentialHasDocuments(loading || (files && files.length > 0));
  }, [files, loading]);

  return [credentialHasDocuments, setCredentialHasDocuments];
};
