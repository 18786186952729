import React from 'react';
import { Container, Card, CardBody, Row, Col } from 'shards-react';

import PageTitle from '../../../components/PageTitle';
import Errors from '../../../components/Errors';
import Dropzone from 'react-dropzone';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';
import BatchEstablishmentService from '../../../services/BatchEstablishmentService';
import AsyncSelect from 'react-select/lib/Async';
import RegularButton from '../../../components/common/GenericSearchBar/RegularButton';
import DismissableMessagesList from '../../../components/DismissableMessagesList';
import { debouncedFetchAccounts } from '../../../utils/accounts';

class BatchCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      file: null,
      account: {
        id: '',
        name: ''
      },
      loading: false,
      createJobState: []
    };
  }

  handleChange = (file) => {
    this.setState({ file: file[0] });
  };

  dismissMessage = (msg) => {
    this.setState((prevState) => {
      return { createJobState: prevState.createJobState.filter((item) => item !== msg) };
    });
  };

  validParams = () => {
    if (!this.state.file || !this.state.account.id) {
      this.setState({ errors: ['Faltan parametros'] });
      return false;
    }
    const filename = this.state.file.name;
    const extension = filename.split('.')[filename.split('.').length - 1];
    if (extension !== 'xlsx' && extension !== 'xls') {
      this.setState({ errors: ['Solo se aceptan archivos .XLSX o .XLS'] });
      return false;
    }
    return true;
  };

  handleUpload = async () => {
    if (!this.validParams()) {
      return;
    }
    this.setState({ loading: true }, async () => {
      this.uploadFile();
    });
  };

  uploadFile = async () => {
    const batchEstablishmentService = await new BatchEstablishmentService();
    batchEstablishmentService
      .batchCreate(this.state.file.name, this.state.account.id, this.state.file)
      .then((data) => {
        const errors = [];
        const createJobState = [];
        if (data.errors.length) {
          errors.push(data.errors[0]);
        } else {
          createJobState.push('Establecimientos creados!');
        }
        this.setState({ loading: false, errors: errors, createJobState: createJobState });
      });
  };

  handleOnChange = (option) => {
    this.setState({ account: { id: option.value, name: option.label } });
  };

  render() {
    return (
      <LoadingProvider value={this.state.loading}>
        <Container className="main-content-container px-4" fluid>
          <Row className="page-header py-4" noGutters>
            <PageTitle
              className="text-sm-left"
              sm="4"
              subtitle=""
              title="Subir Excel de Establecimientos"
            />
          </Row>
          <Row>
            <Col>
              <Card className="mb-4" small>
                <CardBody>
                  <DismissableMessagesList
                    dismissMessage={this.dismissMessage}
                    messages={this.state.scrapingJobState}
                  />
                  <Errors errors={this.state.errors} />
                  <AsyncSelect
                    cacheOptions={true}
                    className="MarginBottom10"
                    id="accountId"
                    loadOptions={debouncedFetchAccounts}
                    onChange={(option) => {
                      this.handleOnChange(option);
                    }}
                    placeholder="Busca la Account por nombre"
                    value={
                      this.state.account.id
                        ? { value: this.state.account.id, label: this.state.account.name }
                        : ''
                    }
                  />
                  <Dropzone onDrop={(acceptedFiles) => this.handleChange(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div className="Height10em Center" {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.state.file
                            ? this.state.file.path
                            : 'Arrastra el archivo o haz click'}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div className="MarginTop20">
                    <RegularButton displayText={'Subir Archivo'} onClick={this.handleUpload} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingProvider>
    );
  }
}

export default BatchCreator;
