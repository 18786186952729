import React from 'react';
import PropTypes from 'prop-types';

const SiblingsPayments = ({ siblingsPayments = [] }) => (
  <ul>
    {!siblingsPayments.length ? (
      <span>No se encontraron liquidaciones hermanas</span>
    ) : (
      siblingsPayments.map((sibling) => (
        <li key={sibling.id}>
          <a href={`/admin/payments/${sibling.id}`}>{sibling.id}</a>
        </li>
      ))
    )}
  </ul>
);

SiblingsPayments.propTypes = {
  siblingsPayments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  )
};

export default SiblingsPayments;
