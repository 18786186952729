import React from 'react';

import StatusBg from './StatusBg';

const CredentialStatus = ({ credential }) => {
  const { lockedById, managementStatus } = credential;

  const translation = {
    awaiting_start: 'Sin iniciar',
    pending: 'En Proceso',
    in_progress: 'En Proceso',
    done: 'Terminada'
  };

  const statusText = translation[managementStatus] || managementStatus;
  const lockedByText = ({ lockedBy, lockedById }) => {
    return lockedBy ? `${lockedBy.firstName} ${lockedBy.lastName}` : lockedById;
  };

  return (
    <StatusBg status={managementStatus}>
      {lockedById ? `Siendo editado por ${lockedByText(credential)}` : statusText}
    </StatusBg>
  );
};

export default CredentialStatus;
