import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';

import PageTitle from '../../../components/PageTitle';
import AccountService from '../../../services/AccountService';
import AccountDetails from '../../../components/AccountDetails';

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = { account: null };
  }

  componentDidMount() {
    this.findAccount();
  }

  findAccount = async () => {
    const accountService = await AccountService();
    accountService.get(this.props.accountId).then((data) => {
      this.setState({ account: data });
    });
  };

  validateAccount = async () => {
    const accountService = await AccountService();
    accountService.validateAccount(this.state.account.id).then(() => this.findAccount());
  };

  render() {
    if (this.state.account === null) return null;
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Cuentas" />
        </Row>
        <Row>
          <Col md="12">
            <AccountDetails {...this.state.account} validateAccount={this.validateAccount} />
          </Col>
        </Row>
      </Container>
    );
  }
}

Show.propTypes = {
  accountId: PropTypes.string.isRequired
};

export default Show;
