import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, CardHeader, CardBody, CardFooter, Container, Row } from 'shards-react';
import Credentials from './Credentials';
import PageTitle from '../../../components/PageTitle';
import EstablishmentService from '../../../services/EstablishmentService';
import ShowPayments from './ShowPayments';

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = { establishment: null };
  }

  componentDidMount() {
    this.queryEstablishment();
  }

  queryEstablishment = async () => {
    const establishmentService = await EstablishmentService();
    establishmentService.get(this.props.establishmentId).then((data) => {
      this.setState({
        establishment: data
      });
    });
  };

  render() {
    const { establishment } = this.state;
    if (establishment === null) return null;
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Establecimiento" />
        </Row>
        <Row>
          <Container className="main-content-container px-4" fluid>
            <Row>
              <Col md="6">
                <Card className="mb-4" small>
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Establecimiento</h6>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="6" sm="6" xs="12">
                        ID
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        {establishment.id}
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg="6" sm="6" xs="12">
                        Numero
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        {establishment.number}
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg="6" sm="6" xs="12">
                        Procesadora
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        {establishment.processor}
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg="6" sm="6" xs="12">
                        Tarjeta
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        {establishment.cardBrandId || ''}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter />
                </Card>
                <Card className="mb-4" small>
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Cuenta</h6>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="6" sm="6" xs="12">
                        ID
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        <Link to={`/admin/accounts/${establishment.account.id}`}>
                          {establishment.account.id}
                        </Link>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg="6" sm="6" xs="12">
                        Nombre
                      </Col>
                      <Col lg="6" sm="6" xs="12">
                        {establishment.account.name}
                      </Col>
                    </Row>
                  </CardBody>

                  <CardFooter />
                </Card>
                <Card className="mb-4" small>
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Sucursal</h6>
                  </CardHeader>
                  <CardBody>
                    {establishment.shop === null ? (
                      'No hay sucursal asociada'
                    ) : (
                      <Container key={establishment.shop.id}>
                        <Row>
                          <Col lg="4" sm="6" xs="12">
                            ID
                          </Col>
                          <Col lg="4" sm="6" xs="12">
                            <Link
                              to={`/admin/accounts/${establishment.account.id}/shops/${
                                establishment.shop.id
                              }`}
                            >
                              {establishment.shop.id}
                            </Link>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col lg="4" sm="6" xs="12">
                            Nombre
                          </Col>
                          <Col lg="4" sm="6" xs="12">
                            <Link
                              to={`/admin/accounts/${establishment.account.id}/shops/${
                                establishment.shop.id
                              }`}
                            >
                              {establishment.shop.name}
                            </Link>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="mb-4" small>
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Credenciales</h6>
                  </CardHeader>
                  <CardBody>
                    {!establishment.credentials.length ? (
                      'No hay credenciales asociadas'
                    ) : (
                      <Credentials credentials={establishment.credentials} />
                    )}
                  </CardBody>
                </Card>
                <Card className="mb-4" small>
                  <ShowPayments establishmentNumber={establishment.number} />
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }
}

Show.propTypes = {};

export default Show;
