import React, { useState } from 'react';
import { Row, Col, Button, Container, FormInput, FormSelect, Badge } from 'shards-react';
import PageTitle from '../../../components/PageTitle';
import Translator from '../../../services/Translator';
import DocumentService from '../../../services/DocumentService';
import {
  Table,
  THead,
  Th,
  Td,
  TableRow,
  TableHeader,
  TableWrapper,
  H3
} from '@increase/typed-components';

import { Can } from '../../../permissions/helper';
import { Alert } from 'shards-react';
import { Paragraph } from '@increase/typed-components';

const Cant = (
  <Alert theme="danger">
    <Paragraph>Tu usuario no tiene permisos para ejecutar esta funcionalidad.</Paragraph>
  </Alert>
);

const SCRAPER_TYPE = 'scraper';
const PARSER_TYPE = 'parser';
const API_TYPE = 'api';

const REPARSE_TYPES = [
  { id: SCRAPER_TYPE, label: 'Scraper', serviceFunction: 'reparseScraper' },
  { id: PARSER_TYPE, label: 'Parser', serviceFunction: 'reparseParser' },
  { id: API_TYPE, label: 'Api', serviceFunction: 'reparseApi' }
];

export function SupportReparse() {
  const [documents, setDocuments] = useState('');
  const [reparseType, setReparseType] = useState(SCRAPER_TYPE);
  const [result, setResult] = useState();

  const handleDocumentsChange = (e) => {
    const value = e.target.value;
    // remove spaces
    const trimmed = value.replace(/\s/g, '');
    setDocuments(trimmed);
  };

  const handleTypeChange = (e) => {
    setReparseType(e.target.value);
  };

  const onReparse = () => {
    // clear results
    setResult(undefined);
    // convert to array & filter empty items
    const documentsArray = documents.split(',').filter((docId) => docId !== '');
    // call service
    reparseDocuments(documentsArray);
  };

  const reparseDocuments = async (documentsArray) => {
    const documentService = await DocumentService();
    const serviceFunction = REPARSE_TYPES.find((type) => type.id === reparseType).serviceFunction;
    documentService[serviceFunction](documentsArray).then((data) => setResult(data));
  };

  const resultHasData = result && Array.isArray(result) && result.length > 0;

  return (
    <Container className="main-content-container px-4" fluid>
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" sm="4" subtitle="" title="Reparser" />
      </Row>
      <Can action="sup:reparse" renderIfCant={Cant}>
        <Row>
          <Col lg="8" sm="12" xs="12">
            <FormInput
              id="documents"
              onChange={handleDocumentsChange}
              placeholder="Documento(s) ID separados por coma"
              type="text"
              value={documents}
            />
          </Col>
          <Col lg="2" sm="4" xs="12">
            <FormSelect
              className="form-control"
              id="reparseType"
              name="reparseType"
              onChange={handleTypeChange}
              value={reparseType}
            >
              {REPARSE_TYPES.map((type) => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.label}
                  </option>
                );
              })}
            </FormSelect>
          </Col>
          <Col lg="2" sm="4" xs="12">
            <Button disabled={documents === ''} onClick={onReparse}>
              Reparsear
            </Button>
          </Col>
        </Row>
      </Can>
      {result && (
        <Row>
          <Col>
            <TableWrapper className="MarginTop20">
              <TableHeader>
                <H3>Resultado {reparseType.toUpperCase()}</H3>
              </TableHeader>
              <Table hasData={resultHasData} noDataMessage="No hay datos" zebra>
                <THead>
                  <tr>
                    <Th>Documento</Th>
                    <Th>Estado</Th>
                  </tr>
                </THead>
                <tbody>
                  {resultHasData &&
                    result.map((documents) => {
                      const statusIsNotFound = documents.status === 'not_found';
                      const statusDescription = Translator.reparseStatus(documents.status);
                      return (
                        <TableRow key={documents.id}>
                          <Td>{documents.id}</Td>
                          <Td>
                            <H3>
                              <Badge pill theme={statusIsNotFound ? 'danger' : 'success'}>
                                {statusDescription}
                              </Badge>
                            </H3>
                          </Td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
          </Col>
        </Row>
      )}
      <Row>
        <p className="">Scraper: Ingrese los scraped document ids separados por coma</p>
      </Row>
      <Row>
        <p className="">Parser: Ingrese los scraped document ids separados por coma</p>
      </Row>
      <Row>
        <p className="">Api: Ingrese los payment ids separados por coma</p>
      </Row>
    </Container>
  );
}
