import React from 'react';
import PropTypes from 'prop-types';
import GenericInput from '../common/GenericSearchBar/GenericInput';

class RequestIdInput extends React.Component {
  handleChange = (requestId) => {
    this.props.handleInputChange({ requestId: requestId });
  };

  render() {
    return (
      <GenericInput
        handleInputChange={this.handleChange}
        inputName={'RequestIdSearch'}
        inputPlaceholder={'Request UUID'}
      />
    );
  }
}

RequestIdInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default RequestIdInput;
