import styled from 'styled-components';

import { color } from '../../../../utils/theme';

const Link = styled.a`
  color: ${color('skyBlue', 'regular')};
  font-size: 0.75rem;
`;

export default Link;
