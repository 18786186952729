import React, { useState, Fragment } from 'react';
import { Row, Col } from 'shards-react';
import { Link } from 'react-router-dom';
import { InputLabel } from '@increase/typed-components';

import DayDetails from './monthlyView/DayDetails';
import InfoProviderSelect from './monthlyView/InfoProviderSelect';
import Calendar from './monthlyView/Calendar';
import { useAccountForScrap } from '../../../hooks/manualScraper';
import Container from './common/Container';
import PageTitle from './common/PageTitle';
import { beginningOfDay } from '../../../utils/manualScraper';

const MonthlyView = ({ match }) => {
  const { accountId } = match.params;

  const account = useAccountForScrap(accountId);
  const [providerFilter, setProviderFilter] = useState();
  const [selectedDate, setSelectedDate] = useState(beginningOfDay());

  return (
    <Container>
      <Row>
        <Col className="mt-3">
          <Link to="/support/manual_scraper">
            <i className="material-icons">chevron_left</i>
            &nbsp;Volver al buscador
          </Link>
        </Col>
      </Row>
      {account && (
        <Fragment>
          <Row>
            <PageTitle className="pt-3 pb-0" title={account.name} />
          </Row>
          <Row>
            <Col lg="4" xl="2" xs="12">
              <InputLabel>Procesadora</InputLabel>
              <InfoProviderSelect account={account} onSelect={setProviderFilter} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="5" xl="4" xs="12">
              <Calendar
                account={account}
                infoProvider={providerFilter}
                onSelect={setSelectedDate}
                value={selectedDate}
              />
            </Col>
            <Col md="7" xl="8" xs="12">
              <DayDetails account={account} date={selectedDate} infoProvider={providerFilter} />
            </Col>
          </Row>
        </Fragment>
      )}
    </Container>
  );
};

export default MonthlyView;
