import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './establishments/List';
import Show from './establishments/Show';
import Editor from './establishments/Editor';
import BatchCreator from './establishments/BatchCreator';

const AdminEstablishments = () => (
  <Switch>
    <Route component={List} exact path="/admin/establishments" />
    <Route exact path="/admin/establishments/new" render={() => <Editor />} />
    <Route exact path="/admin/establishments/batch" render={() => <BatchCreator />} />
    <Route
      exact
      path="/admin/establishments/:id/edit"
      render={(props) => <Editor establishmentId={props.match.params.id} />}
    />
    <Route
      exact
      path="/admin/establishments/:id"
      render={(props) => <Show establishmentId={props.match.params.id} />}
    />
  </Switch>
);

export default AdminEstablishments;
