import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shards-react';
import { Link } from 'react-router-dom';
import { Flex } from './Flex';

class ActionBar extends React.PureComponent {
  render() {
    const { items } = this.props;
    return (
      <Flex gap="10px" justifyContent="flex-end" style={{ marginTop: '10px' }}>
        {items.map((item) => {
          const { label, route } = item;
          return (
            <Link key={route} to={route}>
              <Button>{label}</Button>
            </Link>
          );
        })}
      </Flex>
    );
  }
}

ActionBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      label: PropTypes.number.isRequired
    })
  ).isRequired
};

export default ActionBar;
