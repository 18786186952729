import React from 'react';
import { Container, Button, Row } from 'shards-react';

import styled from 'styled-components';
import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import DocumentTypeInput from '../../../components/inputs/DocumentTypeInput';
import DateInput from '../../../components/inputs/DateInput';
import WithPlaceHolderAndKey from '../../../components/inputs/WithPlaceHolderAndKey';

class ScraperComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allSelected: false
    };

    this.inputs = [
      WithPlaceHolderAndKey(DateInput, 'Fecha desde', 'dateFrom'),
      WithPlaceHolderAndKey(DateInput, 'Fecha hasta', 'dateTo'),
      DocumentTypeInput
    ];
  }

  onSelectAll = () => {
    if (this.state.allSelected) {
      this.setState({ allSelected: false }, () => this.props.onUnselectAll());
    } else {
      this.setState({ allSelected: true }, () => this.props.onSelectAll());
    }
  };

  render() {
    return (
      <CustomContainer className="main-content-container px-4" fluid>
        <Row>
          <CustomWidthContainer width={'85'}>
            <GenericSearchBar
              displayText={'Mandar a correr'}
              handleSubmit={this.props.onSendToScrap}
              inputWidth={'33'}
              inputs={this.inputs}
            />
          </CustomWidthContainer>
          <CustomWidthContainer width={'15'}>
            <Button onClick={this.onSelectAll} theme="dark">
              Seleccionar todos
            </Button>
          </CustomWidthContainer>
        </Row>
      </CustomContainer>
    );
  }
}

const CustomContainer = styled(Container)`
  && {
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    margin-bottom: 0px;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
  }
`;

const CustomWidthContainer = styled(Container)`
  && {
    width: ${(props) => props.width}%;
    align-items: center;
    vertical-align: middle;
    margin: auto;
  }
`;

export default ScraperComponent;
