import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Row } from 'shards-react';
import PropTypes from 'prop-types';

const UserInvisibleEstablishments = ({ user }) => (
  <React.Fragment>
    <Card className="mb-12" small>
      <CardHeader className="border-bottom">
        <Row style={{ margin: '0.8rem' }}>
          <h6 className="m-0">Establecimientos invisibles</h6>
        </Row>
      </CardHeader>
      <CardBody>
        <table className="table mb-0 table-hover table-borderless">
          <thead>
            <tr>
              <th className="border-0" scope="col" />
              <th className="border-0" scope="col">
                Número
              </th>
              <th className="border-0" scope="col">
                Sucursal
              </th>
            </tr>
          </thead>
          <tbody>
            {user.invisibleEstablishments.map((establishment) => {
              return (
                <tr key={establishment.id}>
                  <td>
                    <Link to={`/admin/establishments/${establishment.id}`}>
                      {establishment.number}
                    </Link>
                  </td>
                  <td>{establishment.shop ? establishment.shop.name : 'Sin shop asociado'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter />
    </Card>
  </React.Fragment>
);

UserInvisibleEstablishments.propTypes = {
  user: PropTypes.object
};

export default UserInvisibleEstablishments;
