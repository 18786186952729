import { TableWrapper } from '@increase/typed-components';
import styled from 'styled-components';

import { color } from '../../../../utils/theme';

const Card = styled(TableWrapper)`
  position: relative
  padding: 0.75rem ${({ noGutter }) => (noGutter ? '0' : '1.25rem')};

  .loading-indicator {
    border-radius: 9px;
  }

  .card-header {
    padding: 0;
    border-bottom: 1px solid ${color('brandWhite', 'light')};
  }
`;

export default Card;
