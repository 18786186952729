import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './shops/List';
import PreEditor from './shops/PreEditor';

const AdminShop = () => (
  <Switch>
    <Route component={List} exact path="/admin/shops" />
    <Route exact path="/admin/shops/new" render={() => <PreEditor />} />
  </Switch>
);

export default AdminShop;
