import React from 'react';
import PropTypes from 'prop-types';
import GenericInputDate from '../common/GenericSearchBar/GenericInputDate';

class DateInput extends React.Component {
  handleChange = (date) => {
    this.props.handleInputChange({ [this.props.dateKey]: date });
  };

  render() {
    return (
      // Wrapper needed to contain the expanded state and not generate extra items in a grid
      <div>
        <GenericInputDate
          className="casasa"
          handleInputChange={this.handleChange}
          inputName={'dateSearch'}
          placeHolder={this.props.placeHolder}
        />
      </div>
    );
  }
}

DateInput.propTypes = {
  dateKey: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string
};

DateInput.defaultProps = {
  placeHolder: 'Fecha de pago YYYY-MM-DD',
  dateKey: 'date'
};

export default DateInput;
