import React from 'react';
import Constants from '../../data/Constants';
import PropTypes from 'prop-types';
import GenericInputSelect from '../common/GenericSearchBar/GenericInputSelect';

class CredentialStatusInput extends React.Component {
  handleChange = (credentialStatus) => {
    this.props.handleInputChange({ credentialStatus: credentialStatus });
  };

  render() {
    return (
      <GenericInputSelect
        handleInputChange={this.handleChange}
        selectName={'credentialStatusSearch'}
        selectOptions={Constants.credentialStatuses}
        selectPlaceholder={'Estado'}
      />
    );
  }
}

CredentialStatusInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default CredentialStatusInput;
