import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import EstablishmentNumberInput from '../../../components/inputs/EstablishmentNumberInput';
import { CardHeader, CardBody, CardFooter } from 'shards-react';
import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import Constants from '../../../data/Constants';
import EstablishmentService from '../../../services/EstablishmentService';
import TableWithPagination from '../../../components/common/Table/TableWithPagination';

class ShowEstablishments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      establishments: [],
      filter: {
        credentialId: this.props.credentialId
      },
      offset: 0,
      loading: true
    };
    this.inputs = [EstablishmentNumberInput];

    this.headers = [
      { value: 'number', label: 'Numero', hasLink: true, redirect: '/admin/establishments/$' },
      { value: 'edit', label: 'Editar', hasLink: true, redirect: '/admin/establishments/$/edit' }
    ];
  }

  componentDidMount = () => {
    this.queryCredentialEstablishments();
  };

  onSubmit = (filter) => {
    this.setState({ filter: { credentialId: this.props.credentialId, ...filter } }, () => {
      this.queryCredentialEstablishments();
    });
  };

  handlePageClick = async (direction) => {
    const newOffset = this.state.offset + direction * Constants.elementsPerPage;
    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.queryCredentialEstablishments());
    }
  };

  queryCredentialEstablishments = async () => {
    const establishmentService = await new EstablishmentService();
    establishmentService
      .list(this.state.filter, Constants.elementsPerPage, this.state.offset)
      .then((data) => {
        this.setState({
          establishments: data,
          offset: !data.length ? 0 : this.state.offset
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Fragment>
        <CardHeader className="border-bottom">
          <h6 className="m-0">Establecimientos</h6>
        </CardHeader>
        <CardBody>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} inputWidth="100" />
          <TableWithPagination
            data={this.state.establishments}
            handlePageClick={this.handlePageClick}
            headers={this.headers}
          />
        </CardBody>
        <CardFooter />
      </Fragment>
    );
  }
}

ShowEstablishments.propTypes = {
  credentialId: PropTypes.string
};

export default ShowEstablishments;
