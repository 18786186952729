import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const MonthlyComparatorService = async () => {
  const gqlClient = GraphqlClient();

  const querySummaries = gql`
    query querySummaries($filter: api_MonthlySummaryFilter, $limit: Int, $offset: Int) {
      monthlySummaries: api_monthlySummaries(filter: $filter, limit: $limit, offset: $offset) {
        cardBrand
        establishmentId
        establishmentNumber
        infoProvider
        monthlyPaymentId
        monthlyTotalAmount
        monthlyTotalDeduction
        monthlyTotalEarn
        summary {
          matchesTotalAmount
          matchesTotalDeduction
          matchesTotalEarn
        }
      }
    }
  `;

  const queryDetails = gql`
    query queryDetails($filter: api_MonthlyDetailsFilter) {
      monthlyDetails: api_monthlyDetails(filter: $filter) {
        cardBrand
        establishmentId
        establishmentNumber
        infoProvider
        monthlyPaymentId
        isBalanced
        month
        year
        monthlyTotalAmount
        monthlyTotalDeduction
        monthlyTotalEarn
        downloadLink
        details {
          date
          allMatch
          totalAmount {
            match
            payments
            monthlyDailies
          }
          totalDeduction {
            match
            payments
            monthlyDailies
          }
          totalEarn {
            match
            payments
            monthlyDailies
          }
        }
      }
    }
  `;

  return {
    getSummaries: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: querySummaries,
          variables: { filter, limit, offset }
        })
        .then((result) => {
          return result.data.monthlySummaries;
        })
        .catch(() => null); // TODO: do something
    },
    getDetails: (filter) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: queryDetails,
          variables: { filter }
        })
        .then((result) => {
          return result.data.monthlyDetails;
        })
        .catch(() => null); // TODO: do something
    }
  };
};

export default MonthlyComparatorService;
