import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Button } from 'shards-react';
import { Link } from 'react-router-dom';

import ShopsTable from './ShopsTable';
import Translator from '../services/Translator';

import styled from 'styled-components';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  shops: PropTypes.array,
  taxId: PropTypes.string
};

const defaultProps = {};

const formatProducts = (platformInfo) => {
  if (!platformInfo || !platformInfo.products) {
    return ' - ';
  }
  return platformInfo.products.map((product) => Translator.productName(product.name)).join(' - ');
};

export default function AccountDetails({
  id,
  name,
  taxId,
  shops,
  validated,
  autogenerated,
  onboardingFinished,
  authorizationLetterLink,
  activated,
  validateAccount,
  platformInfo
}) {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Información</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="3" sm="6" xs="12">
                  ID
                </Col>
                <Col lg="9" sm="6" xs="12">
                  {id}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="3" sm="6" xs="12">
                  Nombre
                </Col>
                <Col lg="9" sm="6" xs="12">
                  {name}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="3" sm="6" xs="12">
                  CUIT
                </Col>
                <Col lg="9" sm="6" xs="12">
                  {taxId}
                </Col>
              </Row>
              <hr />
              <Row className="FlexAlignCenter">
                <Col lg="3" sm="6" xs="12">
                  Validada
                </Col>
                <Col lg="9" sm="6" xs="12">
                  {validated ? (
                    'SI'
                  ) : (
                    <CustomRow style={{ width: '100%' }}>
                      <Col lg="2">NO</Col>
                      <Col lg="5">
                        <Button disabled={autogenerated} onClick={validateAccount} theme="success">
                          Validar cuenta
                        </Button>
                      </Col>
                      <Col lg="5">
                        {autogenerated ? (
                          <small>Solo se pueden validar cuentas no autogeneradas</small>
                        ) : null}
                      </Col>
                    </CustomRow>
                  )}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="3" sm="6" xs="12">
                  Autogenerada
                </Col>
                <Col className="Capitalize FlexAlignCenter" lg="9" sm="6" xs="12">
                  {autogenerated ? 'SI' : 'NO'}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="3" sm="6" xs="12">
                  Activada
                </Col>
                <Col className="Capitalize FlexAlignCenter" lg="9" sm="6" xs="12">
                  {activated ? 'SI' : 'NO'}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="3" sm="6" xs="12">
                  Termino onboarding
                </Col>
                <Col className="Capitalize FlexAlignCenter" lg="9" sm="6" xs="12">
                  {onboardingFinished ? 'SI' : 'NO'}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg="3" sm="6" xs="12">
                  Carta de autorización
                </Col>
                <Col className="Capitalize FlexAlignCenter" lg="9" sm="6" xs="12">
                  {authorizationLetterLink ? <a href={authorizationLetterLink}>Descargar</a> : '-'}
                </Col>
              </Row>
              <hr />
            </CardBody>
            <CardFooter />
          </Card>
        </Col>
        <Col>
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Información</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="3" sm="6" xs="12">
                  Productos contratados
                </Col>
                <Col lg="9" sm="6" xs="12">
                  {formatProducts(platformInfo)}
                </Col>
              </Row>
              <hr />
            </CardBody>
            <CardFooter />
          </Card>
        </Col>
      </Row>
      <Card className="mb-4" small>
        <CardHeader className="border-bottom">
          <Row>
            <Col lg="10" sm="6" xs="12">
              <h6 className="m-0">Shops</h6>
            </Col>
            <Col lg="2" sm="6" xs="12">
              <Link to={`${id}/shops/new`}>
                <Button className="btn-primary float-right">Nuevo shop</Button>
              </Link>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <ShopsTable accountId={id} shops={shops} />
          </Row>
        </CardBody>
        <CardFooter />
      </Card>
    </React.Fragment>
  );
}

const CustomRow = styled(Row)`
  width: 100%;
  align-items: center;
`;

AccountDetails.propTypes = propTypes;
AccountDetails.defaultProps = defaultProps;
