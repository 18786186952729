import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'shards-react';
import { Link } from 'react-router-dom';
import { Label, CellText } from '@increase/typed-components';
import styled from 'styled-components';
import { ScraperService } from '../../../services/ScraperService';
import { useUserId, useRealUser } from '../../../contexts/AuthContext';
import { useAccountForScrap, useCredentialHasDocuments } from '../../../hooks/manualScraper';
import Loading from './Loading';
import Container from './common/Container';
import PageTitle from './common/PageTitle';
import CredentialChooser from './providerView/CredentialChooser';
import CredentialManagement from './providerView/CredentialManagement';
import LockManagementAction from './providerView/LockManagementAction';
import SaveDropdown from './providerView/SaveDropdown';
import DocumentList from './providerView/DocumentList';
import CredentialStatus from './CredentialStatus';
import { dateToDateTime } from '../../../utils/manualScraper';
import { color } from '../../../utils/theme';

const CancelButton = styled(CellText)`
  color: ${color('alerta', 'regular')};
  display: inline-block;
  cursor: pointer;
`;

const ProviderView = ({ match, location }) => {
  const { accountId, infoProvider } = match.params;

  // Load account (to display name)
  const account = useAccountForScrap(accountId);
  const [credentials, setCredentials] = useState([]);
  const [loadingCredentials, setLoadingCredentials] = useState(false);
  const [credentialMgmt, setCredentialMgmt] = useState();
  const [saving, setSaving] = useState(false);

  const userId = useUserId();
  const realUser = useRealUser();
  const realUserId = realUser ? realUser.id : userId;

  const date = useMemo(() => {
    return dateToDateTime(match.params.date);
  }, [match.params.date]);

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const progress = useMemo(() => {
    return ScraperService().providerInfo(credentials);
  }, [credentials]);

  // Load Day Managment Summary (to display credentials)
  useEffect(() => {
    async function getAccountDaySummary() {
      const details = await ScraperService().getAccountDaySummary(accountId, date, [infoProvider]);

      setLoadingCredentials(false);
      if (details && details[0]) {
        setCredentials(details[0].credentialManagements);
      }
    }
    if (accountId && infoProvider && date) {
      setLoadingCredentials(true);
      getAccountDaySummary();
    }
  }, [accountId, date, infoProvider]);

  const credentialId = credentialMgmt && credentialMgmt.credential.id;
  const country = credentialMgmt && credentialMgmt.credential.country;
  const lockedById =
    credentialMgmt &&
    ((credentialMgmt.lockedBy && credentialMgmt.lockedBy.id) || credentialMgmt.lockedById);

  // AutoSelect credential: query string param or first credential from the list
  const defaultCredentialId = credentialId || query.get('credential');
  useEffect(() => {
    if (credentials) {
      setCredentialMgmt(
        credentials.find((c) => c.credential.id === defaultCredentialId) || credentials[0]
      );
    }
  }, [credentials, defaultCredentialId]);

  // Lookup if credential has documents for the current date. This input is needed to see if user can use cancel action
  const [credentialHasDocuments, setCredentialHasDocuments] = useCredentialHasDocuments(
    credentialId,
    { date }
  );

  const replaceCredential = (management, matcher = (info) => info.id === management.id) => {
    if (!management) {
      return;
    }

    const newCredentials = credentials.map((info) => {
      if (matcher(info)) {
        return {
          ...info,
          ...management,
          managementStatus: management.status,
          status: info.status,
          credential: info.credential
        };
      }

      return info;
    });

    // Credential will be auto-selected but in two renders (one to update list, the second to update credentials).
    // Do it in the same render to prevent flicks
    setCredentialMgmt(newCredentials.find(matcher));
    setCredentials(newCredentials);
  };

  const cancelManagement = async () => {
    setSaving(true);
    const management = await ScraperService().cancelManagement(credentialMgmt.id, realUserId);
    setSaving(false);
    replaceCredential(management);
  };

  const lockManagement = async () => {
    setSaving(true);
    const management = await ScraperService().lockManagement(credentialId, date, realUserId);
    setSaving(false);
    const matcher = (info) => info.credential.id === management.credential.id;
    replaceCredential(management, matcher);
  };

  const unlockManagement = async () => {
    setSaving(true);
    const management = await ScraperService().unlockManagement(credentialMgmt.id, realUserId);
    setSaving(false);
    replaceCredential(management);
  };

  const finishManagement = async (exception) => {
    setSaving(true);
    const management = await ScraperService().finishManagement(
      credentialMgmt.id,
      realUserId,
      exception
    );
    setSaving(false);
    replaceCredential(management);
  };

  const canEdit = lockedById === realUserId && credentialMgmt.managementStatus === 'in_progress';
  const isFutureDate = new Date() - date < 0;
  const canFinishWithException = canEdit && !isFutureDate;

  return (
    <Container>
      <Row>
        <Col className="mt-3">
          <Link to={`/support/manual_scraper/${accountId}`}>
            <i className="material-icons">chevron_left</i>
            &nbsp;Volver al calendario
          </Link>
        </Col>
      </Row>
      {account && (
        <Row>
          <PageTitle className="pt-3 pb-0" title={account.name} />
        </Row>
      )}

      {credentials.length === 0 && !loadingCredentials && (
        <CellText>No hay credenciales para gestionar</CellText>
      )}

      {credentials.length > 0 && (
        <Row>
          <Col md="6">
            <CredentialChooser
              credentials={credentials}
              infoProvider={infoProvider}
              onSelect={setCredentialMgmt}
              value={credentialMgmt}
            />
          </Col>
          <Col md="6">
            <CredentialManagement
              className="h-100 d-flex flex-column justify-content-between"
              date={date}
              progress={progress}
            >
              {saving && <Loading backdrop={true} />}
              {credentialMgmt && (
                <div className="mb-3 d-flex align-items-center">
                  <div>
                    <Label className="d-block mb-0">Estado de Gestión</Label>
                    <CredentialStatus credential={credentialMgmt} />
                    {canEdit && !credentialHasDocuments && (
                      <CancelButton className="mx-3" onClick={cancelManagement}>
                        (Cancelar Gestión)
                      </CancelButton>
                    )}
                  </div>
                  <div className="ml-auto">
                    {lockedById !== realUserId && (
                      <LockManagementAction
                        credential={credentialMgmt}
                        disabled={lockedById && lockedById !== realUserId}
                        onClick={lockManagement}
                      />
                    )}
                    {canEdit && (
                      <SaveDropdown
                        onFinish={() => finishManagement(false)}
                        onFinishWithException={
                          canFinishWithException && (() => finishManagement(true))
                        }
                        onUnlock={unlockManagement}
                      />
                    )}
                  </div>
                </div>
              )}
            </CredentialManagement>
          </Col>
        </Row>
      )}

      {credentialMgmt && (
        <DocumentList
          canEdit={canEdit}
          country={country}
          credentialId={credentialId}
          date={date}
          infoProvider={infoProvider}
          managementId={credentialMgmt.id}
          onFileUploaded={() => {
            // Hide cancel management action
            setCredentialHasDocuments(true);
          }}
        />
      )}
    </Container>
  );
};

export default ProviderView;
