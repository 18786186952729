import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './payments/List';
import Show from './payments/Show';

const AdminPayments = () => (
  <Switch>
    <Route component={List} exact path="/admin/payments" />
    <Route
      exact
      path="/admin/payments/:id"
      render={(props) => <Show paymentId={props.match.params.id} />}
    />
  </Switch>
);

export default AdminPayments;
