import Debounce from 'es6-promise-debounce';

import AccountService from '../services/AccountService';
import Constants from '../data/Constants';

export const debouncedFetchAccounts = Debounce(async (accountName) => {
  const accountService = await AccountService();

  return accountService.list({ accountNameOrId: accountName }).then((data) => {
    const transformedData = (data || [])
      .filter((account) => account.id !== Constants.defaultAccountId)
      .map((account) => {
        return { value: account.id, label: account.name };
      });
    return transformedData;
  });
}, 500);

export const debouncedFetchGroupedAccounts = Debounce(async (accountName) => {
  const accountService = await AccountService();

  return accountService.groupedList({ accountNameOrId: accountName }).then((data) => {
    const transformedData = (data || [])
      .filter((account) => account.id !== Constants.defaultAccountId)
      .reduce((groupedAccounts, account) => {
        const label = account.supportTag || 'Otras Cuentas';
        groupedAccounts[label] = groupedAccounts[label] || [];
        groupedAccounts[label].push({
          value: account.id,
          label: account.name,
          infoProviders: account.infoProviders || []
        });

        return groupedAccounts;
      }, {});

    const groupedAccounts = [];
    for (const label in transformedData) {
      if (transformedData.hasOwnProperty(label)) {
        groupedAccounts.push({
          label: label,
          options: transformedData[label]
        });
      }
    }

    return groupedAccounts;
  });
}, 500);
