import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'shards-react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { H3 } from '@increase/typed-components';

import { ScraperService } from '../../../../services/ScraperService';
import ProviderDay from './ProviderDay';
import CredentialsList from './CredentialsList';
import NoResult from '../../../../components/common/NoResult';
import Loading from '../Loading';
import Card from '../common/Card';
import { formatDateTime, isToday } from '../../../../utils/manualScraper';

const DayDetailsCard = styled(Card)`
  position: relative;
  min-height: 200px;

  .card-row {
    display: flex;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

const DayDetails = ({ account, date, infoProvider, history, location }) => {
  const [activeInfoProvider, setActiveInfoProvider] = useState(null);
  const [accountDetails, setAccountDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeCredential, setActiveCredential] = useState();

  const scrapableProviders = useMemo(() => {
    const providers = infoProvider ? [infoProvider] : account.infoProviders;

    return ScraperService().rejectNonScrapableProviders(providers);
  }, [account, infoProvider]);

  useEffect(() => {
    async function getAccountDaySummary() {
      const details = await ScraperService().getAccountDaySummary(
        account.id,
        date,
        scrapableProviders
      );

      setAccountDetails(details);
      setLoading(false);
    }
    if (account && date) {
      setLoading(true);
      setAccountDetails([]);
      getAccountDaySummary();
    }
  }, [account, date, scrapableProviders]);

  useEffect(() => {
    if (scrapableProviders.length === 1) {
      setActiveInfoProvider(scrapableProviders[0]);
    } else {
      setActiveInfoProvider(null);
    }
  }, [scrapableProviders]);

  const credentials = useMemo(() => {
    if (!activeInfoProvider || !accountDetails) {
      return null;
    }

    const info = accountDetails.find((detail) => detail.infoProvider === activeInfoProvider);

    return info && info.credentialManagements;
  }, [activeInfoProvider, accountDetails]);

  if (!date) {
    return null;
  }

  const navigateToInfoProvider = () => {
    let url = `${location.pathname}/${activeInfoProvider}/${formatDateTime(date, 'iso')}`;
    if (activeCredential) {
      url += `?credential=${activeCredential.id}`;
    }

    history.push(url);
  };

  return (
    <DayDetailsCard noGutter={true}>
      {loading && <Loading />}

      <H3 className="pb-2 mb-3 card-row card-header">
        {isToday(date) ? 'Fecha de hoy' : formatDateTime(date, 'medium')}
      </H3>

      <div className="card-row">
        {accountDetails.map(({ infoProvider, credentialManagements }) => (
          <ProviderDay
            className="mr-3 mb-3"
            infoProvider={infoProvider}
            key={infoProvider}
            managements={credentialManagements}
            onClick={setActiveInfoProvider}
            selected={activeInfoProvider === infoProvider}
          />
        ))}
      </div>

      {!loading && accountDetails.length === 0 && <NoResult />}

      {credentials && <CredentialsList credentials={credentials} onSelect={setActiveCredential} />}

      {accountDetails.length > 0 && activeInfoProvider && (
        <div className="text-right mr-3 card-row">
          <Button onClick={navigateToInfoProvider}>Ver</Button>
        </div>
      )}
    </DayDetailsCard>
  );
};

export default withRouter(DayDetails);
