import React from 'react';
import { Navbar, NavbarBrand } from 'shards-react';

import logo from '../../../assets/isologo.svg';

const SidebarMainNavbar = () => {
  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand className="w-100 mr-0" href="#" style={{ lineHeight: '25px' }}>
          <div className="d-table m-auto">
            <img
              alt="Admin"
              className="d-inline-block align-top mr-1"
              id="main-logo"
              src={logo}
              style={{ maxWidth: '25px' }}
            />
            <span className="d-none d-md-inline ml-1">Admin</span>
          </div>
        </NavbarBrand>
      </Navbar>
    </div>
  );
};

export default SidebarMainNavbar;
