import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NoResult from '../../../../components/common/NoResult';
import Paginate from '../../../../components/Paginate';
import Translator from '../../../../services/Translator';

const Table = ({ monthlySummaries, onPageClick }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="w-10" scope="col">
            Establecimiento
          </th>
          <th className="w-10" scope="col">
            Tarjeta
          </th>
          <th className="w-10" scope="col">
            Proveedor
          </th>
          <th className="w-20" scope="col">
            Presentado
          </th>
          <th className="w-20" scope="col">
            Descontado
          </th>
          <th className="w-20" scope="col">
            Acreditado
          </th>
          <th className="w-5" scope="col">
            #
          </th>
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {monthlySummaries.map((model) => {
          return (
            <tr key={model.monthlyPaymentId}>
              <td>{model.establishmentNumber}</td>
              <td>{Translator.cardBrand(model.cardBrand)}</td>
              <td>{Translator.infoProvider(model.infoProvider)}</td>
              <td style={tdStyle(model.summary.matchesTotalAmount)}>{model.monthlyTotalAmount}</td>
              <td style={tdStyle(model.summary.matchesTotalDeduction)}>
                {model.monthlyTotalDeduction}
              </td>
              <td style={tdStyle(model.summary.matchesTotalEarn)}>{model.monthlyTotalEarn}</td>
              <td>
                <Link to={`/support/monthly_detailed/${model.monthlyPaymentId}`}>Ver</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {monthlySummaries.length < 1 ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

const tdStyle = (match) => ({ fontSize: 15, color: match ? 'green' : 'tomato' });

Table.propTypes = {
  monthlySummaries: PropTypes.array.isRequired
};

export default Table;
