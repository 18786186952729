import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const MonthlyComparisonsService = async () => {
  const gqlClient = GraphqlClient();

  const queryComparisons = gql`
    query queryComparisons($filter: api_MonthlyComparisonFilter, $limit: Int, $offset: Int) {
      comparisons: api_monthlyComparisonDetails(filter: $filter, limit: $limit, offset: $offset) {
        monthlyPaymentId
        cardBrandId
        establishmentId
        establishmentNumber
        infoProvider
        month
        year
        initialComparison {
          comparisonResult
          dailyPaymentsSummary
          stage
        }
        lastComparison {
          comparisonResult
          dailyPaymentsSummary
          stage
        }
      }
    }
  `;

  return {
    getComparisons: (filter, limit = 20, offset = 0) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: queryComparisons,
          variables: { filter, limit, offset }
        })
        .then((result) => {
          return result.data.comparisons;
        })
        .catch(() => null);
    }
  };
};

export default MonthlyComparisonsService;
