import React, { useContext } from 'react';
import Component from 'react-select';
import { ThemeContext } from 'styled-components';

import { color } from '../../../../utils/theme';

export const styles = (theme) => {
  const colorProps = { theme };
  const whiteBold = color('brandWhite', 'bold')(colorProps);
  const whiteLight = color('brandWhite', 'light')(colorProps);
  const blackRegular = color('brandBlack', 'regular')(colorProps);

  return {
    placeholder: (base) => ({ ...base, color: whiteBold }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
    option: (base, { isFocused }) => ({
      ...base,
      color: blackRegular,
      backgroundColor: isFocused ? whiteLight : null,
      ':active': { ...base[':active'], backgroundColor: whiteLight }
    })
  };
};

const Select = (props) => {
  const theme = useContext(ThemeContext);

  return <Component {...props} styles={styles(theme)} />;
};

export default Select;
