import React, { useState, useEffect } from 'react';
import { Row, Col } from 'shards-react';
import { Table, THead, Th, Td, InputLabel } from '@increase/typed-components';

import { debouncedFetchGroupedAccounts } from '../../../utils/accounts';
import { debouncedFetchCredentials } from '../../../utils/credentials';
import { EmptyTableMessage } from './EmptyTableMessage';
import { ScraperService } from '../../../services/ScraperService';
import Paginate from './common/Paginate';
import constants from '../../../data/Constants';
import Container from './common/Container';
import PageTitle from './common/PageTitle';
import SelectableTableRow from './common/SelectableTableRow';
import AsyncSelect from './common/AsyncSelect';
import AccountStatus from './accountsView/AccountStatus';
import { formatDateTime } from '../../../utils/manualScraper';
import { LoadingTableRow } from './LoadingTableRow';

const elementsPerPage = constants.elementsPerPage;

const AccountsView = ({ history }) => {
  const [accountId, setAccountId] = useState();
  const [credentialId, setCredentialId] = useState();
  const [accounts, setAccounts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  const handlePageClick = (direction) => {
    const newOffset = offset + direction * elementsPerPage;

    if (newOffset >= 0) {
      setOffset(newOffset);
    }
  };

  useEffect(() => {
    async function getAccounts() {
      const filter = { accountId, credentialId };
      const summary = await ScraperService().getAccountsSummary(filter, offset, elementsPerPage);

      setLoading(false);
      setAccounts(summary);
    }

    setLoading(true);
    getAccounts();
  }, [accountId, credentialId, offset]);

  return (
    <Container>
      <Row>
        <PageTitle className="pt-4 pb-3" title="Scraper Manual" />
      </Row>

      <Row>
        <Col md="6">
          <InputLabel>Nombre de cuenta</InputLabel>
          <AsyncSelect
            cacheOptions={true}
            defaultOptions
            id="accountId"
            isClearable
            loadOptions={debouncedFetchGroupedAccounts}
            onBlur={() => {}}
            onChange={(option) => {
              setAccountId(option && option.value);
              setOffset(0);
            }}
            placeholder="Buscar cuenta"
          />
        </Col>
        <Col md="6">
          <InputLabel>Nombre de usuario de credencial</InputLabel>
          <AsyncSelect
            cacheOptions={true}
            defaultOptions
            id="credentialId"
            isClearable
            loadOptions={debouncedFetchCredentials}
            onBlur={() => {}}
            onChange={(option) => {
              setCredentialId(option && option.value);
              setOffset(0);
            }}
            placeholder="Busca nombre"
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Table>
            <THead>
              <tr>
                <Th>Cuenta</Th>
                <Th>Estado</Th>
                <Th>Último Doc. Subido</Th>
              </tr>
            </THead>
            <tbody>
              <LoadingTableRow loading={loading} />

              {!loading &&
                accounts.map((info) => (
                  <SelectableTableRow
                    key={info.accountId}
                    onClick={() => history.push(`/support/manual_scraper/${info.accountId}`)}
                  >
                    <Td>{(info.account && info.account.name) || info.accountId}</Td>
                    <Td>
                      <AccountStatus account={info} />
                    </Td>
                    <Td>{formatDateTime(info.lastUpload)}</Td>
                  </SelectableTableRow>
                ))}

              <EmptyTableMessage collection={accounts} ready={!loading} />
            </tbody>
          </Table>
          <Paginate className="mt-3" onPageChange={handlePageClick} />
        </Col>
      </Row>
    </Container>
  );
};

export default AccountsView;
