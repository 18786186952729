import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const ShopService = async () => {
  const gqlClient = GraphqlClient();

  const getQuery = gql`
    query Shop($id: String!) {
      shop: api_shop(id: $id) {
        id
        name
        account {
          id
          establishments {
            id
            number
          }
        }
        establishments {
          id
          number
        }
      }
    }
  `;

  const listQuery = gql`
    query Shops($filter: api_ShopFilter!) {
      shops: api_shops(filter: $filter) {
        id
        name
        account {
          id
          name
        }
      }
    }
  `;

  const updateMutation = gql`
    mutation Update($id: String!, $params: api_ShopUpdateAttributes!) {
      operation: api_shopUpdate(id: $id, params: $params) {
        shop {
          id
          name
          account {
            id
          }
        }
        errors
      }
    }
  `;

  const createMutation = gql`
    mutation Create($params: api_ShopCreateAttributes!) {
      operation: api_shopCreate(params: $params) {
        shop {
          id
          name
          account {
            id
          }
        }
        errors
      }
    }
  `;
  return {
    get: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getQuery,
          variables: { id }
        })
        .then((data) => data.data.shop)
        .catch(() => null);
    },
    list: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: listQuery,
          variables: {
            filter,
            limit,
            offset
          }
        })
        .then((data) => data.data.shops)
        .catch(() => null);
    },
    update: (params, id) => {
      return gqlClient
        .mutate({
          mutation: updateMutation,
          variables: { id: id, params: params }
        })
        .then((data) => data.data.operation)
        .catch(() => null);
    },
    create: (params) => {
      return gqlClient
        .mutate({
          mutation: createMutation,
          variables: { params }
        })
        .then((data) => data.data.operation)
        .catch(() => null);
    }
  };
};

export default ShopService;
