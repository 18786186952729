import React from 'react';
import { Form } from 'shards-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SubmitButton from './SubmitButton';

class GenericSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleInputChange = (object) => {
    this.setState(object, () => {
      const { handleChange } = this.props;
      if (handleChange) {
        handleChange(this.state);
      }
    });
  };

  handleSubmit = () => {
    this.props.handleSubmit(this.state);
  };

  render() {
    return (
      <Form
        onSubmit={(event) => {
          this.handleSubmit(event);
          event.preventDefault();
        }}
      >
        <Container>
          <InputGrid inputNumber={this.props.inputs.length}>
            {this.props.inputs.map((Input, i) => {
              return <Input handleInputChange={this.handleInputChange} key={i} />;
            })}
          </InputGrid>
          <SubmitButton>{this.props.displayText}</SubmitButton>
        </Container>
      </Form>
    );
  }
}

const Container = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 100px;
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ inputNumber }) => (inputNumber === 4 ? 2 : Math.min(3, inputNumber))},
    1fr
  );
  gap: 10px;
  align-items: center;
`;

GenericSearchBar.propTypes = {
  displayText: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  inputs: PropTypes.array.isRequired
};

GenericSearchBar.defaultProps = {};

export default GenericSearchBar;
