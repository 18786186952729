import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Constants from '../data/Constants';
import { getCurrentCountry } from '../utils/admin';

const cache = new InMemoryCache();

const tenant = getCurrentCountry() !== null ? getCurrentCountry() : Constants.defaultTenant;

const GraphqlClient = () => {
  return new ApolloClient({
    cache,
    headers: {
      tenant: tenant
    },
    credentials: 'include',
    uri: `${process.env.REACT_APP_GRAPHQL_URI}/graphql`
  });
};

export default GraphqlClient;
