import React from 'react';
import PropTypes from 'prop-types';
import NoResult from '../../../components/common/NoResult';
import Translator from '../../../services/Translator';
import SchedulerService from '../../../services/SchedulerService';
import HistoryTable from './HistoryTable';
import { Container, Col, Row } from 'shards-react';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      historyJobs: []
    };
  }

  getJobHistory = async (jobId) => {
    this.props.setLoading(true);

    const schedulerService = await SchedulerService();
    return schedulerService
      .getJobHistory(jobId)
      .then((data) => {
        this.setState({ historyJobs: data });
      })
      .finally(() => this.props.setLoading(false));
  };

  render() {
    return (
      <Container>
        <Row>
          <Col lg="9">
            <React.Fragment>
              <table className="table table-hover text-center table-fixed MarginTop20">
                <thead className="thead-dark">
                  <tr>
                    <th className="w-10" scope="col">
                      Fecha
                    </th>
                    <th className="w-10" scope="col">
                      Establecimiento
                    </th>
                    <th className="w-10" scope="col">
                      Documento
                    </th>
                    <th className="w-10" scope="col">
                      Estado
                    </th>
                    <th className="w-10" scope="col">
                      Origen
                    </th>
                    <th className="w-10" scope="col">
                      Ultimo scrapeo
                    </th>
                    <th className="w-10" scope="col">
                      Job ID
                    </th>
                  </tr>
                </thead>
                <tbody className="BackgroundWhite">
                  {this.props.jobs.map((model, i) => {
                    return (
                      <tr key={i}>
                        <td>{model.searching_date}</td>
                        <td>{model.establishment_number}</td>
                        <td>{Translator.documentType(model.document_type)}</td>
                        <td>{Translator.jobStatus(model.status)}</td>
                        <td>{model.origin_detail}</td>
                        <td>
                          {model.last_runned_at
                            ? new Date(model.last_runned_at).toLocaleString('es-AR', {
                                timeZone: 'America/Argentina/Buenos_Aires'
                              })
                            : '-'}
                        </td>
                        <td>
                          <button
                            className="btn btn-link"
                            onClick={() => this.getJobHistory(model.job_id)}
                            type="button"
                          >
                            {model.job_id}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {this.props.jobs.length < 1 ? <NoResult /> : null}
            </React.Fragment>
          </Col>
          <Col lg="3">
            <HistoryTable historyJobs={this.state.historyJobs || []} />
          </Col>
        </Row>
      </Container>
    );
  }
}

Table.propTypes = {
  jobs: PropTypes.array.isRequired
};

export default Table;
