import React from 'react';
import NoResult from '../../../components/common/NoResult';
import Translator from '../../../services/Translator';

const HistoryTable = ({ historyJobs }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="w-30" scope="col">
            Antes
          </th>
          <th className="w-30" scope="col">
            Despues
          </th>
          <th className="w-30" scope="col">
            Horario
          </th>
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {historyJobs.map((model, i) => {
          return (
            <tr key={i}>
              <td>{Translator.jobStatus(model.from_status)}</td>
              <td>{Translator.jobStatus(model.to_status)}</td>
              <td>
                {new Date(model.updated_at).toLocaleString('es-AR', {
                  timeZone: 'America/Argentina/Buenos_Aires'
                })}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {historyJobs.length < 1 ? <NoResult /> : null}
  </React.Fragment>
);

export default HistoryTable;
