import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './credentials/List';
import Show from './credentials/Show';
import Editor from './credentials/Editor';

const AdminCredential = () => (
  <Switch>
    <Route component={List} exact path="/admin/credentials" />
    <Route exact path="/admin/credentials/new" render={() => <Editor isNew={true} />} />
    <Route
      exact
      path="/admin/credentials/:id/edit"
      render={(props) => <Editor credentialId={props.match.params.id} isNew={false} />}
    />
    <Route
      exact
      path="/admin/credentials/:id"
      render={(props) => <Show credentialId={props.match.params.id} />}
    />
  </Switch>
);

export default AdminCredential;
