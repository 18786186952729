// Permission format
// NAMESPACE-ACTION-[PERMISSION]

// -- NAMESPACE --
// cred -> credential
// shop -> shops
// acc -> accounts
// est -> establishments
// sup -> support

// -- ACTION --
// list | create | edit

// -- PERMISSION --
// string

export const protectedActions = {
  acc: {
    migrate: {
      'super-internal-admin': true
    }
  },
  cred: {
    edit: {
      acc: {
        'super-internal-admin': true
      }
    }
  },
  est: {
    edit: {
      acc: {
        'super-internal-admin': true
      },
      cred: {
        'super-internal-admin': true
      }
    }
  },
  sup: {
    reparse: {
      'super-internal-admin': true
    },
    refocus: {
      'super-internal-admin': true
    }
  }
};
