import React, { useMemo } from 'react';
import Translator from '../../../services/Translator';
import { Label, InlineText } from '@increase/typed-components';
import styled from 'styled-components';

import Link from './common/Link';
import Password from './Password';
import { color } from '../../../utils/theme';

const DataContainer = styled.div`
  label {
    margin-bottom: 0;
  }

  .username {
    font-size: 0.75rem;
    color: ${color('brandBlack', 'light')};
  }
`;

const CredentialData = ({ username, password, otherLogin, status, loginPage, className }) => {
  const statusText = useMemo(() => {
    return status && Translator.credentialStatus(status);
  }, [status]);

  return (
    <DataContainer className={className}>
      {username && (
        <div>
          <Label weight="bold">Credencial</Label>
          <InlineText className="ml-2 username" inline>
            {username}
          </InlineText>
        </div>
      )}
      {otherLogin && (
        <div>
          <Label weight="bold">Otro login</Label>
          <InlineText className="ml-2 username" inline>
            {otherLogin}
          </InlineText>
        </div>
      )}
      {password && (
        <div>
          <Label weight="bold">Clave</Label>
          <Password className="ml-2" password={password} />
        </div>
      )}
      <Label weight="bold">{statusText}</Label>
      {loginPage && (
        <div>
          <Link
            className="font-weight-bold"
            href={loginPage}
            rel="noreferrer noopener"
            target="_blank"
          >
            Abrir <i className="material-icons">open_in_new</i>
          </Link>
        </div>
      )}
    </DataContainer>
  );
};

export default CredentialData;
