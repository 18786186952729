import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const MonthlyMetricsService = async () => {
  const gqlClient = GraphqlClient();

  const queryMetrics = gql`
    query queryMetrics($filter: api_MonthlyMetricFilter) {
      metrics: api_monthlyMetrics(filter: $filter) {
        id
        cardBrandId
        infoProvider
        records
        totalAmountHitRatio
        totalDeductionHitRatio
        totalEarnHitRatio
        updatedAt
      }
    }
  `;

  return {
    getMetrics: (filter) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: queryMetrics,
          variables: { filter }
        })
        .then((result) => {
          return result.data.metrics;
        })
        .catch(() => null);
    }
  };
};

export default MonthlyMetricsService;
