import React, { useState, useEffect, useContext } from 'react';

const CARD_ADMIN_URL = process.env.REACT_APP_GRAPHQL_URI;

export const AuthContext = React.createContext({
  authenticated: null,
  userId: null,
  userEmail: null,
  realUser: null,
  userCountry: null
});

export function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [realUser, setRealUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userCountry, setUserCountry] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    fetch(`${CARD_ADMIN_URL}/me`, { credentials: 'include' })
      .then((response) => {
        if (response.status !== 200) {
          setAuthenticated(false);
        }
        setAuthenticated(true);
        return response.json();
      })
      .then(({ user }) => {
        setUserEmail(user.userEmail);
        setUserId(user.id);
        setRealUser(user.realUser);
        setUserCountry(user.defaultCountry);
        setUserRole(user.role);
      })
      .catch(() => setAuthenticated(false));
  }, []);
  if (authenticated === null) return null;
  return (
    <AuthContext.Provider
      value={{
        authenticated,
        userEmail,
        realUser,
        userId,
        userCountry,
        userRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthenticated() {
  return useContext(AuthContext).authenticated;
}

export function useUserEmail() {
  return useContext(AuthContext).userEmail;
}

export function useUserCountry() {
  return useContext(AuthContext).userCountry;
}

export function useRealUser() {
  return useContext(AuthContext).realUser;
}

export function useUserId() {
  return useContext(AuthContext).userId;
}
export function useUserRole() {
  return useContext(AuthContext).userRole;
}
