import React from 'react';
import Constants from '../../data/Constants';
import PropTypes from 'prop-types';
import GenericInputSelect from '../common/GenericSearchBar/GenericInputSelect';

class InfoProviderSimpleInput extends React.Component {
  handleChange = (infoProvider) => {
    this.props.handleInputChange({ infoProvider: infoProvider });
  };

  render() {
    return (
      <GenericInputSelect
        handleInputChange={this.handleChange}
        selectName={'infoProviderSearch'}
        selectOptions={Constants.infoProvidersSimple}
        selectPlaceholder={'Proveedor'}
      />
    );
  }
}

InfoProviderSimpleInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default InfoProviderSimpleInput;
