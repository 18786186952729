import React from 'react';
import { Formik, Form } from 'formik';
import { Button, FormInput, Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react';
import AsyncSelect from 'react-select/lib/Async';

import PageTitle from '../../../components/PageTitle';
import UserService from '../../../services/UserService';
import Errors from '../../../components/Errors';
import Messages from '../../../components/Messages';
import { debouncedFetchAccounts } from '../../../utils/accounts';
import Select from 'react-select';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const roles = [
  {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'superuser',
    label: 'Manager'
  },
  {
    value: 'viewer',
    label: 'Solo lectura'
  }
];

class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.isNew
        ? {
            firstName: '',
            lastName: '',
            email: '',
            accountId: '',
            accessEnabled: '',
            account: {
              name: ''
            },
            role: {
              name: ''
            },
            appSettings: '',
            terminalsHired: '',
            shops: []
          }
        : null,
      errors: [],
      updateStatus: []
    };
  }

  componentDidMount = () => {
    if (!this.props.isNew) {
      this.findUser(this.props.match.params.id);
    }
  };

  formatSelectLabelAndValue = (array, value) => {
    if (!value) {
      return { value: '', label: '' };
    }
    return array.find((e) => {
      return e.value === value;
    });
  };

  handleSubmit = (values) => {
    if (this.props.isNew) {
      this.createUser(values);
    } else {
      this.updateUser(values);
    }
  };

  createUser = async (values) => {
    const userService = await UserService();
    userService.createUser(values).then((user) => {
      this.setState({ user: user });
    });
  };

  updateUser = async (values) => {
    if (this.state.user === values) {
      return;
    }
    const userService = await UserService();

    const [ptlResult, apiResult] = await Promise.all([
      // This updateUser method updates the User in Platform
      // userService.updateUser(values),
      userService.updateCardUser(values)
    ]);

    if (ptlResult.errors && ptlResult.errors.length) {
      this.setState((state) => {
        return { ...state, errors: [...state.errors, apiResult.errors] };
      });
      return;
    }

    if (apiResult.errors && apiResult.errors.length) {
      this.setState((state) => {
        return { ...state, errors: [...state.errors, apiResult.errors] };
      });
      return;
    }
    const updatedUser = { ...ptlResult.user, ...apiResult.data.update.user };
    this.setState((state) => {
      return { ...state, user: updatedUser, updateStatus: ['User updated!'] };
    });
  };

  findUser = async (id) => {
    const userService = await UserService();
    userService.findUser(id).then((user) => {
      this.setState({ user: user });
    });
  };

  render() {
    if (this.state.user === null) return null;
    const cardTitle = this.props.isNew ? 'Crear' : 'Editar';
    const buttonText = this.props.isNew ? 'Crear usuario' : 'Editar usuario';
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Usuarios" />
        </Row>
        <Row>
          <Col>
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">{cardTitle}</h6>
              </CardHeader>
              <CardBody>
                <Messages messages={this.state.updateStatus} />
                <Errors errors={this.state.errors} />
                <Formik enableReinitialize initialValues={this.state.user}>
                  {(props) => {
                    const { values, isSubmitting, handleChange, handleBlur, setFieldValue } = props;
                    return (
                      <Form>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="name">Nombre</label>
                            <FormInput
                              id="firstName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Nombre"
                              type="text"
                              value={values.firstName}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="name">Apellido</label>
                            <FormInput
                              id="lastName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Apellido"
                              type="text"
                              value={values.lastName || '-'}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="name">Email</label>
                            <FormInput
                              id="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Email"
                              type="text"
                              value={values.email || '-'}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="accountId">Cuenta</label>
                            <AsyncSelect
                              cacheOptions={true}
                              id="accountId"
                              isDisabled={!this.props.isNew}
                              loadOptions={debouncedFetchAccounts}
                              onBlur={handleBlur}
                              onChange={(option) => {
                                setFieldValue('account.id', option.value);
                                setFieldValue('account.name', option.label);
                              }}
                              placeholder="Busca la Account por nombre"
                              value={
                                values.account !== undefined
                                  ? { value: values.account.id, label: values.account.name }
                                  : ''
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3" sm="6" xs="12">
                            Rol/Permisos (Card)
                          </Col>
                        </Row>
                        <Row>
                          <Col className="Capitalize FlexAlignCenter mb-4" lg="4" sm="6" xs="12">
                            {this.state.user.role && (
                              <StyledSelect
                                id="roleIds"
                                onBlur={() => {}}
                                onChange={(option) => {
                                  return setFieldValue('role', option.value);
                                }}
                                options={roles}
                                placeholder="Roles"
                                value={this.formatSelectLabelAndValue(roles, values.role)}
                              />
                            )}
                          </Col>
                        </Row>
                        <Button
                          className="btn-primary"
                          disabled={isSubmitting}
                          onClick={() => {
                            this.handleSubmit(values);
                          }}
                        >
                          {buttonText}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Editor.defaultProps = {
  isNew: false
};

export default Editor;
