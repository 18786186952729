import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const AuthorizedDocumentService = async () => {
  const gqlClient = GraphqlClient();

  const getAuthDocQuery = gql`
    query AuthDoc($filter: scp_AuthorizedDocumentFilter) {
      auth_docs: scp_authorizedDocument(filter: $filter) {
        documentType
        credentialId
      }
    }
  `;

  return {
    getAuthDocs: (credentialId) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getAuthDocQuery,
          variables: {
            filter: {
              credentialId: credentialId
            }
          }
        })
        .then((data) => {
          return data.data.auth_docs;
        })
        .catch((error) => {
          throw error;
        });
    }
  };
};

export default AuthorizedDocumentService;
