import React from 'react';
import { Consumer as LoadingConsumer } from '../../contexts/LoadingContext';

const NoResult = () => (
  <LoadingConsumer>
    {(value) =>
      value ? (
        <p className="text-center">Cargando...</p>
      ) : (
        <p className="text-center">No se encontraron resultados</p>
      )
    }
  </LoadingConsumer>
);

export default NoResult;
