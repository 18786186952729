import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'shards-react';

export function AlertModal({ open, headerText, content, toggle }) {
  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Aceptar</Button>
      </ModalFooter>
    </Modal>
  );
}
