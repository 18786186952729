import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from '@fullcalendar/interaction';

import { ScraperService } from '../../../../services/ScraperService';
import Card from '../common/Card';
import StatusBadge from './StatusBadge';
import Loading from '../Loading';
import constants from '../../../../data/Constants';
import { formatDateTime, isSameDate } from '../../../../utils/manualScraper';
import { color, typography } from '../../../../utils/theme';

const businessHours = { daysOfWeek: [1, 2, 3, 4, 5] };
const calendarPlugins = [dayGridPlugin, bootstrapPlugin, interactionPlugin];

const Calendar = ({ account, infoProvider, value, onSelect }) => {
  const [now] = useState(value || new Date());
  const [events, setEvents] = useState([]);
  const [month, setMonth] = useState(now.getMonth());
  const [year, setYear] = useState(now.getFullYear());
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef();

  useEffect(() => {
    async function getEvents() {
      const summary = await ScraperService().getMonthlySummary(
        account.id,
        year,
        month + 1,
        infoProvider ? [infoProvider] : account.infoProviders
      );
      setEvents(summary);
      setLoading(false);
    }
    if (account && year && month >= 0 && month <= 11) {
      setEvents([]);
      setLoading(true);
      getEvents();
    }
  }, [account, year, month, infoProvider]);

  useEffect(() => {
    if (value) {
      calendarRef.current.calendar.select(value);
    }
  }, [value]);

  const handleCalendarRender = ({ view }) => {
    const date = view.calendar.getDate();
    const newMonth = date.getMonth();

    setYear(date.getFullYear());
    setMonth(newMonth);
  };

  const eventRender = ({ event, view }) => {
    const cell = findCell(view, event.start);

    if (cell) {
      addClassToDay(cell, event.classNames);
    }
  };

  const highlighSelection = useCallback(
    ({ date, el }) => {
      if (isSameDate(date, value)) {
        addClassToDay(el, 'active');
      }
    },
    [value]
  );

  const handleDateSelection = useCallback(
    (info) => {
      if (!isSameDate(info.date, value)) {
        onSelect(info.date);
      }
    },
    [value, onSelect]
  );

  const formatTitle = ({ end }) => {
    return formatDateTime(end.marker, 'monthOfYear');
  };

  return (
    <Card>
      {loading && <Loading />}
      <CalendarWrapper>
        <FullCalendar
          businessHours={businessHours}
          dateClick={handleDateSelection}
          datesRender={handleCalendarRender}
          dayRender={highlighSelection}
          defaultView="dayGridMonth"
          eventRender={eventRender}
          events={events}
          firstDay={0}
          header={{ left: 'prev,next', center: 'title', right: '' }}
          height={450}
          locale={esLocale}
          now={now}
          plugins={calendarPlugins}
          ref={calendarRef}
          themeSystem="bootstrap"
          titleFormat={formatTitle}
          unselectAuto={false}
        />
      </CalendarWrapper>
      <hr />
      <div className="mb-3">
        <StatusExplanation className="row mb-2 ml-0">
          <StatusBadge className="mr-2" color={constants.scrapperDocMissingColor} size="0.75rem" />
          Documentos faltantes
        </StatusExplanation>
        <StatusExplanation className="row mb-2 ml-0">
          <StatusBadge className="mr-2" color={constants.scrapperParserFailColor} size="0.75rem" />
          Documentos mal parseados
        </StatusExplanation>
        <StatusExplanation className="row mb-2 ml-0">
          <StatusBadge className="mr-2" color={constants.scrapperOkColor} size="0.75rem" />
          Documentos OK
        </StatusExplanation>
      </div>
    </Card>
  );
};

const findCell = (view, date) => {
  const dateString = formatDateTime(date, 'iso');

  return view.dayGrid.cellEls.find(
    (cellElement) => cellElement.getAttribute('data-date') === dateString
  );
};

const addClassToDay = (el, classNames) => {
  el.classList.add(classNames);

  const dateString = el.getAttribute('data-date');
  const content = el
    .closest('.fc-row')
    .querySelector(`.fc-content-skeleton .fc-day-top[data-date="${dateString}"]`);

  if (content) {
    content.classList.add(classNames);
  }
};

const StatusExplanation = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 0.625rem;
  line-height: 12px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
`;

const CalendarWrapper = styled.div`
  .fc {
    ${typography('cell')};
    font-size: 12px;
    color: ${color('brandBlack', 'regular')};
  }

  // Hide scrollbar
  .fc-head-container .fc-row.table-bordered {
    margin-right: 0;
  }
  .fc-scroller.fc-day-grid-container {
    overflow: hidden !important;
  }

  // Header styles
  .fc-toolbar {
    justify-content: unset;

    .fc-prev-button {
      margin-right: 10px;
    }

    h2 {
      ${typography('H2')};
      margin-bottom: 0;
      font-weight: bold;
    }

    .fc-center {
      margin-left: 0.75rem;
    }
  }

  .fc-view {
    // Hide header borders
    > .table-bordered,
    .fc-head,
    .fc-head .fc-head-container,
    .fc-head .table-bordered,
    .fc-head .fc-day-header {
      border: 0;
    }

    .fc-head {
      .fc-day-header {
        text-transform: uppercase;
      }
    }

    // Hide all events
    .fc-event-container {
      display: none;
    }

    // Make daynumber full day height so we can center it
    .fc-content-skeleton,
    .fc-content-skeleton table,
    .fc-content-skeleton table thead {
      height: 100%;
    }

    // Change thead display so it can have height 100%
    .fc-content-skeleton {
      thead {
        display: table-row-group;
      }
    }

    // Style day-numbers
    .fc-day-top {
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;

      .fc-day-number {
        float: unset;
        padding: 0;
      }

      &.scrap-done {
        color: ${color(constants.scrapperOkColor)};
        &.active:not(.fc-today) {
          background-color: ${color(constants.scrapperOkColor)};
          color: ${color('brandWhite', 'regular')};
        }
      }

      &.scrap-docmissing {
        color: ${color(constants.scrapperDocMissingColor)};
        &.active:not(.fc-today) {
          background-color: ${color(constants.scrapperDocMissingColor)};
          color: ${color('brandWhite', 'regular')};
        }
      }

      &.scrap-parserfail {
        color: ${color(constants.scrapperParserFailColor)};
        &.active:not(.fc-today) {
          background-color: ${color(constants.scrapperParserFailColor)};
          color: ${color('brandWhite', 'regular')};
        }
      }

      &.fc-today {
        background-color: rgba(207, 225, 255, 0.6) !important;

        &.active {
          background-color: #cfe1ff !important;
        }
      }

      .fc-highlight-skeleton {
        display: none;
      }
    }
  }
`;

export default Calendar;
