import React from 'react';
import styled from 'styled-components';
import { ButtonGroup, Button } from 'shards-react';

export default function Paginate({ onPageChange, disabled = false }) {
  return (
    <Container>
      <ButtonGroup className="ml-2 mr-2">
        <Button disabled={disabled} onClick={() => onPageChange(-1)} outline pill>
          Anterior
        </Button>
        <Button disabled={disabled} onClick={() => onPageChange(1)} outline pill>
          Siguiente
        </Button>
      </ButtonGroup>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;
