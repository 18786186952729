import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CardHeader, CardBody, CardFooter } from 'shards-react';
import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import TableWithPagination from '../../../components/common/Table/TableWithPagination';
import PaymentService from '../../../services/PaymentService';
import DateInput from '../../../components/inputs/DateInput';
import PaymentOrderInput from '../../../components/inputs/PaymentOrderInput';
const redirectPaymentsUrl = '/admin/payments/$';
const paymentsPerPage = 10;

class ShowPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      filter: {
        establishmentNumber: this.props.establishmentNumber
      },
      offset: 0,
      loading: true
    };
    this.inputs = [DateInput, PaymentOrderInput];

    this.headers = [
      {
        value: 'paymentDate',
        label: 'Fecha de Pago',
        hasLink: true,
        redirect: redirectPaymentsUrl
      },
      {
        value: 'clearingNumber',
        label: 'Numero de Liquidación',
        hasLink: true,
        redirect: redirectPaymentsUrl
      },
      {
        value: 'totalAmount',
        label: 'Total Presentado',
        hasLink: true,
        redirect: redirectPaymentsUrl
      },
      {
        value: 'totalDeduction',
        label: 'Total Deducido',
        hasLink: true,
        redirect: redirectPaymentsUrl
      },
      {
        value: 'totalEarn',
        label: 'Total Acreditado',
        hasLink: true,
        redirect: redirectPaymentsUrl
      },
      {
        value: 'totalEarn',
        label: 'Total Acreditado',
        hasLink: true,
        redirect: redirectPaymentsUrl
      }
    ];
  }

  componentDidMount = () => {
    this.queryEstablishmentsPayments();
  };

  onSubmit = (filter) => {
    this.setState(
      { filter: { establishmentNumber: this.props.establishmentNumber, ...filter } },
      () => {
        this.queryEstablishmentsPayments();
      }
    );
  };

  handlePageClick = async (direction) => {
    const newOffset = this.state.offset + direction * paymentsPerPage;
    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.queryCredentialEstablishments());
    }
  };

  queryEstablishmentsPayments = async () => {
    const paymentService = await new PaymentService();
    const filter = { paymentDate: this.state.filter.date, ...this.state.filter };
    delete filter.date;
    paymentService
      .list(filter, paymentsPerPage, this.state.offset)
      .then((data) => {
        this.setState({
          payments: data,
          offset: !data.length ? 0 : this.state.offset
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Fragment>
        <CardHeader className="border-bottom">
          <h6 className="m-0">Liquidaciones</h6>
        </CardHeader>
        <CardBody>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} />
          <TableWithPagination
            data={this.state.payments}
            handlePageClick={this.handlePageClick}
            headers={this.headers}
          />
        </CardBody>
        <CardFooter />
      </Fragment>
    );
  }
}

ShowPayments.propTypes = {
  establishmentNumber: PropTypes.string
};

export default ShowPayments;
