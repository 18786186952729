import React from 'react';
import MessageDismissable from './MessageDismissable';

const DismissableMessagesList = ({ messages, dismissMessage }) => {
  if (messages !== undefined && messages !== null) {
    return messages.map((msg, index) => {
      return <MessageDismissable dismissMessage={dismissMessage} key={index} msg={msg} />;
    });
  }
  return '';
};

export default DismissableMessagesList;
