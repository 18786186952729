import React, { useState, useEffect, useContext } from 'react';
import CredentialService from '../services/CredentialService';

export const SharedDataContext = React.createContext({
  documentsToManualScraper: null
});

export function SharedDataProvider({ children }) {
  const [documentsToManualScraper, setDocumentsToManualScraper] = useState(null);

  const getDocumentsToManualScraper = async () => {
    const credentialService = await CredentialService();
    credentialService.getDocumentsToManualScraper().then((documents) => {
      // transform api response: create an array with all infoProviders of all countries
      const docs = Object.keys(documents)
        .map((country) => {
          return Object.keys(documents[country]).map((infoProvider) => infoProvider);
        })
        .reduce((acc, docsByCountry) => acc.concat(docsByCountry));
      // save state
      setDocumentsToManualScraper(docs);
    });
  };

  useEffect(() => {
    getDocumentsToManualScraper();
  }, []);
  return (
    <SharedDataContext.Provider
      value={{
        documentsToManualScraper
      }}
    >
      {children}
    </SharedDataContext.Provider>
  );
}

export function useDocumentsToManualScraper() {
  return useContext(SharedDataContext).documentsToManualScraper;
}
