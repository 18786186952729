import React from 'react';
import { Container, Card, CardHeader, CardBody, Row, Col, Button } from 'shards-react';
import AsyncSelect from 'react-select/lib/Async';

import PageTitle from '../../../components/PageTitle';
import { debouncedFetchAccounts } from '../../../utils/accounts';

import { withRouter } from 'react-router-dom';

const PreEditor = () => {
  const [accountId, setAccountId] = React.useState();

  const NextStepButton = withRouter(({ history }) => (
    <Button
      disabled={accountId === undefined}
      onClick={() => {
        history.push(`/admin/accounts/${accountId}/shops/new`);
      }}
      type="button"
    >
      Siguiente
    </Button>
  ));

  return (
    <Container className="main-content-container px-4" fluid>
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" sm="4" subtitle="" title="Shops" />
      </Row>
      <Row>
        <Col>
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Elija la cuenta para crearle un Shop</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="form-group" md="4">
                  <label htmlFor="accountId">Cuenta</label>
                  <AsyncSelect
                    cacheOptions={true}
                    id="externalAccountId"
                    loadOptions={debouncedFetchAccounts}
                    name="externalAccountId"
                    onChange={(option) => {
                      setAccountId(option.value);
                    }}
                    placeholder="Busca la Account por nombre"
                  />
                </Col>
              </Row>
              <NextStepButton />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PreEditor;
