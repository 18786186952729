import React from 'react';
import PropTypes from 'prop-types';

const Documents = ({ documents }) => (
  <ul>
    {!documents.length ? (
      <span>No se encontraron documentos parseados, posiblemente es una liquidación migrada</span>
    ) : (
      documents.map((document) => (
        <li key={document.link}>
          <a href={document.link}>{`${document.show}: ${document.linkKey}`}</a>
        </li>
      ))
    )}
  </ul>
);

Documents.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      linkKey: PropTypes.string,
      show: PropTypes.string
    })
  )
};

export default Documents;
