import React from 'react';
import PropTypes from 'prop-types';
import GenericInput from '../common/GenericSearchBar/GenericInput';

class UserEmailInput extends React.Component {
  handleChange = (email) => {
    this.props.handleInputChange({ userEmail: email });
  };

  render() {
    return (
      <GenericInput
        handleInputChange={this.handleChange}
        inputName={'emailSearch'}
        inputPlaceholder={'Email del cliente'}
      />
    );
  }
}

UserEmailInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default UserEmailInput;
