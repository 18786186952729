import gql from 'graphql-tag';
import axios from 'axios';
import { intersection } from 'lodash';
import GraphqlClient from './GraphqlClients';
import constants from '../data/Constants';
import { getDocumentTypesByCountry, getDocumentTypesByInfoProviders } from '../utils/manualScraper';

export const ScraperService = () => {
  const gqlClient = GraphqlClient();

  const scraperAccountManagmentSummaryQuery = gql`
    query scp_accountManagementSummaries(
      $filter: scp_SummaryAccountManagementFilter
      $offset: Int
      $limit: Int
    ) {
      summary: scp_accountManagementSummaries(filter: $filter, offset: $offset, limit: $limit) {
        accountId
        account {
          id
          name
        }
        status
        lockedById
        lockedBy {
          firstName
          lastName
        }
        lastUpload
      }
    }
  `;

  const scraperMonthlySummaryQuery = gql`
    query scp_accountManagementMonthly(
      $accountId: String!
      $year: Int!
      $month: Int!
      $infoProvider: [String!]
      $documentType: [String!]
    ) {
      summary: scp_accountManagementMonthly(
        accountId: $accountId
        month: $month
        year: $year
        infoProvider: $infoProvider
        documentType: $documentType
      ) {
        date
        status
      }
    }
  `;

  const scraperDayDetailQuery = gql`
    query scp_accountManagementDaily(
      $accountId: String!
      $date: String!
      $infoProvider: [String!]
      $documentType: [String!]
    ) {
      detail: scp_accountManagementDaily(
        accountId: $accountId
        date: $date
        infoProvider: $infoProvider
        documentType: $documentType
      ) {
        infoProvider
        credentialManagements {
          id
          credential {
            id
            username
            password
            loginPage
            otherLogin
            status
            country
          }
          date
          status
          managementStatus
          lockedById
          lockedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  const getCredentialFilesQuery = gql`
    query scp_documentsFromCredential(
      $credentialId: String!
      $filter: scp_DocumentsFilter!
      $limit: Int
      $offset: Int
    ) {
      managements: scp_documentsFromCredential(
        credentialId: $credentialId
        filter: $filter
        limit: $limit
        offset: $offset
      ) {
        expectedDocuments
        files {
          id
          date
          createdAt
          parsed
          link
          processInApi
        }
      }
    }
  `;

  const uploadLinkMutation = gql`
    mutation scp_manualDocumentLink($fileName: String!, $fileOptions: scp_FileOptions!) {
      result: scp_manualDocumentLink(fileName: $fileName, fileOptions: $fileOptions) {
        errors
        link
      }
    }
  `;

  const notifyFileUploadMutation = gql`
    mutation scp_manualDocumentNotify(
      $link: String!
      $dailyManagementId: String
      $requiresTransformation: Boolean
    ) {
      result: scp_manualDocumentNotify(
        link: $link
        dailyManagementId: $dailyManagementId
        requiresTransformation: $requiresTransformation
      ) {
        message
      }
    }
  `;

  const lockManagementMutation = gql`
    mutation LockManagement($credentialId: String!, $date: String!, $doneBy: String!) {
      result: scp_dailyCredentialManagementLock(
        credentialId: $credentialId
        date: $date
        doneBy: $doneBy
      ) {
        management {
          id
          status
          credential {
            id
          }
          lockedById
          lockedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  const unlockManagementMutation = gql`
    mutation UnlockManagement($id: String!, $doneBy: String!) {
      result: scp_dailyCredentialManagementUnlock(id: $id, doneBy: $doneBy) {
        management {
          id
          status
          lockedById
          lockedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  const finishManagementMutation = gql`
    mutation FinishManagement($id: String!, $doneBy: String!, $exception: Boolean!) {
      result: scp_dailyCredentialManagementFinish(id: $id, doneBy: $doneBy, exception: $exception) {
        management {
          id
          status
          lockedById
          lockedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  const cancelManagementMutation = gql`
    mutation CancelManagement($id: String!, $doneBy: String!) {
      result: scp_dailyCredentialManagementCancel(id: $id, doneBy: $doneBy) {
        management {
          id
          status
          lockedById
          lockedBy {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  const getAccountsSummary = async (filter, offset, limit) => {
    const documentType = getDocumentTypesByCountry();
    return gqlClient
      .query({
        fetchPolicy: 'network-only',
        query: scraperAccountManagmentSummaryQuery,
        variables: {
          filter: {
            ...filter,
            documentType,
            infoProvider: constants.scraperProviders
          },
          offset,
          limit
        }
      })
      .then((data) => {
        return data.data.summary;
      });
  };

  const scraperProviders = (infoProviders = []) => {
    return intersection(constants.scraperProviders, infoProviders);
  };

  const StatusStyle = {
    done: {
      color: constants.scrapperOkColor,
      classNames: 'scrap-done'
    },
    parser_fail: {
      color: constants.scrapperParserFailColor,
      classNames: 'scrap-parserfail'
    },
    document_missing: {
      color: constants.scrapperDocMissingColor,
      classNames: 'scrap-docmissing'
    }
  };

  const getMonthlySummary = async (accountId, year, month, infoProviders = []) => {
    // Filter infoProviders by ignoredScraperProviders only in MonthlySummary
    // couse we don't care for naranja scraping status
    const providers = scraperProviders(infoProviders).filter(
      (provider) => !constants.ignoredScraperProviders.includes(provider)
    );
    const documentTypes = getDocumentTypesByInfoProviders(providers);
    return gqlClient
      .query({
        fetchPolicy: 'network-only',
        query: scraperMonthlySummaryQuery,
        variables: {
          accountId,
          month,
          year,
          infoProvider: providers,
          documentType: documentTypes
        }
      })
      .then((data) => {
        const monthInfo = data.data.summary;
        return monthInfo.map((info) => {
          const dateParts = info.date.split('-');
          const start = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0);
          const end = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 23, 59, 29);
          return {
            allDay: true,
            color: StatusStyle[info.status].color,
            id: info.date,
            start,
            end,
            classNames: StatusStyle[info.status].classNames
          };
        });
      })
      .catch(() => []);
  };

  const getAccountDaySummary = async (accountId, date, infoProviders) => {
    const providers = scraperProviders(infoProviders);
    const documentTypes = getDocumentTypesByInfoProviders(providers);
    return gqlClient
      .query({
        // Turn off cache. Response include Objects with id null (managements that are awaiting_start)
        // If use cache, then all responses with null will have the same info (i.e. two credentials show the same info)
        fetchPolicy: 'no-cache',
        query: scraperDayDetailQuery,
        variables: {
          accountId,
          date,
          infoProvider: providers,
          documentType: documentTypes
        }
      })
      .then((response) => {
        const orders = constants.scraperProvidersOrder;

        return response.data.detail.sort((data1, data2) => {
          return orders[data1.infoProvider] - orders[data2.infoProvider];
        });
      })
      .catch(() => []);
  };

  const getFiles = async (credentialId, filter, limit, offset) => {
    return gqlClient
      .query({
        fetchPolicy: 'network-only',
        query: getCredentialFilesQuery,
        variables: { credentialId, filter, limit, offset }
      })
      .then((data) => {
        return data.data.managements;
      })
      .catch(() => []);
  };

  const lockManagement = async (credentialId, date, doneBy) => {
    return gqlClient
      .mutate({
        mutation: lockManagementMutation,
        variables: { credentialId, date, doneBy }
      })
      .then((data) => data.data.result.management)
      .catch(() => null);
  };

  const unlockManagement = async (managementId, doneBy) => {
    return gqlClient
      .mutate({
        mutation: unlockManagementMutation,
        variables: { id: managementId, doneBy }
      })
      .then((data) => data.data.result.management)
      .catch(() => null);
  };

  const finishManagement = async (managementId, doneBy, exception) => {
    return gqlClient
      .mutate({
        mutation: finishManagementMutation,
        variables: { id: managementId, doneBy, exception }
      })
      .then((data) => data.data.result.management)
      .catch(() => null);
  };

  const cancelManagement = async (managementId, doneBy) => {
    return gqlClient
      .mutate({
        mutation: cancelManagementMutation,
        variables: { id: managementId, doneBy }
      })
      .then((data) => data.data.result.management)
      .catch(() => null);
  };

  const notifyFileUpload = async (link, managmentId, requiresTransformation = false) => {
    return gqlClient
      .mutate({
        mutation: notifyFileUploadMutation,
        variables: { link, dailyManagementId: managmentId, requiresTransformation }
      })
      .then((data) => data.data.result)
      .catch(() => null);
  };

  const getUploadLink = (fileName, fileOptions) => {
    return gqlClient
      .mutate({
        mutation: uploadLinkMutation,
        variables: { fileName, fileOptions }
      })
      .then((response) => response.data.result.link);
  };

  const uploadDocument = async (fileOptions, file, managementId, onProgress) => {
    return getUploadLink(file.name, fileOptions)
      .then((rawParams) => {
        const params = JSON.parse(rawParams);
        const formData = new FormData();

        formData.append('Content-Type', params['Content-Type'] || 'multipart/form-data');
        formData.append('key', params.key);
        formData.append('acl', params.acl);
        formData.append('x-amz-credential', params['x-amz-credential']);
        formData.append('x-amz-date', params['x-amz-date']);
        formData.append('x-amz-signature', params['x-amz-signature']);
        formData.append('x-amz-algorithm', params['x-amz-algorithm']);
        if (params['x-amz-security-token']) {
          formData.append('x-amz-security-token', params['x-amz-security-token']);
        }
        formData.append('policy', params.policy);
        formData.append('Expires', params['Expires']);
        formData.append('file', file);

        return axios
          .post(params['url'], formData, {
            onUploadProgress: (progressEvent) => {
              if (onProgress) {
                onProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
              }
            }
          })
          .then(() => ({ url: params['url'], key: params['key'] }));
      })
      .then(({ url, key }) => {
        const { country } = fileOptions;
        // Right transformation is only enabled for uruguay.
        // Scraper do not work yet with other countries than Argentian but we left this prepared.
        const requiresTransformation = country === 'uruguay' && file.name.endsWith('.pdf');
        return notifyFileUpload(key, managementId, requiresTransformation).then((res) => {
          return { ...res, file, url };
        });
      });
  };

  const providerInfo = (credentialManagements) => {
    const credentialsOk = credentialManagements.filter(isCredentialStatusDone);
    const ok = credentialsOk.length;
    const total = credentialManagements.length;
    return {
      total,
      ok,
      percentage: total > 0 ? Math.floor((ok / total) * 100) : 0
    };
  };

  const getInfoProviders = (infoProviders = []) => {
    const providers = constants.infoProvidersScraper.filter((provider) => {
      return infoProviders.some((id) => provider.value === id);
    });

    const orders = constants.scraperProvidersOrder;
    providers.sort((p1, p2) => orders[p1.value] - orders[p2.value]);

    return providers;
  };

  const isCredentialStatusDone = (management) => management.status === 'done';
  const isCredentialStatusParserFail = (management) => management.status === 'parser_fail';
  const isCredentialStatusDocumentMissing = (management) =>
    management.status === 'document_missing';
  const isDayStatusParserFail = (day) => day.find(isCredentialStatusParserFail);
  const isDayStatusDocumentMissing = (day) => day.find(isCredentialStatusDocumentMissing);

  const credentialFinishedColor = (management) => {
    if (!management) {
      return ['brandWhite', 'regular'];
    }
    if (isCredentialStatusDocumentMissing(management)) {
      return constants.scrapperDocMissingColor;
    }
    if (isCredentialStatusParserFail(management)) {
      return constants.scrapperParserFailColor;
    }
    return constants.scrapperOkColor;
  };

  const dayFinishedColor = (managements, infoProvider) => {
    if (constants.ignoredScraperProviders.includes(infoProvider)) {
      return constants.scrapperIgnoreColor;
    }
    if (isDayStatusDocumentMissing(managements)) {
      return constants.scrapperDocMissingColor;
    }
    if (isDayStatusParserFail(managements)) {
      return constants.scrapperParserFailColor;
    }
    return constants.scrapperOkColor;
  };

  return {
    getAccountsSummary,
    getMonthlySummary,
    getFiles,
    getAccountDaySummary,
    uploadDocument,
    providerInfo,
    getInfoProviders,
    lockManagement,
    unlockManagement,
    finishManagement,
    cancelManagement,
    rejectNonScrapableProviders: scraperProviders,
    credentialFinishedColor,
    dayFinishedColor
  };
};
