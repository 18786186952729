import React from 'react';
import Constants from '../../data/Constants';
import PropTypes from 'prop-types';
import GenericInputSelect from '../common/GenericSearchBar/GenericInputSelect';

class CardBrandInput extends React.Component {
  handleChange = (cardBrand) => {
    this.props.handleInputChange({ cardBrand: cardBrand });
  };

  render() {
    return (
      <GenericInputSelect
        handleInputChange={this.handleChange}
        selectName={'cardBrandSearch'}
        selectOptions={Constants.cardBrands}
        selectPlaceholder={'Tarjeta'}
      />
    );
  }
}

CardBrandInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default CardBrandInput;
