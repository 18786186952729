import React from 'react';
import { Container, Card, CardHeader, CardBody, Row, Col } from 'shards-react';
import { Link } from 'react-router-dom';

import PageTitle from '../../../components/PageTitle';
import ShopService from '../../../services/ShopService';
import Errors from '../../../components/Errors';

class ShopShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shop: {
        id: props.shopId || null,
        name: null,
        account: {
          id: props.accountId,
          establishments: []
        },
        establishments: []
      },
      redirect: null
    };
  }

  componentDidMount() {
    this.findShop();
  }

  findShop = async () => {
    const shopService = await ShopService();
    shopService.get(this.props.shopId).then((data) => {
      this.setState({ shop: data });
    });
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Shops" />
        </Row>
        <Row>
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Shop</h6>
            </CardHeader>
            <CardBody>
              <Errors errors={this.state.errors} />
              <Row>
                <Col lg="3" sm="6" xs="12">
                  ID
                </Col>
                <Col lg="9" sm="6" xs="12">
                  {this.state.shop.id}
                </Col>
              </Row>
              <Row>
                <Col lg="3" sm="6" xs="12">
                  Nombre
                </Col>
                <Col lg="9" sm="6" xs="12">
                  {this.state.shop.name || '-'}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
        <Row>
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Establecimientos</h6>
            </CardHeader>
            <CardBody>
              <table className="table mb-0 table-hover">
                <thead className="bg-light">
                  <tr>
                    <th className="border-0" scope="col">
                      Id de Establecimiento
                    </th>
                    <th className="border-0" scope="col">
                      Número de Establecimiento
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.shop.establishments.map((estab) => {
                    return (
                      <tr key={`tr-${estab.id}`}>
                        <td>{estab.id || ''}</td>
                        <td>{estab.number || ''}</td>
                        <td>
                          <Link to={`/admin/establishments/${estab.id}`}>Ver</Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default ShopShow;
