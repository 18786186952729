import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './serviceWorker';
import Router from './Router';
import { ThemeProvider } from '@increase/typed-components';
import { SharedDataProvider } from './contexts/SharedDataContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/shards-dashboards.1.1.0.min.css';

import './App.css';
import { AuthProvider } from './contexts/AuthContext';

ReactDOM.render(
  <AuthProvider>
    <SharedDataProvider>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </SharedDataProvider>
  </AuthProvider>,
  document.getElementById('root')
);
