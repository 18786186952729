import React from 'react';
import { Container } from 'shards-react';
import styled from 'styled-components';

const IconBox = (props) => {
  const { match } = props;
  return (
    <ContainerBox className="IconBox">
      {match ? (
        <i className="material-icons IconGreen">check_circle</i>
      ) : (
        <i className="material-icons IconRed">cancel</i>
      )}
    </ContainerBox>
  );
};

const ContainerBox = styled(Container)`
  & {
      text-align: right;
      font-weight: 700;
      font-size: 20px;
      padding-left: 15px;
      margin: 0;
  }
}
`;

export default IconBox;
