import React from 'react';
import { DatePicker } from 'shards-react';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';
registerLocale('es', es);

class GenericInputDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null
    };
  }

  handleChange = (date) => {
    this.setState({ date: date });

    const value = date instanceof Date ? date.toISOString().slice(0, 10) : null;

    this.props.handleInputChange(value);
  };

  render() {
    const { inputName, placeHolder } = this.props;
    return (
      <DatePicker
        dateFormat="yyyy-MM-dd"
        locale="es"
        name={inputName}
        onChange={this.handleChange}
        placeholderText={placeHolder}
        selected={this.state.date}
        todayButton={'Hoy'}
      />
    );
  }
}

GenericInputDate.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  placeHolder: PropTypes.string
};

export default GenericInputDate;
