import React from 'react';
import { useUserEmail, useRealUser } from '../../../contexts/AuthContext';
import styled from 'styled-components';

const SidebarUserInfo = () => {
  const userEmail = useUserEmail();
  const realUser = useRealUser();
  return (
    <CustomDiv>
      <p>
        <strong>{realUser ? realUser.email : userEmail}</strong>
      </p>
      {realUser && (
        <React.Fragment>
          <small>Estas viendo como: </small>
          <br />
          <small>
            <i>{userEmail}</i>
          </small>
        </React.Fragment>
      )}
    </CustomDiv>
  );
};

const CustomDiv = styled.div`
  width: 100%;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
`;

export default SidebarUserInfo;
