import React from 'react';
import { FormCheckbox } from 'shards-react';
import PropTypes from 'prop-types';
import { Flex } from '../common/Flex';

class PaymentOrderInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creationDate: { selected: true, value: 'created_at' },
      paymentDate: { selected: false, value: 'payment_date' }
    };
  }

  handleChange(element) {
    const newState = {
      creationDate: { ...this.state.creationDate, selected: false },
      paymentDate: { ...this.state.paymentDate, selected: false }
    };
    newState[element].selected = true;

    this.setState(newState);
    this.props.handleInputChange({ orderBy: newState[element].value });
  }

  render() {
    return (
      <Flex justifyContent="space-evenly">
        <FormCheckbox
          checked={this.state.creationDate.selected}
          onChange={() => this.handleChange('creationDate')}
        >
          Fecha de Creación
        </FormCheckbox>
        <FormCheckbox
          checked={this.state.paymentDate.selected}
          onChange={() => this.handleChange('paymentDate')}
        >
          Fecha de Pago
        </FormCheckbox>
      </Flex>
    );
  }
}

PaymentOrderInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default PaymentOrderInput;
