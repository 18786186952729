import React, { useMemo } from 'react';
import { Row, Col, Nav, NavItem } from 'shards-react';
import styled from 'styled-components';

import Card from '../common/Card';
import { ScraperService } from '../../../../services/ScraperService';
import ProviderBox from '../ProviderBox';
import CredentialData from '../CredentialDetail';
import { colorSystem } from '../../../../utils/theme';

const CredentialNavItem = styled(NavItem)`
  border-bottom: 0px solid ${colorSystem};
  cursor: pointer;
  color: ${colorSystem};
  opacity: 0.4;

  ${({ active }) =>
    active &&
    `
    border-bottom-width: 3px;
    opacity: 1;
  `}
`;

const CredentialChooser = ({ credentials, infoProvider, value, onSelect }) => {
  const color = useMemo(() => {
    return ScraperService().credentialFinishedColor(value);
  }, [value]);

  return (
    <Card>
      <Nav className="card-header">
        {credentials.map((info) => (
          <CredentialNavItem
            active={info === value}
            className="px-2 pb-2"
            color={ScraperService().credentialFinishedColor(info)}
            key={info.credential.id}
            onClick={() => onSelect(info)}
          >
            {info.credential.username}
          </CredentialNavItem>
        ))}
      </Nav>

      {value && (
        <Row className="mt-3">
          <Col className="d-flex align-items-center" xs="12">
            <ProviderBox active={true} color={color} infoProvider={infoProvider} />
            <CredentialData className="ml-3" {...value.credential} />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default CredentialChooser;
