import React, { useMemo } from 'react';
import styled from 'styled-components';

import { color, colorSystem } from '../../../utils/theme';
import Translator from '../../../services/Translator';

const ProviderContainer = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 145px;
  border: 1px solid ${color('brandWhite', 'medium')};
  box-sizing: border-box;
  border-radius: 10px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  ${({ selected, ...rest }) =>
    selected &&
    `
    background-color: ${colorSystem(rest)};
    color: ${color('brandWhite', 'regular')(rest)};
  `}

  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
  `}

  .provider-name {
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 5px;
    text-align: center;
  }

  .provider-content {
    font-size: 1.25rem;
    letter-spacing: -0.011em;
  }
`;

const ProviderStatus = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9px;
  background: ${colorSystem};
  border-radius: 9px 9px 0px 0px;
`;

const ProviderBox = ({ selected, disabled, onClick, className, color, infoProvider, children }) => {
  const provider = useMemo(() => {
    return Translator.scraperInfoProvider(infoProvider);
  }, [infoProvider]);

  return (
    <ProviderContainer
      className={className}
      color={color}
      disabled={disabled}
      onClick={() => !disabled && onClick && onClick(infoProvider)}
      selected={selected}
    >
      <ProviderStatus color={color} />
      <div className="provider-name">{provider}</div>
      {children && <div className="provider-content">{children}</div>}
    </ProviderContainer>
  );
};

export default ProviderBox;
