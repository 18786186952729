import React, { useRef } from 'react';
import { Button } from '@increase/typed-components';
import { Label } from '@increase/typed-components';
import styled from 'styled-components';

import { color } from '../../../../utils/theme';

const InputWraper = styled.div`
  display: inline-flex;
  flex-direction: column;

  &.has-error {
    button {
      border-color: ${color('alerta', 'regular')};

      &:hover,
      &:active {
        background-color: ${color('alerta', 'regular')};
      }

      span {
        color: ${color('alerta', 'regular')};
      }
    }
  }
`;

const LabelError = styled(Label)`
  color: ${color('alerta', 'regular')};
`;

const InputFile = ({ label, disabled, errorMessage, onChange, multiple = false }) => {
  const inputRef = useRef();

  return (
    <InputWraper className={errorMessage && 'has-error'}>
      <Button buttonType="outline" disabled={disabled} onClick={() => inputRef.current.click()}>
        <i className="material-icons mr-2 font-weight-bold">add</i>
        {label}
      </Button>

      <input
        className="d-none"
        disabled={disabled}
        multiple={multiple}
        onChange={onChange}
        ref={inputRef}
        type="file"
      />

      {errorMessage && <LabelError>{errorMessage}</LabelError>}
    </InputWraper>
  );
};

export default InputFile;
