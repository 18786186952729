import React from 'react';
import { Button } from '@increase/typed-components';

const LockManagementAction = ({ credential, onClick, disabled }) => {
  const translation = {
    awaiting_start: 'Iniciar Gestión',
    pending: 'Editar Gestión',
    done: 'Reabri Gestión'
  };

  const actionText = translation[credential.managenmentStatus] || 'Iniciar Gestión';

  return (
    <Button className="ml-3" disabled={disabled} onClick={onClick}>
      {actionText}
    </Button>
  );
};

export default LockManagementAction;
