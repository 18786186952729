import React from 'react';
import { Container, Row } from 'shards-react';
import PageTitle from '../../../../components/PageTitle';
import MonthlyComparatorService from '../../../../services/MonthlyComparatorService';

import GenericSearchBar from '../../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../../contexts/LoadingContext';

import UserEmailInput from '../../../../components/inputs/UserEmailInput';
import ShopNameInput from '../../../../components/inputs/ShopNameInput';
import EstablishmentNumberInput from '../../../../components/inputs/EstablishmentNumberInput';
import CardBrandInput from '../../../../components/inputs/CardBrandInput';
import InfoProviderSimpleInput from '../../../../components/inputs/InfoProviderSimpleInput';
import MonthAndYearInput from '../../../../components/inputs/MonthAndYearInput';
import Table from './Table';
import Constants from '../../../../data/Constants';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      monthlySummaries: [],
      offset: 0,
      loading: true
    };

    this.inputs = [
      UserEmailInput,
      EstablishmentNumberInput,
      ShopNameInput,
      MonthAndYearInput,
      CardBrandInput,
      InfoProviderSimpleInput
    ];
  }

  componentDidMount() {
    const currentDate = new Date();

    this.setState(
      {
        filter: {
          month: currentDate.getMonth(),
          year: currentDate.getFullYear()
        }
      },
      () => this.getMonthlySummaries()
    );
  }

  onSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.getMonthlySummaries());
  };

  getMonthlySummaries = async () => {
    this.setState({ loading: true });

    window.scrollTo(0, 0);

    const monthlyComparatorService = await MonthlyComparatorService();
    monthlyComparatorService
      .getSummaries(this.state.filter, Constants.elementsPerPage, this.state.offset)
      .then((data) => {
        this.setState({
          monthlySummaries: data || [],
          offset: data === null || !data.length ? 0 : this.state.offset
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  handlePageClick = async (direction) => {
    const newOffset = this.state.offset + direction * Constants.elementsPerPage;

    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.getMonthlySummaries());
    }
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Comparador Mensual" />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} inputWidth="33" />
          <Table
            monthlySummaries={this.state.monthlySummaries}
            onPageClick={this.handlePageClick}
          />
        </LoadingProvider>
      </Container>
    );
  }
}

export default List;
