import React from 'react';
import Paginate from '../../../components/Paginate';
import { FormCheckbox } from 'shards-react';
import { Link } from 'react-router-dom';
import NoResult from '../../../components/common/NoResult';
import PropTypes from 'prop-types';
import Translator from '../../../services/Translator';

const ScrapeTable = ({ establishments, onPageClick, onSelect, selectedEstablishments }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th>Sucursal</th>
          <th>Establecimiento</th>
          <th>Credencial</th>
          <th>Estado</th>
          <th>Proveedor</th>
          <th>Última Liquidación</th>
          <th>Seleccionado</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {establishments.map((model) => {
          const checked =
            selectedEstablishments.filter((estab) => estab.number === model.number).length > 0;
          return (
            <tr key={model.id || ''}>
              <td>{model.shop ? model.shop.name : '-'}</td>
              <td>{model.number || ''}</td>
              <td>{formatCredentialsUsernames(model)}</td>
              <td>{formatCredentialsStatus(model)}</td>
              <td>{formatCredentialsProviders(model)}</td>
              <td>
                {model.lastPayment ? (
                  <Link to={`/admin/payments/${model.lastPayment.id}`}>
                    {model.lastPayment.paymentDate}
                  </Link>
                ) : (
                  '-'
                )}
              </td>
              <td>
                <FormCheckbox
                  checked={checked}
                  onChange={() => {
                    onSelect(model, !checked);
                  }}
                />
              </td>
              <td>
                <Link to={`/admin/establishments/${model.id || ''}`}>Ver</Link>
              </td>
              <td>
                <Link to={`/admin/establishments/${model.id || ''}/edit`}>Editar</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {!establishments.length ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

const formatCredentialsUsernames = (establishment) => {
  if (!establishment.credentials) return '-';
  return (
    <React.Fragment>
      {establishment.credentials.map((credential) => {
        return (
          <Link key={credential.id} to={`/admin/credentials/${credential.id}`}>{`${
            credential.username
          } `}</Link>
        );
      })}
    </React.Fragment>
  );
};

const formatCredentialsProviders = (establishment) =>
  establishment.credentials.map((cred) => Translator.infoProvider(cred.infoProvider)).join(' - ');

const formatCredentialsStatus = (establishment) =>
  establishment.credentials.map((cred) => Translator.credentialStatus(cred.status)).join(' - ');

ScrapeTable.propTypes = {
  establishments: PropTypes.array,
  onPageClick: PropTypes.func,
  onSelect: PropTypes.func,
  selectedEstablishments: PropTypes.array
};

export default ScrapeTable;
