import React from 'react';
import Paginate from '../../../components/Paginate';
import { Link } from 'react-router-dom';
import NoResult from '../../../components/common/NoResult';
import PropTypes from 'prop-types';

const Table = ({ accounts, onPageClick }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="w-30" scope="col">
            Nombre
          </th>
          <th className="w-20" scope="col">
            Cuit
          </th>
          <th className="w-20" scope="col">
            Autogenerada
          </th>
          <th className="w-20" scope="col">
            Activa
          </th>
          <th className="w-10" scope="col">
            Ver
          </th>
          <th className="w-10" scope="col">
            Editar
          </th>
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {accounts.map(({ id, name, taxId, autogenerated, active }) => {
          return (
            <tr key={id}>
              <td>{name}</td>
              <td>{taxId}</td>
              <td>{autogenerated ? 'SI' : 'NO'}</td>
              <td>{active ? 'SI' : 'NO'}</td>
              <td>
                <Link to={`/admin/accounts/${id}`}>Ver</Link>
              </td>
              <td>
                <Link to={`/admin/accounts/${id}/edit`}>Editar</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {!accounts.length ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

Table.propTypes = {
  accounts: PropTypes.array,
  onPageClick: PropTypes.func
};

export default Table;
