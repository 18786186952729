import React from 'react';
import { Container, Row } from 'shards-react';
import PageTitle from '../../../../components/PageTitle';
import Table from './Table';
import Header from './Header';
import MonthlyComparatorService from '../../../../services/MonthlyComparatorService';
import PropTypes from 'prop-types';

import GenericSearchBar from '../../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../../contexts/LoadingContext';

import EstablishmentNumberInput from '../../../../components/inputs/EstablishmentNumberInput';
import CardBrandInput from '../../../../components/inputs/CardBrandInput';
import InfoProviderSimpleInput from '../../../../components/inputs/InfoProviderSimpleInput';
import MonthAndYearInput from '../../../../components/inputs/MonthAndYearInput';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      monthlyDetails: [],
      loading: true
    };

    this.inputs = [
      EstablishmentNumberInput,
      MonthAndYearInput,
      CardBrandInput,
      InfoProviderSimpleInput
    ];
  }

  componentDidMount() {
    const currentDate = new Date();

    this.setState({
      filter: {
        month: currentDate.getMonth() - 1,
        year: currentDate.getFullYear()
      }
    });

    this.getMonthlyDetails(this.props.match.params.monthlyPaymentId);
  }

  onSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.getMonthlyDetails());
  };

  getMonthlyDetails = async (monthlyPaymentId) => {
    this.setState({ loading: true });

    const filter = monthlyPaymentId ? { id: monthlyPaymentId } : this.state.filter;

    const monthlyComparatorService = await MonthlyComparatorService();
    monthlyComparatorService
      .getDetails(filter)
      .then((data) => {
        this.setState({ monthlyDetails: data || {} });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle
            className="text-sm-left"
            sm="4"
            subtitle=""
            title="Comparador Mensual - Detalles"
          />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar handleSubmit={this.onSubmit} inputs={this.inputs} inputWidth="25" />
          <Header monthlyDetails={this.state.monthlyDetails} />
          <Table monthlyDetails={this.state.monthlyDetails} />
        </LoadingProvider>
      </Container>
    );
  }
}

List.propTypes = {
  monthlyTableId: PropTypes.string.isRequired
};

export default List;
