import React from 'react';
import { Alert } from 'shards-react';

const Messages = ({ messages }) => {
  if (messages !== undefined && messages !== null) {
    return messages.map((msg, index) => {
      return (
        <Alert key={index} theme="success">
          {msg}
        </Alert>
      );
    });
  }
  return '';
};

export default Messages;
