import React, { useCallback } from 'react';
import { result } from 'lodash';

import { useUserRole } from '../contexts/AuthContext';
import { protectedActions } from './permissions';

const can = (userOrRole, action, data) => {
  const rolesAllowed = result(protectedActions, action.replaceAll(':', '.'));
  const role = userOrRole.role || userOrRole;

  if (!rolesAllowed) {
    return true;
  }

  if (typeof rolesAllowed[role] === 'function') {
    return rolesAllowed[role](data);
  }
  return rolesAllowed[role] === true;
};

// Hook
export const useAuthorize = () => {
  const role = useUserRole();
  const isAuthorized = useCallback(
    (permission, data) => {
      if (!role || !permission) {
        return false;
      }

      return can(role, permission, data);
    },
    [role]
  );

  return isAuthorized;
};

// React component
export const Can = ({ children, action, data, unAuthorizedProps, renderIfCant }) => {
  const isAuthorized = useAuthorize();

  if (!isAuthorized(action, data)) {
    if (unAuthorizedProps !== undefined) {
      return React.Children.map(children, (child) =>
        React.cloneElement(child, { ...unAuthorizedProps })
      );
    }
    if (renderIfCant) return renderIfCant;
    return null;
  }

  return children;
};
