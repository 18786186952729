import React from 'react';
import PropTypes from 'prop-types';

const Discounts = ({ discounts }) => (
  <table className="table mb-0 table-hover table-borderless">
    <thead>
      <tr>
        <th className="border-0" scope="col">
          Categoria
        </th>
        <th className="border-0" scope="col">
          Descripción
        </th>
        <th className="border-0" scope="col">
          Región
        </th>
        <th className="border-0" scope="col">
          Moneda
        </th>
        <th className="border-0" scope="col">
          Porcentaje
        </th>
        <th className="border-0" scope="col">
          Monto
        </th>
      </tr>
    </thead>
    <tbody>
      {discounts.map((discount) => (
        <tr key={discount.amount}>
          <td>
            {[discount.type1, discount.type2, discount.type3, discount.type4]
              .filter((el) => !!el)
              .join(' - ')}
          </td>
          <td>{discount.description}</td>
          <td>{discount.region}</td>
          <td>{discount.currency}</td>
          <td>{discount.rate}</td>
          <td>{discount.amount}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

Discounts.propTypes = {
  discounts: PropTypes.arrayOf(
    PropTypes.shape({
      type1: PropTypes.string,
      type2: PropTypes.string,
      type3: PropTypes.string,
      type4: PropTypes.string,
      description: PropTypes.string,
      region: PropTypes.string,
      currency: PropTypes.string,
      rate: PropTypes.string,
      amount: PropTypes.number
    })
  )
};

export default Discounts;
