import React from 'react';
import { Container as ShardsContainer } from 'shards-react';

const Container = ({ children }) => {
  return (
    <ShardsContainer className="main-content-container px-4 mb-5" fluid>
      {children}
    </ShardsContainer>
  );
};

export default Container;
