import React from 'react';
import { TableRow, Td } from '@increase/typed-components';
import styled from 'styled-components';

import { color } from '../../../utils/theme';

const StyledTd = styled(Td)`
  color: ${color('brandWhite', 'bold')};
`;

export const EmptyTableMessage = ({ ready, collection, message = 'No hay datos' }) => {
  if (!ready || !collection || collection.length > 0) {
    return null;
  }

  return (
    <TableRow>
      <StyledTd colSpan="10">{message}</StyledTd>
    </TableRow>
  );
};
