import React from 'react';
import PageTitle from '../../../components/PageTitle';
import { Container, Row } from 'shards-react';

import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';

import AccountNameOrIdInput from '../../../components/inputs/AccountNameOrIdInput';
import CredentialUsernameInput from '../../../components/inputs/CredentialUsernameInput';
import CredentialStatusInput from '../../../components/inputs/CredentialStatusInput';
import InfoProviderInput from '../../../components/inputs/InfoProviderInput';
import Table from './Table';

import '../../../assets/styles/icons.css';
import CredentialService from '../../../services/CredentialService';
import Constants from '../../../data/Constants';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      credentials: [],
      offset: 0,
      loading: true
    };

    this.inputs = [
      CredentialUsernameInput,
      AccountNameOrIdInput,
      InfoProviderInput,
      CredentialStatusInput
    ];
  }

  componentDidMount() {
    this.queryCredentials();
  }

  onSubmit = (filter) => {
    this.setState({ filter: filter }, () => this.queryCredentials());
  };

  handlePageClick = async (direction) => {
    const newOffset = this.state.offset + direction * Constants.elementsPerPage;

    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.queryCredentials());
    }
  };

  queryCredentials = async () => {
    this.setState({ loading: true });

    window.scrollTo(0, 0);

    const credentialService = await CredentialService();
    credentialService
      .list(this.state.filter, Constants.elementsPerPage, this.state.offset)
      .then((data) => {
        this.setState((prevState) => ({
          credentials: data,
          offset: !data.length ? 0 : prevState.offset
        }));
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Credenciales" />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar handleSubmit={this.onSubmit} inputWidth="50" inputs={this.inputs} />
          <Table credentials={this.state.credentials} onPageClick={this.handlePageClick} />
        </LoadingProvider>
      </Container>
    );
  }
}

export default List;
