import styled from 'styled-components';
import { Td } from '@increase/typed-components';

const TableCell = styled(Td)`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default TableCell;
