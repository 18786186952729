import React from 'react';
import styled from 'styled-components';
import { Table, THead, Th, TableRow } from '@increase/typed-components';
import { LoadingTableRow } from '../LoadingTableRow';
import { EmptyTableMessage } from '../EmptyTableMessage';
import { useCredentialDocuments } from '../../../../hooks/manualScraper';
import TableCell from '../common/TableCell';
import DownloadIcon from '../DownloadIcon';
import ParsedStatusIcon from '../../../../components/ParsedStatusIcon';
import { urlFileName } from '../../../../utils/manualScraper';
import { CredentialDocumentsStatus } from '../../../../components/CredentialDocumentsStatus';

const FilesTable = styled.div`
  > div {
    border: 0px;
    padding: 0px;
  }
`;

const CredentialDocuments = ({ credential, date, className }) => {
  const credentialId = credential && credential.id;
  const [files, managements, { loading }] = useCredentialDocuments(credentialId, {
    date,
    unlimited: true
  });

  if (!credential) {
    return null;
  }

  return (
    <>
      <CredentialDocumentsStatus managements={managements} />
      <FilesTable className={className}>
        <Table>
          <THead>
            <tr>
              <Th width="5%">Parseado</Th>
              <Th scope="col">Documentos subidos</Th>
              <Th width="10%" />
            </tr>
          </THead>
          <tbody>
            <LoadingTableRow loading={loading} />
            {files &&
              files.map((f) => {
                const fileName = urlFileName(f.link);
                return (
                  <TableRow key={f.id}>
                    <TableCell className="text-center">
                      <ParsedStatusIcon isParsed={f.parsed} isProcessInApi={f.processInApi} />
                    </TableCell>
                    <TableCell title={fileName}>{fileName}</TableCell>
                    <TableCell className="text-center">
                      <DownloadIcon href={f.link} />
                    </TableCell>
                  </TableRow>
                );
              })}
            <EmptyTableMessage collection={files} message="No hay documentos." ready={!loading} />
          </tbody>
        </Table>
      </FilesTable>
    </>
  );
};

export default CredentialDocuments;
