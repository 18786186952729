import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'shards-react';
import PropTypes from 'prop-types';
import Translator from '../../../services/Translator';

const UserDetails = ({ user }) => (
  <React.Fragment>
    <Card className="mb-12" small>
      <CardHeader className="border-bottom">
        <h6 className="m-0">Usuario</h6>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg="3" sm="6" xs="12">
            Nombre
          </Col>
          <Col lg="9" sm="6" xs="12">
            {user.firstName}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg="3" sm="6" xs="12">
            Apellido
          </Col>
          <Col lg="9" sm="6" xs="12">
            {user.lastName}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg="3" sm="6" xs="12">
            Email
          </Col>
          <Col lg="9" sm="6" xs="12">
            {user.email}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg="3" sm="6" xs="12">
            Cuenta
          </Col>
          <Col className="Capitalize FlexAlignCenter" lg="9" sm="6" xs="12">
            <Link to={`/admin/accounts/${user.accountId}`}>{user.account.name}</Link>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg="3" sm="6" xs="12">
            Rol/Permisos
          </Col>
          <Col className="Capitalize FlexAlignCenter" lg="9" sm="6" xs="12">
            {Translator.userRole(user.role)}
          </Col>
        </Row>
        <hr />
      </CardBody>
      <CardFooter />
    </Card>
  </React.Fragment>
);

UserDetails.propTypes = {
  user: PropTypes.object
};

export default UserDetails;
