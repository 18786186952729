import React from 'react';
import Spinner from './Spinner';
import { Button } from 'shards-react';
import PropTypes from 'prop-types';
import { Consumer as LoadingConsumer } from '../../../contexts/LoadingContext';

const RegularButton = ({ onClick, displayText }) => (
  <Button onClick={onClick} theme="secondary">
    <LoadingConsumer>{(value) => (value ? <Spinner /> : displayText)}</LoadingConsumer>
  </Button>
);

RegularButton.propTypes = {
  displayText: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

RegularButton.defaultProps = {
  displayText: 'Buscar'
};

export default RegularButton;
