import React from 'react';
import { Container, Row } from 'shards-react';

import PageTitle from '../../components/PageTitle';

const AdminDashboard = () => (
  <Container className="main-content-container px-4" fluid>
    <Row className="page-header py-4" noGutters>
      <PageTitle className="text-sm-left mb-3" subtitle="Increase" title="Admin" />
    </Row>
  </Container>
);

export default AdminDashboard;
