import React from 'react';
import { Progress } from 'shards-react';
import styled from 'styled-components';
import { InputLabel, Label } from '@increase/typed-components';

import { color } from '../../../../utils/theme';

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0.95rem;
  right: 0.95rem;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    width: 50%;
  }
  ${InputLabel} {
    margin-bottom: 0;
  }
  .progress-bar {
    height: 10px;
    border-radius: 5px;
    box-shadow: none;
    background: ${color('brandWhite', 'medium')};
  }
`;

const UploadingFile = ({ uploading, filesProgress }) => {
  if (!uploading) {
    return null;
  }
  return (
    <StyledContainer>
      <div className="message">
        <Label className="mb-1">Subiendo documentos</Label>
        {filesProgress &&
          Object.entries(filesProgress).map(([name, progress]) => {
            return (
              <div key={name}>
                <InputLabel>{name}</InputLabel>;
                <Progress className="mt-1 progress-bar" theme="primary" value={progress} />
              </div>
            );
          })}
      </div>
    </StyledContainer>
  );
};

export default UploadingFile;
