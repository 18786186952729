const navItems = [
  {
    name: 'Cuentas',
    to: '/admin/accounts',
    htmlBefore: '<i class="material-icons">account_box</i>',
    hasAggregatedTabs: true,
    tabs: [
      {
        name: 'Crear cuenta',
        to: '/admin/accounts/new'
      }
    ]
  },
  {
    name: 'Credenciales',
    to: '/admin/credentials',
    htmlBefore: '<i class="material-icons">lock</i>',
    hasAggregatedTabs: true,
    tabs: [
      {
        name: 'Crear credencial',
        to: '/admin/credentials/new'
      }
    ]
  },
  {
    name: 'Establecimientos',
    to: '/admin/establishments',
    htmlBefore: '<i class="material-icons">business</i>',
    hasAggregatedTabs: true,
    tabs: [
      {
        name: 'Crear Establecimiento',
        to: '/admin/establishments/new'
      },
      {
        name: 'Subir excel',
        to: '/admin/establishments/batch'
      }
    ]
  },
  {
    name: 'Shops',
    to: '/admin/shops',
    htmlBefore: '<i class="material-icons">shopping_cart</i>',
    hasAggregatedTabs: true,
    tabs: [
      {
        name: 'Crear Shop',
        to: '/admin/shops/new'
      }
    ]
  },
  {
    name: 'Usuarios',
    to: '/admin/users',
    htmlBefore: '<i class="material-icons">people</i>',
    hasAggregatedTabs: true,
    tabs: [
      {
        name: 'Crear usuario',
        to: '/admin/users/new'
      }
    ]
  },
  {
    name: 'Liquidaciones',
    to: '/admin/payments',
    htmlBefore: '<i class="material-icons">description</i>'
  },
  {
    name: 'Soporte',
    to: '/support',
    htmlBefore: '<i class="material-icons">contact_phone</i>',
    hasAggregatedTabs: true,
    tabs: [
      {
        name: 'Comparador mensual',
        to: '/support/monthly_summary'
      },
      {
        name: 'Job Tracker',
        to: '/support/job_tracker'
      },
      {
        name: 'Reparseos',
        to: '/support/reparse'
      },
      {
        name: 'Corrección de focus',
        to: '/support/refocus'
      },
      {
        name: 'Scraper Manual',
        to: '/support/manual_scraper'
      },
      {
        name: 'Fetch Establishments',
        to: '/support/fetch_establishments'
      }
    ]
  }
];

export default navItems;
