import styled from 'styled-components';

import { colorSystem } from '../../../../utils/theme';

const StatusBadge = styled.div`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background: ${colorSystem};
  border-radius: 30%;
`;

export default StatusBadge;
