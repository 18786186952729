import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Constants from '../../../data/Constants';

const Info = ({ payment }) => (
  <table className="table mb-0 table-hover table-borderless">
    <tbody>
      <tr>
        <td>ID</td>
        <td>{payment.id || ''}</td>
      </tr>
      <tr>
        <td>Fecha de creación</td>
        <td>{payment.createdAt || ''}</td>
      </tr>
      <tr>
        <td>Fecha de pago</td>
        <td>{payment.paymentDate || ''}</td>
      </tr>
      <tr>
        <td>Fecha de presentación</td>
        <td>{payment.presentationDate || ''}</td>
      </tr>
      <tr>
        <td>Proveedor de información</td>
        <td className="FlexAlignCenter">
          <img
            alt={payment.infoProvider}
            className="MarginLeft10 MaxHeight50 MaxWidth100"
            src={Constants.providerLogos[payment.infoProvider]}
          />
        </td>
      </tr>
      <tr>
        <td>Tarjeta</td>
        <td>{payment.cardBrandId || ''}</td>
      </tr>
      <tr>
        <td>Tipo de documento</td>
        <td>{payment.documentType || ''}</td>
      </tr>
      <tr>
        <td>Número de liquidación</td>
        <td>{payment.clearingNumber || ''}</td>
      </tr>
      <tr>
        <td>ID de establecimiento</td>
        <td>
          {payment.establishment ? (
            <Link to={`/admin/establishments/${payment.establishment.id}`}>
              {payment.establishment.id}
            </Link>
          ) : (
            ''
          )}
        </td>
      </tr>
      <tr>
        <td>Número de establecimiento</td>
        <td>
          {payment.establishment ? (
            <Link to={`/admin/establishments/${payment.establishment.id}`}>
              {payment.establishment.number}
            </Link>
          ) : (
            ''
          )}
        </td>
      </tr>
    </tbody>
  </table>
);

Info.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    paymentDate: PropTypes.string,
    presentationDate: PropTypes.string,
    infoProvider: PropTypes.string,
    cardBrandId: PropTypes.string,
    documentType: PropTypes.string,
    clearingNumber: PropTypes.string,
    establishment: PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.string
    })
  }).isRequired
};

export default Info;
