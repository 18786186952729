import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import NoResult from '../../common/NoResult';
import Paginate from '../../Paginate';

const renderNoResult = (
  <td>
    <NoResult />
  </td>
);

const emptyHeader = (
  <th className="border-0" scope="col">
    ...
  </th>
);

const renderHeader = (headers) => {
  return headers.map((header) => (
    <th className="border-0" key={header.value} scope="col">
      {header.label}
    </th>
  ));
};

const renderLinkData = (header, model) => {
  const redirect = header.redirect.replace('$', model.id);
  return (
    <td>
      <Link to={redirect}>{model[header.value] || header.label}</Link>
    </td>
  );
};

const renderDataColumns = (headers, data) => {
  return data.map((model) => (
    <tr key={model.key}>
      {headers.map((header) => {
        return header.hasLink ? renderLinkData(header, model) : <td>{model[header.value]}</td>;
      })}
    </tr>
  ));
};

class TableWithPagination extends React.Component {
  render() {
    const { headers, data, handlePageClick } = this.props;
    return (
      <Fragment>
        <table className="table mb-0 table-hover table-borderless TableWrap">
          <thead>
            <tr>{headers.length ? renderHeader(headers) : emptyHeader}</tr>
          </thead>
          <tbody>{data.length ? renderDataColumns(headers, data) : renderNoResult}</tbody>
        </table>
        <Paginate onPageChange={handlePageClick} />
      </Fragment>
    );
  }
}

// HEADERS SHOULD BE THIS SHAPE
// this.headers = [
//   { value: 'id', label: 'ID', hasLink: false },
//   { value: 'number', label: 'Numero', hasLink: true, redirect: '/admin/establishments/$' },
// ];

TableWithPagination.propTypes = {
  data: PropTypes.array,
  handlePageClick: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.string)
};

export default TableWithPagination;
