import React from 'react';
import { CellText, InputLabel } from '@increase/typed-components';
import styled from 'styled-components';
import { Progress } from 'shards-react';

import Card from '../common/Card';
import { formatDateTime, isToday } from '../../../../utils/manualScraper';
import { color } from '../../../../utils/theme';

const ProgressBar = styled(Progress)`
  &.progress {
    background: ${color('brandWhite', 'light')};
    border-radius: 9px;
    box-shadow: none;
  }

  .progress-bar {
    background-color: ${color('eter', 'medium')} !important;
  }
`;

const CredentialManagement = ({ children, className, date, progress }) => {
  return (
    <Card className={className}>
      <CellText className="card-header pt-1 pb-2" weight="bold">
        {isToday(date) ? 'Fecha de hoy' : formatDateTime(date, 'medium')}
      </CellText>
      <div>
        <div className="d-flex">
          <InputLabel className="mb-0">
            {progress.ok}/{progress.total} Credenciales
          </InputLabel>
          <InputLabel className="mb-0 ml-auto">{progress.percentage}%</InputLabel>
        </div>
        <ProgressBar className="my-1" value={progress.percentage} />
      </div>
      {children}
    </Card>
  );
};

export default CredentialManagement;
