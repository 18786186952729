import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const CredentialService = async () => {
  const gqlClient = GraphqlClient();

  const listQuery = gql`
    query Credentials($filter: scp_CredentialsFilter, $limit: Int, $offset: Int) {
      credentials: scp_credentials(filter: $filter, limit: $limit, offset: $offset) {
        id
        username
        country
        infoProvider
        status
        statusCode
        externalAccountId
        status
        electronic
        account {
          id
          name
        }
      }
    }
  `;

  const getQuery = gql`
    query Credential($id: String!) {
      credential: scp_credential(id: $id) {
        id
        username
        password
        otherLogin
        country
        infoProvider
        blockedAt
        deletedAt
        externalAccountId
        electronic
        status
        electronicCheckbox
        expectedDocuments
        statusCode
        establishments {
          id
          number
        }
        account {
          id
          name
        }
        metadata {
          folder
        }
      }
    }
  `;

  const createMutation = gql`
    mutation Create($params: scp_CredentialAttributes!) {
      operation: scp_credentialCreate(params: $params) {
        credential {
          id
        }
        errors
      }
    }
  `;

  const createApiMutation = gql`
    mutation Create($params: api_CredentialAttributes!) {
      operation: api_credentialCreate(params: $params) {
        credential {
          id
        }
        errors
      }
    }
  `;

  const updateMutation = gql`
    mutation Update($params: scp_CredentialAttributes!, $id: String!) {
      operation: scp_credentialUpdate(params: $params, id: $id) {
        credential {
          id
        }
        errors
      }
    }
  `;

  const updateEstablishmentsMutation = gql`
    mutation UpdateCredentialEstablishments($params: api_CredentialEstablishmentsAttributes!) {
      operation: api_updateCredentialEstablishments(params: $params) {
        credentialId
        establishments
        errors
      }
    }
  `;

  const firstDataNewToggleElectronic = gql`
    mutation firstDataNewToggleElectronic($id: String!, $enableElectronic: Boolean!) {
      operation: scp_credentialToggleElectronic(id: $id, enableElectronic: $enableElectronic) {
        credential {
          id
          electronicCheckbox
        }
      }
    }
  `;

  const getDocumentsToManualScraperQuery = gql`
    query DocumentsToManualScraper {
      result: scp_documentsToManualScraper {
        documents
      }
    }
  `;

  const fetchEstablishment = gql`
    mutation Update($credentialId: String!) {
      update: scp_fetchEstablishments(credentialId: $credentialId) {
        establishments {
          number
        }
        errors
      }
    }
  `;

  return {
    list: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: listQuery,
          variables: { filter: filter, limit, offset }
        })
        .then((data) => data.data.credentials)
        .catch((error) => {
          throw error;
        });
    },
    get: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getQuery,
          variables: { id }
        })
        .then((data) => data.data.credential)
        .catch((error) => {
          throw error;
        });
    },
    update: (params) => {
      return gqlClient
        .mutate({
          mutation: updateMutation,
          variables: {
            params: {
              username: params.username,
              password: params.password,
              otherLogin: params.otherLogin,
              country: params.country,
              infoProvider: params.infoProvider,
              status: params.status,
              externalAccountId: params.externalAccountId,
              metadata: params.metadata,
              ftpKey: params.ftpKey,
              expectedDocuments: params.expectedDocuments
            },
            id: params.id
          }
        })
        .then((data) => {
          if (data.data.operation.errors.length) {
            return data.data.operation;
          }
          return gqlClient
            .mutate({
              mutation: updateEstablishmentsMutation,
              variables: {
                params: {
                  credentialId: data.data.operation.credential.id,
                  establishmentIds: params.establishmentIds
                }
              }
            })
            .then((data) => data.data.operation);
        })
        .catch((error) => {
          throw error;
        });
    },
    firstDataNewToggleElectronic: (id, enableElectronic) => {
      return gqlClient
        .mutate({
          mutation: firstDataNewToggleElectronic,
          variables: {
            id: id,
            enableElectronic: enableElectronic
          }
        })
        .then((data) => data.data.operation);
    },
    create: (params) => {
      return gqlClient
        .mutate({
          mutation: createMutation,
          variables: {
            params: {
              username: params.username,
              password: params.password,
              otherLogin: params.otherLogin,
              country: params.country,
              infoProvider: params.infoProvider,
              status: params.status,
              externalAccountId: params.externalAccountId,
              metadata: params.metadata,
              ftpKey: params.ftpKey,
              expectedDocuments: params.expectedDocuments
            }
          }
        })
        .then((data) => {
          if (data.data.operation.errors.length) {
            return data.data.operation;
          }
          return gqlClient
            .mutate({
              mutation: createApiMutation,
              variables: {
                params: {
                  id: data.data.operation.credential.id,
                  username: params.username,
                  infoProvider: params.infoProvider,
                  accountId: params.externalAccountId
                }
              }
            })
            .then((data) => {
              if (!params.establishmentIds) {
                data.data.operation.credentialId = data.data.operation.credential.id;
                return data.data.operation;
              }
              return gqlClient.mutate({
                mutation: updateEstablishmentsMutation,
                variables: {
                  params: {
                    credentialId: data.data.operation.credential.id,
                    establishmentIds: params.establishmentIds
                  }
                }
              });
            })
            .then((data) => data.data.operation)
            .catch((error) => {
              throw error;
            });
        });
    },
    getDocumentsToManualScraper: () => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: getDocumentsToManualScraperQuery
        })
        .then((data) => {
          return data.data.result.documents;
        })
        .catch((error) => {
          throw error;
        });
    },
    fetchEstablishment: (credentialId) => {
      return gqlClient
        .mutate({
          mutation: fetchEstablishment,
          variables: {
            credentialId
          }
        })
        .then((data) => data.data)
        .catch((error) => {
          throw error;
        });
    }
  };
};

export default CredentialService;
