import React, { useContext } from 'react';
import Component from 'react-select/lib/Async';
import { ThemeContext } from 'styled-components';

import { styles } from './Select';

const AsyncSelect = (props) => {
  const theme = useContext(ThemeContext);

  return <Component {...props} styles={styles(theme)} />;
};

export default AsyncSelect;
