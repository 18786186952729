import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { InlineText } from '@increase/typed-components';
import classNames from 'classnames';

import { color } from '../../../utils/theme';

const defaultSize = '0.75rem';

const PasswordInput = styled(InlineText)`
  color: ${color('brandBlack', 'light')};
  font-size: inherit;
`;

const PasswordContainer = styled.div`
  display: inline-flex;
  font-size: ${({ fontSize }) => fontSize || defaultSize};
`;

const IconContainer = styled.button`
  background: transparent;
  padding: 0;
  border: none;
  text-align: center;
  &:focus {
    outline: none;
  }
`;

const Password = ({ password, className }) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordPlaceholder = useMemo(() => {
    return password.replace(/./g, '*');
  }, [password]);

  const toggle = () => {
    setShowPassword((val) => !val);
  };

  const iconClases = classNames('fas', { 'fa-eye': !showPassword, 'fa-eye-slash': showPassword });
  return (
    <PasswordContainer className={className}>
      <PasswordInput weight="500">{showPassword ? password : passwordPlaceholder}</PasswordInput>

      <IconContainer className="ml-2" onClick={toggle}>
        <i className={iconClases} />
      </IconContainer>
    </PasswordContainer>
  );
};

export default Password;
