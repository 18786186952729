import gql from 'graphql-tag';
import GraphqlClient from './GraphqlClients';

const SchedulerService = async () => {
  const gqlClient = GraphqlClient();

  const getDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + 1
    );

    const endDate2 = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1);

    while (currentDate <= endDate2) {
      dates.push(currentDate.toISOString().slice(0, 10));
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      );
    }
    return dates;
  };

  const jobProposal = gql`
    query jobProposal($params: SchedulerApiParams) {
      proposeScrappingJob(params: $params) {
        requestUuid
        credentialId
        establishmentNumbers
      }
    }
  `;

  const jobsQuery = gql`
    query getJobs($requestId: String!) {
      jobs: getJobsByRequestId(requestId: $requestId) {
        jobs {
          job_id
          document_type
          searching_date
          establishment_number
          status
          origin
          origin_detail
          wait_until
          description
          ever_found
          last_runned_at
          updated_at
        }
      }
    }
  `;

  const jobsHistory = gql`
    query jobHistory($jobId: String!) {
      history: getJobHistory(jobId: $jobId) {
        from_status
        to_status
        updated_at
        origin
        origin_detail
      }
    }
  `;

  return {
    scheduleScrapping: (args) => {
      const dateRange = getDatesBetween(new Date(args.startDate), new Date(args.endDate));
      return gqlClient
        .query({
          query: jobProposal,
          variables: {
            params: {
              credentialExternalId: args.credentialId,
              downloadDates: dateRange,
              documentTypes: args.documentTypes,
              establishmentNumbers: args.establishmentNumbers,
              originDetail: args.originDetail
            }
          }
        })
        .then((data) => {
          return data.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getJobs: (params) => {
      return gqlClient
        .query({
          query: jobsQuery,
          variables: {
            requestId: params.requestId
          }
        })
        .then((data) => {
          return data.data.jobs;
        })
        .catch((error) => {
          return error;
        });
    },
    getJobHistory: (jobId) => {
      return gqlClient
        .query({
          query: jobsHistory,
          variables: {
            jobId: jobId
          }
        })
        .then((data) => {
          return data.data.history;
        })
        .catch((error) => {
          return error;
        });
    }
  };
};

export default SchedulerService;
