import React from 'react';
import Select from 'react-select';
import Constants from '../../data/Constants';
import { Flex } from '../../components/common/Flex';
import PropTypes from 'prop-types';

class MonthAndYearInput extends React.Component {
  componentDidMount() {
    this.handleChange({ month: this.defaultMonth().value });
    this.handleChange({ year: this.defaultYear().value });
  }

  defaultMonth = () => Constants.months[(this.currentDate().getMonth() + 11) % 12];

  defaultYear = () => this.yearOptions()[2];

  yearOptions() {
    const year = this.currentYear();
    return [-2, -1, 0, 1].map((offset) => {
      const value = year + offset;

      return { value: value, label: value };
    });
  }

  currentDate = () => new Date();

  currentYear = () => this.currentDate().getFullYear();

  handleChange = (object) => this.props.handleInputChange(object);

  render() {
    return (
      <Flex childrenFlex="1" gap="10px">
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          defaultValue={this.defaultMonth()}
          name="monthInput"
          onChange={(e) => {
            this.handleChange({ month: e.value });
          }}
          options={Constants.months}
          placeholder="Mes"
        />
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          defaultValue={this.defaultYear()}
          name="YearInput"
          onChange={(e) => {
            this.handleChange({ year: e.value });
          }}
          options={this.yearOptions()}
          placeholder="Año"
        />
      </Flex>
    );
  }
}

MonthAndYearInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default MonthAndYearInput;
