import React from 'react';
import { Button } from 'shards-react';

const logOut = () => {
  window.location.assign('https://auth.increase.app/logout');
};

const SidebarLogOut = () => {
  return (
    <div className="Center">
      <Button onClick={logOut} outline pill>
        Cerrar Sesión
      </Button>
    </div>
  );
};

export default SidebarLogOut;
