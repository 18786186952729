import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import styled from 'styled-components';
import { Button } from 'shards-react';
import { Consumer as LoadingConsumer } from '../../../contexts/LoadingContext';

const SubmitButton = ({ children }) => (
  <ButtonFillParent theme="outline-secondary" type="submit">
    <LoadingConsumer>
      {(value) =>
        value ? (
          <Spinner />
        ) : (
          children || (
            <em className="material-icons" style={{ fontSize: 25 }}>
              search
            </em>
          )
        )
      }
    </LoadingConsumer>
  </ButtonFillParent>
);

SubmitButton.propTypes = {
  children: PropTypes.node
};

const ButtonFillParent = styled(Button)`
  && {
    padding: 0;
  }
`;

export default SubmitButton;
