import React from 'react';
import { Container, Row, Col } from 'shards-react';
import PageTitle from '../../../components/PageTitle';
import UserService from '../../../services/UserService';
import Errors from '../../../components/Errors';
import UserDetails from './UserDetails';
import UserShops from './UserShops';
import UserEstablishments from './UserEstablishments';
import UserInvisibleEstablishments from './UserInvisibleEstablishments';

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      errors: []
    };
  }

  componentDidMount() {
    this.findUser(this.props.match.params.id);
  }

  findUser = async (id) => {
    const userService = await UserService();
    userService.findUser(id).then((user) => {
      if (user === null) {
        this.setState({ errors: ['El Usuario es de otro pais, cambie el tenant'] });
      } else {
        this.setState({ user: user });
      }
    });
  };

  render() {
    const { user } = this.state;
    if (user === null) return null;
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Usuarios" />
        </Row>
        <Row>
          <Errors errors={this.state.errors} />
        </Row>
        <Row>
          <Col md="5">
            <UserDetails user={user} />
          </Col>
          <Col md="7">
            <UserShops user={user} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="5">
            <UserEstablishments user={user} />
          </Col>
          <Col md="7">
            <UserInvisibleEstablishments user={user} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Show;
