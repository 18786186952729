import { TableRow } from '@increase/typed-components';
import styled from 'styled-components';

import { color } from '../../../../utils/theme';

const SelectableTableRow = styled(TableRow)`
  &:hover {
    background: ${color('brandWhite', 'medium')};
    cursor: pointer;
  }
`;

export default SelectableTableRow;
