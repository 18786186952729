import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'shards-react';

const Totals = ({ payment, tickOrCross }) => (
  <Row>
    <Col md="6">
      <table className="table mb-0 table-hover table-borderless">
        <tbody>
          <tr>
            <td>Balanceada</td>
            <td>{tickOrCross(payment.isBalanced)}</td>
          </tr>
          <tr>
            <td>Moneda</td>
            <td>{payment.currency}</td>
          </tr>
          <tr>
            <td>Opening balance</td>
            <td>{payment.openingBalance}</td>
          </tr>
          <tr>
            <td>Closing balance</td>
            <td>{payment.closingBalance}</td>
          </tr>
        </tbody>
      </table>
    </Col>
    <Col md="6">
      <table className="table mb-0 table-hover table-borderless">
        <tbody>
          <tr>
            <td>Total presentado</td>
            <td>{payment.totalAmount}</td>
          </tr>
          <tr>
            <td>Total descontado</td>
            <td>{payment.totalDeduction}</td>
          </tr>
          <tr>
            <td>Total neto</td>
            <td>{payment.totalEarn}</td>
          </tr>
        </tbody>
      </table>
    </Col>
  </Row>
);

Totals.propTypes = {
  payment: PropTypes.shape({
    isBalanced: PropTypes.string,
    currency: PropTypes.string,
    openingBalance: PropTypes.number,
    closingBalance: PropTypes.number,
    totalAmount: PropTypes.number,
    totalDeduction: PropTypes.number,
    totalEarn: PropTypes.number
  }).isRequired,
  tickOrCross: PropTypes.func.isRequired
};

export default Totals;
