import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from 'shards-react';

import PageTitle from '../../../components/PageTitle';
import AccountService from '../../../services/AccountService';
import Errors from '../../../components/Errors';
import { getCurrentCountry } from '../../../utils/admin';
import { Can } from '../../../permissions/helper';

class Editor extends React.Component {
  constructor(props) {
    super(props);

    const accountState = {
      id: props.accountId,
      name: '',
      taxId: '',
      salesforceAccountId: ''
    };

    this.state = {
      account: accountState,
      redirect: null
    };
  }
  async componentDidMount() {
    if (!this.props.isNew) {
      const accountService = await AccountService();
      accountService.get(this.state.account.id).then((data) => {
        this.setState({ account: data });
      });
    }
  }

  updateAccount = async (params) => {
    const accountService = await AccountService();
    return accountService.update(this.state.account.id, params);
  };

  createAccount = async (params) => {
    const accountService = await AccountService();
    return accountService.create(params);
  };

  handleSubmit = (account) => {
    const func = this.props.isNew ? this.createAccount : this.updateAccount;
    const params = {
      name: account.name,
      taxId: account.taxId,
      salesforceAccountId: account.salesforceAccountId,
      country: getCurrentCountry()
    };
    func(params).then((data) => {
      if ((data.errors || []).length > 0) {
        this.setState({ errors: data.errors });
      } else {
        this.setState({ redirect: data.account.id });
      }
    });
  };

  render() {
    const cardTitle = this.props.isNew ? 'Crear' : 'Editar';
    const buttonText = this.props.isNew ? 'Crear cuenta' : 'Editar cuenta';

    return this.state.redirect === null ? (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Cuentas" />
        </Row>
        <Row>
          <Col>
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">{cardTitle}</h6>
              </CardHeader>
              <CardBody>
                <Errors errors={this.state.errors} />
                <Formik enableReinitialize initialValues={this.state.account}>
                  {(props) => {
                    const { values, isSubmitting, handleChange, handleBlur } = props;

                    return (
                      <Form>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="name">Nombre</label>
                            <FormInput
                              id="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Nombre"
                              type="text"
                              value={values.name}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="taxId">CUIT</label>
                            <FormInput
                              id="taxId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="CUIT"
                              type="text"
                              value={values.taxId}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="salesforceAccountId">Salesforce ID</label>
                            <FormInput
                              id="salesforceAccountId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Salesforce ID"
                              type="text"
                              value={values.salesforceAccountId}
                            />
                          </Col>
                        </Row>
                        <Button
                          className="btn-primary"
                          disabled={isSubmitting}
                          onClick={() => this.handleSubmit(values)}
                        >
                          {buttonText}
                        </Button>
                        <Can action="acc:migrate">
                          <Button>MIGRAR CUEENTA</Button>
                        </Can>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    ) : (
      <Redirect to={`/admin/accounts/${this.state.redirect}`} />
    );
  }
}

Editor.defaultProps = {
  isNew: true
};

Editor.propTypes = {
  accountId: PropTypes.string,
  isNew: PropTypes.bool
};

export default Editor;
