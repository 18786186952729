import React from 'react';
import Paginate from '../../../components/Paginate';
import { Link } from 'react-router-dom';
import NoResult from '../../../components/common/NoResult';
import PropTypes from 'prop-types';
import Translator from '../../../services/Translator';

const Table = ({ users, onPageClick }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="w-30" scope="col">
            Email
          </th>
          <th className="w-10" scope="col">
            Nombre
          </th>
          <th className="w-10" scope="col">
            Apellido
          </th>
          <th className="w-20" scope="col">
            Cuenta
          </th>
          <th className="w-20" scope="col">
            Rol/Permisos
          </th>
          <th className="w-5" scope="col">
            Ver
          </th>
          <th className="w-5" scope="col">
            Editar
          </th>
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {users.map((model) => {
          return (
            <tr key={model.id}>
              <td>{model.email || '-'}</td>
              <td>{model.firstName || '-'}</td>
              <td>{model.lastName || '-'}</td>
              <td>
                {model.account !== null ? (
                  <Link to={`/admin/accounts/${model.account.id}`}>{model.account.name}</Link>
                ) : (
                  ''
                )}
              </td>
              <td>{Translator.userRole(model.role)}</td>
              <td>
                <Link to={`/admin/users/${model.id}`}>Ver</Link>
              </td>
              <td>
                <Link to={`/admin/users/${model.id}/edit`}>Editar</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    {!users.length ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

Table.propTypes = {
  onPageClick: PropTypes.func,
  users: PropTypes.array
};

export default Table;
