import React, { useState } from 'react';
import { Row, Col, Button, Container, FormInput, FormCheckbox, Badge } from 'shards-react';
import PageTitle from '../../../components/PageTitle';
import DocumentService from '../../../services/DocumentService';
import Translator from '../../../services/Translator';
import {
  Table,
  THead,
  Th,
  Td,
  TableRow,
  TableHeader,
  TableWrapper,
  H3
} from '@increase/typed-components';

export function SupportRefocus() {
  const [documents, setDocuments] = useState('');
  const [focusedForApi, setFocusedForApi] = useState(false);
  const [focusedForPayments, setFocusedForPayments] = useState(false);
  const [focusedForTransactions, setFocusedForTransactions] = useState(false);
  const [focusedForChargebacks, setFocusedForChargebacks] = useState(false);
  const [result, setResult] = useState();

  const handleDocumentsChange = (e) => {
    const value = e.target.value;
    const trimmed = value.replace(/\s/g, '');
    setDocuments(trimmed);
  };

  const onRefocus = () => {
    setResult(undefined);
    const documentsArray = documents.split(',').filter((docId) => docId !== '');
    const focusOptions = {
      focusedForApi: focusedForApi,
      focusedForPayments: focusedForPayments,
      focusedForTransactions: focusedForTransactions,
      focusedForChargebacks: focusedForChargebacks
    };
    refocusDocuments(documentsArray, focusOptions);
  };

  const refocusDocuments = async (documentsArray, focusOptions) => {
    const documentService = await DocumentService();
    documentService.refocus(documentsArray, focusOptions).then((data) => setResult(data));
  };

  const resultHasData = result && Array.isArray(result) && result.length > 0;

  return (
    <Container className="main-content-container px-4" fluid>
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" sm="4" subtitle="" title="Refocus Documents" />
      </Row>
      <Row>
        <Col lg="8" sm="12" xs="12">
          <FormInput
            id="documents"
            onChange={handleDocumentsChange}
            placeholder="Liquidacion(es) ID separados por coma"
            type="text"
            value={documents}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12" sm="12" xs="12">
          <FormCheckbox
            checked={focusedForApi}
            onChange={(e) => setFocusedForApi(e.target.checked)}
          >
            Focused for API
          </FormCheckbox>
        </Col>
      </Row>
      <Row>
        <Col lg="12" sm="12" xs="12">
          <FormCheckbox
            checked={focusedForPayments}
            onChange={(e) => setFocusedForPayments(e.target.checked)}
          >
            Focused for Payments
          </FormCheckbox>
        </Col>
      </Row>
      <Row>
        <Col lg="12" sm="12" xs="12">
          <FormCheckbox
            checked={focusedForTransactions}
            onChange={(e) => setFocusedForTransactions(e.target.checked)}
          >
            Focused for Transactions
          </FormCheckbox>
        </Col>
      </Row>
      <Row>
        <Col lg="12" sm="12" xs="12">
          <FormCheckbox
            checked={focusedForChargebacks}
            onChange={(e) => setFocusedForChargebacks(e.target.checked)}
          >
            Focused for Chargebacks
          </FormCheckbox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button disabled={documents === ''} onClick={onRefocus}>
            Refocus
          </Button>
        </Col>
      </Row>
      {result && (
        <Row>
          <Col>
            <TableWrapper className="MarginTop20">
              <TableHeader>
                <H3>Resultado</H3>
              </TableHeader>
              <Table hasData={resultHasData} noDataMessage="No hay datos" zebra>
                <THead>
                  <tr>
                    <Th>Documento</Th>
                    <Th>Estado</Th>
                  </tr>
                </THead>
                <tbody>
                  {resultHasData &&
                    result.map((documents) => {
                      const statusIsNotFound = documents.status === 'not_found';
                      const statusDescription = Translator.refocusStatus(documents.status);
                      return (
                        <TableRow key={documents.id}>
                          <Td>{documents.id}</Td>
                          <Td>
                            <H3>
                              <Badge pill theme={statusIsNotFound ? 'danger' : 'success'}>
                                {statusDescription}
                              </Badge>
                            </H3>
                          </Td>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
            </TableWrapper>
          </Col>
        </Row>
      )}
    </Container>
  );
}
