import React from 'react';
import styled from 'styled-components';
import { ButtonGroup, Button } from 'shards-react';

import { color } from '../../../../utils/theme';

const Icon = styled.i`
  && {
    font-size: 24px;
    color: ${color('brandBlack', 'light')};
  }
`;

const PagerButton = styled(Button)`
  && {
    border-color: ${color('brandWhite', 'medium')};
    padding: 3px;

    &&:disabled {
      border-color: ${color('brandWhite', 'medium')};
      opacity: 0.4;
      cursor: default;
    }

    &:hover:not(:disabled),
    &&:active:not(:disabled) {
      border-color: ${color('brandWhite', 'medium')};
      background-color: ${color('brandWhite', 'medium')};
    }
  }
`;

export default function Paginate({ className, disabled, onPageChange }) {
  return (
    <Container className={className}>
      <ButtonGroup>
        <PagerButton
          className="mr-1 btn-outline-light"
          disabled={disabled}
          onClick={() => onPageChange(-1)}
          outline
        >
          <Icon className="material-icons">chevron_left</Icon>
        </PagerButton>
        <PagerButton
          className="btn-outline-light"
          disabled={disabled}
          onClick={() => onPageChange(1)}
          outline
        >
          <Icon className="material-icons">chevron_right</Icon>
        </PagerButton>
      </ButtonGroup>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
  text-align: right;
`;
