import styled from 'styled-components';

import { color } from '../../../utils/theme';

const bgColor = (props) => {
  const { status } = props;

  switch (status) {
    case 'in_progress':
      return color('sol', 'light')(props);
    case 'pending':
      return color('brandBlack', 'light')(props);
    default:
      return color('brandWhite', 'bold')(props);
  }
};

const StatusBg = styled.span`
  color: ${bgColor};
  font-size: 0.75rem;
  font-weight: normal;
`;

export default StatusBg;
