import React from 'react';
import PropTypes from 'prop-types';

const Focus = ({ forApi, forPayments, forTransactions, forChargebacks, tickOrCross }) => (
  <table className="table mb-0 table-hover table-borderless">
    <tbody>
      <tr>
        <td>Focuseado para la API (clientes grandes)</td>
        <td>{tickOrCross(forApi)}</td>
      </tr>
      <tr>
        <td>Focuseado para la tab de liquidaciones</td>
        <td>{tickOrCross(forPayments)}</td>
      </tr>
      <tr>
        <td>Focuseado para la tab de transacciones</td>
        <td>{tickOrCross(forTransactions)}</td>
      </tr>
      <tr>
        <td>Focuseado para la tab de contragargos</td>
        <td>{tickOrCross(forChargebacks)}</td>
      </tr>
    </tbody>
  </table>
);

Focus.propTypes = {
  forApi: PropTypes.bool,
  forChargebacks: PropTypes.bool,
  forPayments: PropTypes.bool,
  forTransactions: PropTypes.bool,
  tickOrCross: PropTypes.func.isRequired
};

export default Focus;
