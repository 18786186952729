import React, { useState, useEffect } from 'react';

import CredentialInfo from './CredentialInfo';

const CredentialsList = ({ credentials, onSelect }) => {
  const [selectedId, setSelectedId] = useState(null);
  useEffect(() => {
    const selectedCredential = credentials.find((info) => info.credential.id === selectedId);
    onSelect(selectedCredential && selectedCredential.credential);
  }, [selectedId, credentials, onSelect]);

  // When credentials array change, try to keep the active credential
  useEffect(() => {
    if (selectedId) {
      const selectedCredential = credentials.find((info) => info.credential.id === selectedId);
      setSelectedId(selectedCredential ? selectedCredential.credential.id : null);
    } else if (credentials && credentials.length === 1) {
      setSelectedId(credentials[0].credential.id);
    }
  }, [credentials, selectedId]);
  const items = credentials.map((info, index) => (
    <CredentialInfo
      active={selectedId === info.credential.id}
      info={info}
      key={`${info.credential.id}-${index}`}
      onClick={() => setSelectedId(info.credential.id === selectedId ? null : info.credential.id)}
    />
  ));

  return <div className="mb-3">{items}</div>;
};

export default CredentialsList;
