import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { default as AccountsView } from './manualScraper/AccountsView';
import { default as MonthlyView } from './manualScraper/MonthlyView';
import { default as ProviderView } from './manualScraper/ProviderView';

const ManualScraper = () => (
  <Switch>
    <Route component={AccountsView} exact path="/support/manual_scraper" />
    <Route component={MonthlyView} exact path="/support/manual_scraper/:accountId" />
    <Route
      component={ProviderView}
      exact
      path="/support/manual_scraper/:accountId/:infoProvider/:date"
    />
  </Switch>
);

export default ManualScraper;
