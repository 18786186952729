import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'shards-react';

import PageTitle from '../../../components/PageTitle';
import Focus from './Focus';
import Info from './Info';
import Totals from './Totals';
import Discounts from './Discounts';
import Documents from './Documents';
import SiblingsPayments from './SiblingsPayments';
import MovementsList from './MovementsList';
import DocumentService from '../../../services/DocumentService';
import PaymentService from '../../../services/PaymentService';
import TickOrCross from '../../../utils/TickOrCross';

const reprocessStyleBtn = {
  position: 'absolute',
  right: '10px',
  top: '10px'
};

const reprocessStyleMessageSuccess = {
  position: 'absolute',
  right: '120px',
  top: '17px',
  color: 'green'
};

const reprocessStyleMessageError = {
  position: 'absolute',
  right: '120px',
  top: '17px',
  color: 'red'
};

function Show({ paymentId }) {
  const [loaded, setLoaded] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [payment, setPayment] = useState(null);
  const [siblingsPayments, setSiblingsPayments] = useState([]);
  const [showMovements, setShowMovements] = useState(false);
  const [reprocessing, setReprocessing] = useState(false);
  const [showReprocessingResultMessage, setShowReprocessingResultMessage] = useState(false);
  const [reprocessingResult, setReprocessingResult] = useState(null);
  const [reprocessingResultMessage, setReprocessingResultMessage] = useState(null);

  const queryParsedDocument = useCallback(
    async (payment) => {
      if (payment === null) {
        return;
      }
      const documentService = await DocumentService();
      const res = await documentService.getParsedDocuments([payment.parsedDocumentExtId]);
      if (res && res.length) {
        const newDocument = documentObject(res[0], 'Documento parseado');
        const newDocuments = [...documents, newDocument];
        setDocuments(newDocuments);
      }
    },
    [documents]
  );

  const queryNextScrapedDocument = useCallback(
    async (documentId) => {
      const documentService = await DocumentService();
      let document = await documentService.getScrapedDocument(documentId);
      if (document !== null) {
        let text = '';
        if (document.requiresTransformation) {
          document = await documentService.getScrapedDocument(document.relatedDocumentId);
          text = 'Documento transformado';
        } else {
          text = 'Documento original';
        }
        const newDocument = documentObject(document, text);
        const newDocuments = [...documents, newDocument];
        setDocuments(newDocuments);
      }
    },
    [documents]
  );

  function documentObject(document, text) {
    const link = document.link;
    const splitted = link.split('/');
    const fileName = splitted[splitted.length - 1].split('?')[0];
    return {
      link: link,
      linkKey: fileName,
      show: text
    };
  }

  function toggleShowMovements() {
    setShowMovements(!showMovements);
  }

  async function reprocess(id) {
    const paymentService = await PaymentService();
    const data = await paymentService.refocus(id);
    setReprocessing(false);
    setReprocessingResult(data.result);
    setShowReprocessingResultMessage(true);
    setReprocessingResultMessage(data.result ? 'Reproceso Satisfactorio' : 'Error en el reproceso');
  }

  useEffect(() => {
    const fetchPayment = async () => {
      const paymentService = await PaymentService();
      const payment = await paymentService.get(paymentId);
      setPayment(payment);
      const siblings = await paymentService.getSiblings(paymentId);
      setSiblingsPayments(siblings);
    };
    fetchPayment();
  }, [paymentId]);

  useEffect(() => {
    if (!loaded && payment && payment.scrapedDocumentExtId) {
      if (!documents.length) {
        queryParsedDocument(payment);
      }
      if (documents.length) {
        queryNextScrapedDocument(payment.scrapedDocumentExtId);
        setLoaded(true);
      }
    }
  }, [payment, documents, loaded, queryNextScrapedDocument, queryParsedDocument]);

  if (payment === null) return null;

  return (
    <Container className="main-content-container px-4" fluid>
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" sm="4" subtitle="" title="Liquidación" />
      </Row>
      <Row>
        <Col md="6">
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Información</h6>
            </CardHeader>
            <CardBody>{loaded && <Info payment={payment} />}</CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Foco</h6>
              {showReprocessingResultMessage && (
                <div
                  className="mr-1"
                  style={
                    reprocessingResult ? reprocessStyleMessageSuccess : reprocessStyleMessageError
                  }
                >
                  {reprocessingResultMessage}
                </div>
              )}
              <Button
                className="mb-2 mr-1"
                disabled={reprocessing}
                onClick={() => {
                  setReprocessing(true);
                  reprocess(payment.id);
                }}
                size="sm"
                style={reprocessStyleBtn}
                theme="primary"
              >
                {reprocessing ? 'Procesando...' : 'Reprocesar'}
              </Button>
            </CardHeader>
            <CardBody>
              {loaded && (
                <Focus
                  forApi={payment.focusedForApi}
                  forChargebacks={payment.focusedForChargebacks}
                  forPayments={payment.focusedForPayments}
                  forTransactions={payment.focusedForTransactions}
                  tickOrCross={TickOrCross}
                />
              )}
            </CardBody>
          </Card>
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Totales</h6>
            </CardHeader>
            <CardBody>{loaded && <Totals payment={payment} tickOrCross={TickOrCross} />}</CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Liquidaciones Hermanas</h6>
            </CardHeader>
            <CardBody>
              {loaded && <SiblingsPayments siblingsPayments={siblingsPayments} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Documentos</h6>
            </CardHeader>
            <CardBody>{loaded && <Documents documents={documents} />}</CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="mb-4" small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Descuentos</h6>
            </CardHeader>
            <CardBody>{loaded && <Discounts discounts={payment.discounts} />}</CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {showMovements ? (
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Movimientos</h6>
              </CardHeader>
              <CardBody>
                <MovementsList movements={payment.movements} />
              </CardBody>
              <CardFooter>
                <Button
                  className="mb-2 mr-1"
                  onClick={toggleShowMovements}
                  size="sm"
                  theme="primary"
                >
                  Ocultar movimientos
                </Button>
              </CardFooter>
            </Card>
          ) : (
            <Button className="mb-2 mr-1" onClick={toggleShowMovements} size="sm" theme="primary">
              Ver movimientos
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

Show.propTypes = {
  paymentId: PropTypes.string.isRequired
};

export default Show;
