import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const UserService = async () => {
  const gqlClient = GraphqlClient();

  const listUsersQuery = gql`
    query Users($filter: api_UserFilter, $limit: Int, $offset: Int) {
      users: api_users(filter: $filter, limit: $limit, offset: $offset) {
        id
        firstName
        lastName
        email
        accountId
        account {
          id
          name
        }
      }
    }
  `;

  const findUserQuery = gql`
    query Users($id: String!) {
      user: api_user(id: $id) {
        id
        firstName
        lastName
        email
        role
        accountId
        account {
          id
          name
        }
        establishments {
          id
          number
          shop {
            id
            name
          }
        }
        invisibleEstablishments {
          id
          number
          shop {
            id
            name
          }
        }
        shops {
          id
          name
        }
      }
    }
  `;

  const createUserQuery = gql`
    mutation Create($params: plt_UserAttributes!) {
      operation: plt_userCreate(params: $params) {
        user {
          id
        }
        errors
      }
    }
  `;

  const updateUserQuery = gql`
    mutation UpdateUser($id: String!, $params: plt_UserAttributes!) {
      user: plt_userUpdate(id: $id, params: $params) {
        user {
          id
          firstName
          lastName
          email
          accountId
          account {
            id
            name
          }
        }
        errors
      }
    }
  `;

  const updateCardUser = gql`
    mutation UserUpdate($id: String!, $params: api_UserAttributes!) {
      update: api_userUpdate(id: $id, params: $params) {
        user {
          role
        }
        errors
      }
    }
  `;

  return {
    listUsers: (filter, limit, offset) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: listUsersQuery,
          variables: { filter: filter, limit, offset }
        })
        .then((data) => {
          return data.data.users;
        })
        .catch((error) => {
          throw error;
        });
    },
    findUser: (id) => {
      return gqlClient
        .query({
          fetchPolicy: 'network-only',
          query: findUserQuery,
          variables: { id }
        })
        .then((data) => {
          return data.data.user;
        })
        .catch((error) => {
          throw error;
        });
    },
    createUser: (params) => {
      return gqlClient
        .mutate({
          mutation: createUserQuery,
          variables: { params }
        })
        .then((data) => {
          return data.data.user;
        })
        .catch((error) => {
          throw error;
        });
    },
    updateUser: (params) => {
      return gqlClient
        .mutate({
          mutation: updateUserQuery,
          variables: {
            params: {
              firstName: params.firstName,
              lastName: params.lastName
            },
            id: params.id
          }
        })
        .then((data) => {
          return data.data.user;
        })
        .catch((error) => {
          throw error;
        });
    },
    updateCardUser: (params) => {
      return gqlClient.mutate({
        mutation: updateCardUser,
        variables: {
          params: {
            role: params.role
          },
          id: params.id
        }
      });
    }
  };
};

export default UserService;
