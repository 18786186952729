import React from 'react';
import Paginate from '../../../components/Paginate';
import { Link } from 'react-router-dom';
import NoResult from '../../../components/common/NoResult';
import PropTypes from 'prop-types';

const Table = ({ shops, onPageClick }) => (
  <React.Fragment>
    <table className="table table-hover text-center table-fixed MarginTop20">
      <thead className="thead-dark">
        <tr>
          <th className="w-30" scope="col">
            Nombre
          </th>
          <th className="w-20" scope="col">
            Account
          </th>
          <th className="w-10" scope="col" />
          <th className="w-10" scope="col" />
        </tr>
      </thead>
      <tbody className="BackgroundWhite">
        {shops &&
          shops.map(({ id, name, account }) => {
            return (
              <tr key={id}>
                <td>{name}</td>
                <td>
                  <Link to={`/admin/accounts/${account.id}`}>{account.name}</Link>
                </td>
                <td>
                  <Link to={`/admin/accounts/${account.id}/shops/${id}`}>Ver</Link>
                </td>
                <td>
                  <Link to={`/admin/accounts/${account.id}/shops/${id}/edit`}>Editar</Link>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
    {shops && !shops.length ? <NoResult /> : <Paginate onPageChange={onPageClick} />}
  </React.Fragment>
);

Table.propTypes = {
  onPageClick: PropTypes.func,
  shops: PropTypes.array
};

export default Table;
