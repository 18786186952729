const themeColor = (theme, name, variant) => {
  // Support passing an array as first argument
  if (Array.isArray(name)) {
    [name, variant] = name;
  }

  // Support passing just a color
  if (name && !variant) {
    return name;
  }

  const themeColor = theme.colors[name];
  return themeColor && themeColor[variant];
};

/**
 * Pick a fixed color from theme.
 *
 * Examples:
 *
 * const theme = {
 *  colors: {
 *    main: {
 *      bold: 'fff',
 *      medium: '0af'
 *    }
 *  }
 * }
 *
 * color('main', 'bold')({ theme }) => 'fff'
 * color(['main', 'medium'])({ theme }) => '0af'
 * color()({ theme, color: ['main', 'medium'] }) => '0af'
 * color()({ theme, color: '1b0' }) => '1b0'
 */
export const color = (name, variant) => ({ theme }) => {
  return themeColor(theme, name, variant);
};

/**
 * Pick a color from the theme based on color value
 *
 * Examples:
 *
 * const theme = {
 *  colors: {
 *    main: {
 *      bold: 'fff',
 *      medium: '0af'
 *    }
 *  }
 * }
 *
 * colorSystem({ theme, color: ['main', 'medium'] }) => '0af'
 * colorSystem({ theme, color: '1b0' }) => '1b0'
 */
export const colorSystem = ({ theme, color }) => {
  return themeColor(theme, color);
};

export const typography = (name) => ({ theme }) => {
  const typography = theme.typography[name];

  return typography;
};
