import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button,
  FormCheckbox
} from 'shards-react';
import { InputFile } from '@increase/typed-components';
import Constants from '../../../data/Constants';
import PageTitle from '../../../components/PageTitle';
import CredentialService from '../../../services/CredentialService';
import EstablishmentService from '../../../services/EstablishmentService';
import Errors from '../../../components/Errors';
import Debounce from 'es6-promise-debounce';
import { debouncedFetchAccounts } from '../../../utils/accounts';
import { SharedDataContext } from '../../../contexts/SharedDataContext';
import { Can } from '../../../permissions/helper';

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credential: null,
      electronicCheckbox: null,
      redirect: null
    };
    this.requiredFields = [
      { field: 'username', label: 'Usuario' },
      { field: 'externalAccountId', label: 'Cuenta' },
      { field: 'infoProvider', label: 'Proveedor' },
      { field: 'country', label: 'Pais' },
      { field: 'password', label: 'Contraseña' },
      { field: 'status', label: 'Status' }
    ];
  }

  async componentDidMount() {
    if (!this.props.isNew) {
      // get credential to edit
      const credentialService = await CredentialService();
      credentialService.get(this.props.credentialId).then((data) => {
        data.establishmentIds = this.transformEstablishments(data.establishments);
        this.setState({
          credential: data,
          electronicCheckbox: data.electronicCheckbox
        });
      });
    }
  }

  readFile(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function(evt) {
        resolve(evt.target.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }

  handleSubmit = async (credential) => {
    const credentialService = await CredentialService();
    const func = this.props.isNew ? credentialService.create : credentialService.update;

    const payload = Object.assign({}, credential);

    if (payload.ftpKey) {
      payload.ftpKey = await this.readFile(payload.ftpKey);
    }

    if (payload.establishmentIds) {
      payload.establishmentIds = payload.establishmentIds.map((option) => option.value);
    }

    if (!(await this.validFields(payload))) {
      return;
    }

    if (payload.metadata && payload.metadata.__typename) {
      delete payload.metadata.__typename;
    }

    func(payload).then((data) => {
      if ((data.errors || []).length > 0) {
        this.setState({ errors: data.errors });
      } else {
        this.setState({ redirect: data.credentialId });
      }
    });
  };

  validFields = (form) => {
    let valid = true;
    const errors = [];

    this.requiredFields.forEach((key) => {
      if (!form[key.field]) {
        valid = false;
        errors.push(key.label);
      }
    });
    if (!valid) {
      this.setState({ errors: [`Falta completar el campo: ${errors.join(' - ')}`] });
    }
    return valid;
  };

  transformEstablishments = (establishments) => {
    return (establishments || []).map((establishment) => {
      return this.establishmentToOption(establishment);
    });
  };

  establishmentToOption = (establishment) => {
    return {
      value: establishment.id,
      label: establishment.number
    };
  };

  debouncedFetchEstablishments = Debounce(async (establishment) => {
    const establishmentService = await EstablishmentService();
    return establishmentService.list({ establishmentNumberOrId: establishment }).then((data) => {
      return this.transformEstablishments(data);
    });
  }, 500);

  // Array of [{label: '', value: ''}]
  formatSelectLabelAndValue = (array, value) => {
    let result = { value: '', label: '' };
    if (!value) {
      return result;
    }
    array.forEach((element) => {
      if (element.value === value) {
        result = { value: element.value, label: element.label };
      }
    });
    return result;
  };

  handleFirstDataNewCheckbox = async (selected) => {
    const credentialService = await CredentialService();

    credentialService
      .firstDataNewToggleElectronic(this.state.credential.id, selected)
      .then((data) => this.setState({ electronicCheckbox: data.credential.electronicCheckbox }));
  };

  providerNeedsExpectedDocuments = (infoProvider) => {
    if (!infoProvider) {
      return false;
    }
    const { documentsToManualScraper } = this.context;
    if (!documentsToManualScraper) {
      return false;
    }
    return documentsToManualScraper.includes(infoProvider);
  };

  render() {
    const cardTitle = this.props.isNew ? 'Crear' : 'Editar';
    const buttonText = this.props.isNew ? 'Crear credencial' : 'Editar credencial';

    return this.state.redirect === null ? (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Credenciales" />
        </Row>
        <Row>
          <Col>
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">{cardTitle}</h6>
              </CardHeader>
              <CardBody>
                <Errors errors={this.state.errors} />
                <Formik enableReinitialize initialValues={this.state.credential}>
                  {(props) => {
                    const { values, isSubmitting, handleChange, handleBlur, setFieldValue } = props;

                    return (
                      <Form>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="username">Nombre de usuario</label>
                            <FormInput
                              id="username"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Nombre de usuario"
                              type="text"
                              value={values.username}
                            />
                          </Col>
                          <Col className="form-group" md="4">
                            <label htmlFor="otherLogin">Otro login (opcional)</label>
                            <FormInput
                              id="otherLogin"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Otro login (opcional)"
                              type="text"
                              value={values.otherLogin}
                            />
                          </Col>
                          {values.infoProvider === 'first_data_sftp' && (
                            <Col className="form-group" md="4">
                              {/* Loading a credential as a file was used to preserve its encoding */}
                              {/* In this case otherLogin is only used to show that a file was loeaded */}
                              {/* If you are in the future reading this, I hope you have a better solution */}
                              <InputFile
                                id="ftpKey"
                                label="FTP key"
                                name="ftpKey"
                                onBlur={handleBlur}
                                onChange={async (file) =>
                                  setFieldValue('otherLogin', await this.readFile(file))
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="password">Contraseña</label>
                            <FormInput
                              id="password"
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Contraseña"
                              value={values.password}
                            />
                          </Col>
                          <Col className="form-group" md="4">
                            <label htmlFor="infoProvider">Proveedor</label>
                            <Select
                              className="basic-multi-select"
                              classNamePrefix="select"
                              id="infoProvider"
                              name="infoProvider"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('infoProvider', e.value);
                              }}
                              options={Constants.infoProvidersSimple}
                              placeholder={'Proveedor'}
                              value={this.formatSelectLabelAndValue(
                                Constants.infoProvidersSimple,
                                values.infoProvider
                              )}
                            />
                          </Col>
                          <Col className="form-group" md="4">
                            {values.infoProvider === 'first_data_new' && this.state.credential ? (
                              <React.Fragment>
                                <label htmlFor="establishmentIds">Solo FirstData nuevo</label>
                                <FormCheckbox
                                  checked={this.state.electronicCheckbox || false}
                                  onChange={() =>
                                    this.handleFirstDataNewCheckbox(
                                      !(this.state.electronicCheckbox || false)
                                    )
                                  }
                                >
                                  Descargar documentos de electrónica
                                </FormCheckbox>
                              </React.Fragment>
                            ) : null}
                            {values.infoProvider === 'cabal' ? (
                              <React.Fragment>
                                <label htmlFor="folder">Carpeta</label>
                                <FormInput
                                  id="metadata.folder"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder="Por ejemplo: Establecimientos%5CSHELL"
                                  style={{ borderColor: 'green' }}
                                  type="text"
                                  value={values.metadata ? values.metadata.folder : undefined}
                                />
                              </React.Fragment>
                            ) : null}
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="country">País</label>
                            <FormSelect
                              className="form-control"
                              id="country"
                              name="country"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.country}
                            >
                              <option value="" />
                              <option value="argentina">Argentina</option>
                              <option value="ecuador">Ecuador</option>
                              <option value="uruguay">Uruguay</option>
                            </FormSelect>
                          </Col>
                          <Col className="form-group" md="4">
                            <label htmlFor="status">Estado</label>
                            <Select
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="credentialStatus"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('status', e.value);
                              }}
                              options={Constants.credentialStatuses}
                              placeholder={'Estado'}
                              uniqueKey="credentialStatus"
                              value={this.formatSelectLabelAndValue(
                                Constants.credentialStatuses,
                                values.status
                              )}
                            />
                          </Col>
                          {this.providerNeedsExpectedDocuments(values.infoProvider) ? (
                            <Col className="form-group" md="4">
                              <label htmlFor="folder">Documentos esperados (cantidad)</label>
                              <FormInput
                                id="expectedDocuments"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder=""
                                type="number"
                                value={values.expectedDocuments}
                              />
                            </Col>
                          ) : null}
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="externalAccountId">Account</label>
                            <Can
                              action="cred:edit:acc"
                              unAuthorizedProps={{ isDisabled: !this.props.isNew }}
                            >
                              <AsyncSelect
                                cacheOptions={true}
                                id="externalAccountId"
                                loadOptions={debouncedFetchAccounts}
                                name="externalAccountId"
                                onBlur={handleBlur}
                                onChange={(option) => {
                                  setFieldValue('externalAccountId', option.value);
                                  setFieldValue('account.id', option.value);
                                  setFieldValue('account.name', option.label);
                                }}
                                placeholder="Busca la Account por nombre"
                                value={
                                  values.account
                                    ? { value: values.account.id, label: values.account.name }
                                    : ''
                                }
                              />
                            </Can>
                          </Col>
                          <Col className="form-group" md="4">
                            <label htmlFor="establishmentIds">Establecimientos</label>
                            <AsyncSelect
                              cacheOptions={true}
                              id="establishmentIds"
                              isDisabled
                              isMulti={true}
                              loadOptions={this.debouncedFetchEstablishments}
                              name="establishmentIds"
                              onBlur={handleBlur}
                              onChange={(option) => {
                                setFieldValue('establishmentIds', option);
                              }}
                              placeholder="Establecimientos"
                              value={values.establishmentIds}
                            />
                          </Col>
                        </Row>
                        <Button
                          className="btn-primary"
                          disabled={isSubmitting}
                          onClick={() => this.handleSubmit(values)}
                          style={{ marginRight: 5 }}
                        >
                          {buttonText}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    ) : (
      <Redirect to={`/admin/credentials/${this.state.redirect}`} />
    );
  }
}

Editor.defaultProps = {
  isNew: true
};

Editor.propTypes = {
  isNew: PropTypes.bool
};

Editor.contextType = SharedDataContext;

export default Editor;
