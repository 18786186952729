import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'shards-react';
import styled from 'styled-components';
import GenericInput from '../common/GenericSearchBar/GenericInput';

class UserNamesInput extends React.Component {
  handleChange = (object) => {
    this.props.handleInputChange(object);
  };

  handleFirstNameChange = (userFirstName) => {
    this.handleChange({ userFirstName: userFirstName });
  };

  handleLastNameChange = (userLastName) => {
    this.handleChange({ userLastName: userLastName });
  };

  render() {
    return (
      <Row form>
        <ColNoPaddingRight md="6">
          <GenericInput
            handleInputChange={this.handleFirstNameChange}
            inputName={'userFirstName'}
            inputPlaceholder={'Nombre'}
          />
        </ColNoPaddingRight>
        <ColNoPaddingLeft md="6">
          <GenericInput
            handleInputChange={this.handleLastNameChange}
            inputName={'userLastName'}
            inputPlaceholder={'Apellido'}
          />
        </ColNoPaddingLeft>
      </Row>
    );
  }
}

UserNamesInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

const ColNoPaddingLeft = styled(Col)`
  && {
    padding-left: 0
  }
};`;

const ColNoPaddingRight = styled(Col)`
  && {
    padding-right: 0
  }
};`;

export default UserNamesInput;
