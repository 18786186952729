import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import newClient from './services/GraphqlClients';
// Layout Types
import DefaultLayout from './containers/layout/DefaultLayout';

import AdminDashboard from './containers/admin/Dashboard';
import AdminEstablishments from './containers/admin/Establishments';
import AdminCredentials from './containers/admin/Credentials';
import AdminAccounts from './containers/admin/Accounts';
import AdminUsers from './containers/admin/Users';
import AdminPayments from './containers/admin/Payments';
import AdminShops from './containers/admin/Shops';
import Support from './containers/support/Support';
import Errors from './containers/layout/Errors';
import { useAuthenticated } from './contexts/AuthContext';

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

export default function Router() {
  const authenticated = useAuthenticated();

  if (authenticated === false) {
    window.location.href = `${LOGIN_URL}?redirect=${window.location}`;
    return null;
  }
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME || ''}>
      <ApolloProvider client={newClient()}>
        <DefaultLayout>
          <Switch>
            <Route component={AdminDashboard} exact={true} path="/" />
            <Route component={AdminEstablishments} path="/admin/establishments" />
            <Route component={AdminPayments} path="/admin/payments" />
            <Route component={AdminCredentials} path="/admin/credentials" />
            <Route component={AdminAccounts} path="/admin/accounts" />
            <Route component={AdminUsers} path="/admin/users" />
            <Route component={AdminShops} path="/admin/shops" />
            <Route component={Errors} path="/errors" />
            <Route component={Support} path="/support" />
          </Switch>
        </DefaultLayout>
      </ApolloProvider>
    </BrowserRouter>
  );
}
