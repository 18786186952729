import React from 'react';
import { Container, Row } from 'shards-react';
import PageTitle from '../../../components/PageTitle';
import MonthlyComparisonsService from '../../../services/MonthlyComparisonsService';

import GenericSearchBar from '../../../components/common/GenericSearchBar/GenericSearchBar';
import { Provider as LoadingProvider } from '../../../contexts/LoadingContext';

import InfoProviderSimpleInput from '../../../components/inputs/InfoProviderSimpleInput';
import MonthAndYearInput from '../../../components/inputs/MonthAndYearInput';
import AccountNameOrIdInput from '../../../components/inputs/AccountNameOrIdInput';
import Table from './Table';
import EstablishmentNumberInput from '../../../components/inputs/EstablishmentNumberInput';
import Constants from '../../../data/Constants';

const inputs = [
  MonthAndYearInput,
  InfoProviderSimpleInput,
  EstablishmentNumberInput,
  AccountNameOrIdInput
];
class List extends React.Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    this.state = {
      filter: {
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
      },
      offset: 0,
      comparisons: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getMonthlyComparisons();
  }

  onSubmit = () => {
    this.getMonthlyComparisons();
  };

  handleFiltersChange = (filter) => {
    this.setState({ filter });
  };

  getMonthlyComparisons = async () => {
    this.setState({ loading: true });

    window.scrollTo(0, 0);

    const monthlyComparisonsService = await MonthlyComparisonsService();
    monthlyComparisonsService
      .getComparisons(this.state.filter, Constants.elementsPerPage, this.state.offset)
      .then((data) => {
        this.setState((state) => {
          return {
            comparisons: data || [],
            offset: !data.length ? 0 : state.offset
          };
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  handlePageClick = async (direction) => {
    const newOffset = this.state.offset + direction * Constants.elementsPerPage;

    if (newOffset >= 0) {
      this.setState({ offset: newOffset }, () => this.getMonthlyComparisons());
    }
  };

  render() {
    return (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle
            className="text-sm-left"
            sm="4"
            subtitle=""
            title="Comparador mensual - Etapas"
          />
        </Row>
        <LoadingProvider value={this.state.loading}>
          <GenericSearchBar
            handleChange={this.handleFiltersChange}
            handleSubmit={this.onSubmit}
            inputWidth="50"
            inputs={inputs}
          />
          <Table comparisons={this.state.comparisons} onPageClick={this.handlePageClick} />
        </LoadingProvider>
      </Container>
    );
  }
}

export default List;
