import React from 'react';
import { TableRow, Td } from '@increase/typed-components';
import styled from 'styled-components';

import Spinner from '../../../components/common/GenericSearchBar/Spinner';

const TableCell = styled(Td)`
  text-align: center;
`;

export const LoadingTableRow = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <TableRow>
      <TableCell colSpan="10">
        <Spinner />
      </TableCell>
    </TableRow>
  );
};
