import React from 'react';
import PropTypes from 'prop-types';
import GenericInput from '../common/GenericSearchBar/GenericInput';

class AccountNameOrIdInput extends React.Component {
  handleChange = (accountNameOrId) => {
    this.props.handleInputChange({ accountNameOrId: accountNameOrId });
  };

  render() {
    return (
      <GenericInput
        handleInputChange={this.handleChange}
        inputName={'AccountSearch'}
        inputPlaceholder={'Nombre o id de la cuenta'}
      />
    );
  }
}

AccountNameOrIdInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default AccountNameOrIdInput;
