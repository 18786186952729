import React from 'react';
import Select from 'react-select';
import { Col } from 'shards-react';
import Constants from '../../../data/Constants';
import Utils from '../../../services/Strings';
import { getCurrentCountry, setCurrentCountry } from '../../../utils/admin';

class SidebarOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant: getCurrentCountry() !== null ? getCurrentCountry() : Constants.defaultTenant
    };
  }

  handleChange = (option) => {
    setCurrentCountry(option.value);
    this.setState({ tenant: option.value });
    window.location.reload(true);
  };

  render() {
    return (
      <div className="nav--no-borders flex-column">
        <Col className="TextAlignCenter">
          <hr />
          <img
            alt="selected country icon"
            className="MarginBottom10 MaxHeight35"
            src={Constants.countryIcons[this.state.tenant]}
          />

          <Select
            onChange={this.handleChange}
            options={Constants.countries}
            placeholder={Utils.capitalize(this.state.tenant)}
          />
        </Col>
      </div>
    );
  }
}

export default SidebarOptions;
